/**
 * View More Box component
 *
 * This renders a view more box with simialr proportions to the ProfileBox.  Will pass through clicks/events to parent
 *
 *
 * @file   Profile Box component
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 *
 */
<template>
    <v-container class="viewmore-box-container" v-on="$listeners" fill-height>
        <v-row align="center" justify="center">
            <v-col col="12">
                <v-hover v-slot="{ hover }">
                    <v-card class="viewmore-box ma-0 mx-auto justify-center" :elevation="hover ? 5 : 2" :max-height="height" :height="height" :max-width="width" :width="width">
                        <v-img class="viewmore-box-background" :src="selectedBackground" :height="height">
                            <v-spacer />
                            <v-card-subtitle class="white--text text-center">
                                <p class="viewmore-box-name">{{title}}</p>
                            </v-card-subtitle>
                            <v-spacer />
                        </v-img>                    
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// The view more box component
export default {

    name: "ViewMoreBox",

    props: {
        width: { type: String, default: "161" },
        height: { type: String, default: "205" },
        title: { type: String, default: "View more" },
    },

    computed: {        
        selectedBackground() {
           // Include the image using require
           return require('@/assets/imgs/backgrounds/default.png');
        },
    },
};
</script>

<style scoped>
.viewmore-box-container {
    cursor: pointer;
}

.v-sheet.v-card.viewmore-box {
    border-radius: 10px;
}

.viewmore-box-background .v-image__image--cover {
    opacity: 0.4;
}

.viewmore-box-name {
    color: rgba(0,0,0,0.6);
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
    margin-top: 70px;
}

</style>
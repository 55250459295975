/**
 * Front-end view controller for the value disputed page
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * Note:
 * 
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the value disputed page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container v-if="isLoggedIn">
        <!-- Title and blurb -->
        <v-row align="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col>
                <h1>We're sorry to hear that</h1>
                <p class="mt-5">We've notified one of our team who will reach out and help you resolve the dispute if necessary.</p>

                <p class="my-5" id="icon">
                    😔
                </p>

                <p>Sometimes this could have been a simple mistake when the value was logged, but other times the person may not have genuinely delivered the value as described.  Either way, our team can help you find a way forward.</p>
                <p>Note: the value creator isn't awarded any reputation or rewards from disputed transactions.</p>

                <p class="font-weight-bold mt-15">We'll be in touch in due course, or feel free to contact us in discord and ask for help in the meantime.</p>
                <p class="mb-0 ">Thanks for letting us know!</p>
            </v-col>
        </v-row>

        <!-- Submit reminder & button -->
        <v-row align="center" justify="space-around">
            <v-col cols="12" md="9">
                <v-btn :to="{ name: 'valueHome' }">Done</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters } from 'vuex';


// The main admin home page component
export default {
    name: 'ValueDisputed',

    data() {
        return {
        };
    },

    computed: {
        // Map our Vuex getters
        ...mapGetters({
            isLoggedIn: 'Auth/isLoggedIn',
        }),
    },

    watch: {
        // whenever login state changes and we're no longer logged in, we redirect to login page
        isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
            if (!newIsLoggedIn && oldIsLoggedIn) {
                this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
            }
        },
    },

    // If we're not navigating to another exchange page, we clear the searchResults
    beforeRouteLeave(to,from,next) {
        const validRouteNames = ['valueHome', 'valueAddPerson', 'valueLogValue', 'valueLogged', 'valueConfirm', 'valueConfirmed', 'valueDisputed', 'valueView'];
        if (!to.name || validRouteNames.indexOf(to.name) === -1) {
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-searchResults' });
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-recentResults' });
        }
        next();
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
#icon {
    font-size: 160px;
}
</style>

/**
 * Front-end view controller for the value exchange home page
 *
 * Render's the page template, and processes any UI functions and interactivity
 *
 * Note:
 *
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the value exchange home page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 *
 */
 <template>
	<v-container v-if="isLoggedIn" fluid class="d-flex flex-column flex-grow-1 fill-parent-height pa-0">
		<v-row color="grey lighten-3" no-gutters class="top-row flex-grow-0 flex-shrink-0">
			<v-col>
				<!-- Title -->
				<h1>What Value Have You Created Recently?</h1>
				<p>Log value exchanged with other community members for reputation, awards, and other community benefits</p>

				<!-- Value Capital Boxes -->
				<v-container class="capital-type-container mt-10">
					<v-row>
						<v-col col="4" v-for="(v, index) in valueTypes" :key="index" @click="addValue(v.type)" style="position: relative">
							<v-hover v-slot="{ hover }">
								<v-card class="capital-type ma-auto" :elevation="hover ? 5 : 2" :max-height="205" :height="205" :max-width="161" :width="161" :color="hover ? 'blue-grey lighten-4' : 'white'">
									<v-btn class="capital-type-help" icon outlined x-small absolute top right @click.stop="valueHelp"><v-icon x-small color="grey darken-1">mdi-help</v-icon></v-btn>
									<v-card-subtitle class="white--text text-center mt-0">
										<!-- Value button for capital Type -->
										<v-avatar color="grey lighten-3" size="56" class="profile-box-user my-4">
											<v-icon :large="hover" dense class="capital-type-icon">{{hover ? 'mdi-plus-circle-outline' : v.icon}}</v-icon>
										</v-avatar>
										
										<p class="capital-type-name ma-2">{{v.title}}</p>
										<p class="capital-type-type mt-5 mb-2">{{v.capital}}</p>
									</v-card-subtitle>
								</v-card>
							</v-hover>
						</v-col>
					</v-row>
				</v-container>

				<!-- Contribution model links -->
				<p class="mt-10"><a href="https://contribute.positivesum.community/adding-value-to-the-community/creating-community-value" target="_blank">How does our community contribution model work?</a></p>
			</v-col>
		</v-row>

		<!-- Bottom section -->
		<v-row no-gutters class="bottom-row flex-grow-1 flex-shrink-1">
			<v-col cols="12">
				<!-- Page loader-->
				<v-progress-linear class="pa-0 ma-0" :indeterminate="loadingRecent" rounded height="3" :background-color="loadingRecent ? null : 'white'" :color="loadingRecent ? 'primary' : 'white'" />

				<v-container class="mt-10">
					<!-- Top value creators -->
					<v-row>
						<v-col cols="12" :md="4" class="text-left mt-2 mb-auto">
							<!-- Heading -->
							<h2 class="mx-3" :class="{ 'text-center': $vuetify.breakpoint.smAndDown}">Top Value Creators</h2>
						</v-col>
						
						<v-spacer></v-spacer>

						<!-- Drop down filter -->
						<v-col cols="12" :md="6" class="text-right mr-3">
							<v-select
								v-model="currentSeason"
								:items="seasonListAndAllTimeList"
								label="Season"
								solo
								@change="changeSeason"
								v-if="seasons">
								<v-icon slot="prepend-inner" class="mr-3">
									mdi-calendar
								</v-icon>
							</v-select>
						</v-col>
					</v-row>

					<!-- Top Value creators row -->
					<v-row v-if="topValueList.length" align="start" justify="space-between" class="mb-15">
						<v-col cols="12" md="3" lg="2" v-for="(v, index) in topValueList" :key="index">
							<ProfileBox
								:id='v.userid'
								:firstname='v.firstname'
								:lastname='v.lastname'
								:profileImgSrc='v.profileImg'
								:countryStr='v.countryname'
								:countryCode='v.countrycode'
								:regionStr='v.regionname'
								level=""
								:clickable="true"
								@click="select(v.userid)"
								v-if="v"
								/>
							<p class="capital-type-type mt-5 mb-2" v-if="v">{{capitalize(v.type)}} Capital</p>
						</v-col>

						<v-spacer />
					</v-row>
					<v-row v-else align="start" justify="space-between" class="mb-15">
						<!-- No search results -->
						<v-col cols="12">
							<h3 class="text-left mx-3">None found</h3>
						</v-col>
					</v-row>

					<!-- Search/recent results list-->
					<v-row>
						<v-col cols="12" :md="searchFocus || hasFilter ? 4 : 5" class="text-left mt-2 mb-auto">
							<!-- Heading -->
							<h2 class="mx-3" :class="{ 'text-center': $vuetify.breakpoint.smAndDown}">Recent Value Created</h2>
						</v-col>

						<v-spacer />
						
						<!-- Search box -->
						<v-col cols="12" :md="searchFocus || hasFilter ? 7 : 6" class="text-right pl-6 mr-3">
							<v-form @submit.prevent="getDebouncedRecentResults">
								<v-text-field :rules="searchRules" ref="search" v-model="searchString" solo label="Search for people, types, & comments..." clearable @focus="searchFocus=true" @blur="searchFocus=false">
									<template v-slot:prepend-inner>
										<v-icon class="mr-2">mdi-magnify</v-icon>
										<v-chip v-if="confirmedSearchFilter" color="green lighten-1" text-color="white" class="mr-2" label small close @click:close="clearChip('confirmed')"><v-icon left small>mdi-star</v-icon></v-chip>
										<v-chip v-if="pendingSearchFilter" color="amber lighten-1" text-color="white" class="mr-2" label small close @click:close="clearChip('pending')"><v-icon left small>mdi-information</v-icon></v-chip>
										<v-chip v-if="disputedSearchFilter" color="red lighten-1" text-color="white" class="mr-2" label small close @click:close="clearChip('disputed')"><v-icon left small>mdi-alert</v-icon></v-chip>
									</template>
								</v-text-field>
							</v-form>

							<!-- Chips for common searches -->
							<span style="display: inline-block" class="mr-2 mt-0 mb-10" v-if="!$vuetify.breakpoint.smAndDown">Filters:</span>
							<v-chip color="green lighten-1" :outlined="confirmedSearchFilter" label small class="mr-2 mb-2" dark @click="clickChip('confirmed')"><v-icon left small v-if="!$vuetify.breakpoint.smAndDown">mdi-star</v-icon>Confirmed</v-chip>
							<v-chip color="amber lighten-1" :outlined="pendingSearchFilter" label small class="mr-2 mb-2" dark @click="clickChip('pending')"><v-icon left small v-if="!$vuetify.breakpoint.smAndDown">mdi-information</v-icon>Pending</v-chip>
							<v-chip color="red lighten-1" :outlined="disputedSearchFilter" label small dark class="mb-2" @click="clickChip('disputed')"><v-icon left small v-if="!$vuetify.breakpoint.smAndDown">mdi-alert</v-icon>Disputed</v-chip>
						</v-col>
					</v-row>

					<!-- Recent Value Created -->
					<v-container>
						<!-- No search results -->
						<v-row align="start" justify="space-between" v-if="!isRecentResults">
							<v-col cols="12">
								<h3 class="text-left">No transactions found</h3>
							</v-col>
						</v-row>
						
						<v-row align="start" justify="space-between" v-if="isRecentResults">
							<v-col cols="12" lg="4" md="6" sm="12" v-for="v in recentResults" :key="v.id">
								<ValueTransactionBox
									:id="v.id"
									:value="v.value"
									:status="v.status"
									:confirmedValue="v.confirmed_value"
									:type="v.type"
									:fromid="v.fromid"
									:fromFirstname="v.from_firstname"
									:fromLastname="v.from_lastname"
									:fromProfileImgSrc="v.from_profileImg"
									:toid="v.toid"
									:toFirstname="v.to_firstname"
									:toLastname="v.to_lastname"
									:toProfileImgSrc="v.to_profileImg"
									:createdAt="v.createdat_ts"
									@click="clickValue"
								></ValueTransactionBox>                             
							</v-col>
						</v-row>

						<!-- Nearby pagination for long result sets -->
						<v-row align="start" justify="center" v-if="isRecentResults">
							<v-col>
								<v-pagination
									v-model="recentPage"
									:length="numRecentPages"
									:total-visible="7"
									color="blue-grey lighten-4'"
									v-if="numRecentPages > 1"
								></v-pagination>
							</v-col>
						</v-row>
					</v-container>
				</v-container>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters, mapActions } from 'vuex';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';

// API Connectors
import ValueExchangeAPI from '@/services/ValueExchangeAPIService.js';

// Shared components used in this page
import ValueTransactionBox from '@/components/ValueTransactionBox';
import ProfileBox from '@/components/ProfileBox';

// The main value exchange home page component
export default {
	name: 'ValueHome',

	data() {
		return {
			valueTypes: [
				{ type: 'network', icon: 'mdi-card-account-details-outline', title: 'Made Introduction', capital: 'Network Capital' },
				{ type: 'mentorship', icon: 'mdi-human-male-board-poll', title: 'Given Mentorship', capital: 'Mentorship Capital' },
				{ type: 'intellectual', icon: 'mdi-text-box-plus-outline', title: 'Created Content', capital: 'Intellectual Capital' },
				{ type: 'community', icon: 'mdi-wrench-outline', title: 'Provided Ops Support', capital: 'Community Capital' }
			],

			// Recent search parameters
			searchFocus: false,
			numPerPage: 10,
			searchRules: [],
			loadingRecent: false,
			debounceRecentTimer: null,

			// Top value creators
			currentSeason: null,
			topValuesCache: {},         // Dictionary indexed by seasonid 
		};
	},

	components: {
		ValueTransactionBox,
		ProfileBox,
	},

	computed: {
		// Map our Vuex getters
		...mapGetters({
			isLoggedIn: 'Auth/isLoggedIn',
			userid: 'User/userid',
			seasons: 'Seasons/seasons',
		}),

		// Recent saved results - save whether chip is enabled in the searchFilter slot
		searchString: {
			get() { return this.$store.getters['SearchResults/searchString']('value-recentResults'); },
			set(searchString) { this.$store.dispatch('SearchResults/setSearchString', { key: 'value-recentResults', searchString }); }
		},
		
		recentSearchFilter: {
			get() { return this.$store.getters['SearchResults/searchFilter']('value-recentResults'); },
			set(searchFilter) { this.$store.dispatch('SearchResults/setSearchFilter', { key: 'value-recentResults', searchFilter }); }
		},

		recentResults: {
			get() { return this.$store.getters['SearchResults/searchResults']('value-recentResults'); },
			set(searchResults) { this.$store.dispatch('SearchResults/setSearchResults', { key: 'value-recentResults', searchResults }); }
		},

		recentPage: {
			get() { return this.$store.getters['SearchResults/searchPage']('value-recentResults'); },
			set(page) { this.$store.dispatch('SearchResults/setSearchPage', { key: 'value-recentResults', page }); }
		},

		recentTotalResults: {
			get() { return this.$store.getters['SearchResults/searchTotalResults']('value-recentResults'); },
			set(totalResults) { this.$store.dispatch('SearchResults/setSearchTotalResults', { key: 'value-recentResults', totalResults }); }
		},

		isRecentResults() {
			return this.recentResults && this.recentResults.length;
		},

		numRecentPages() {
			return !this.recentTotalResults ? 0 : Math.ceil(this.recentTotalResults / this.numPerPage);
		},

		confirmedSearchFilter() {
			return this.recentSearchFilter && this.recentSearchFilter.confirmed ? this.recentSearchFilter.confirmed : null;
		},

		disputedSearchFilter() {
			return this.recentSearchFilter && this.recentSearchFilter.disputed ? this.recentSearchFilter.disputed : null;
		},

		pendingSearchFilter() {
			return this.recentSearchFilter && this.recentSearchFilter.pending ? this.recentSearchFilter.pending : null;
		},

		hasFilter() {
			return this.confirmedSearchFilter || this.pendingSearchFilter || this.disputedSearchFilter;
		},

		seasonList() {
			return this.seasons.map(s => {
				const fromDate = new Date(s.fromdate*1000);
				const toDate = new Date(s.todate*1000);

				var options = {
					month: "short",
					year: "numeric"
				};

				let obj = Object.assign({}, s);
				obj.text = 'Season ' + s.season + ' (' + fromDate.toLocaleDateString("en", options) + ' - ' + toDate.toLocaleDateString("en", options) + ')';
				obj.value = s.season;
				return obj;
			});
		},

		seasonListAndAllTimeList() {
			return [...this.seasonList, { text: 'All time (Across All Seasons)', value: ''}];
		},

		// Unordered list of values for a season as pulled from valuesCache
		topValues() {
			if (!Object.prototype.hasOwnProperty.call(this.topValuesCache, this.currentSeason)) {
				return null;
			} else {
				return this.topValuesCache[this.currentSeason];
			}
		},
		
		// An individual value type pulled from the cache
		topValue() {
			return (type) => {
				// Lookup the capital type in the top value and return the person record with that type, if it exists
				return this.topValues ? this.topValues.find(v => v.type === type) : null;
			}
		},

		topValueList() {
			let list = [];

			const network = this.topValue('network');
			const mentorship = this.topValue('mentorship');
			const intellectual = this.topValue('intellectual');
			const community = this.topValue('community');

			if (network) list.push(network);
			if (mentorship) list.push(mentorship);
			if (intellectual) list.push(intellectual);
			if (community) list.push(community);

			return list;
		}
	},

	watch: {
		// whenever login state changes and we're no longer logged in, we redirect to login page
		isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
			if (!newIsLoggedIn && oldIsLoggedIn) {
				this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
			}
		},

		// Issue new search on search string changing
		searchString(value, old) {
			// If the value has changed, we reset the page number
			if (value !== old && old) {
				this.recentPage = 1;

				// If we just cleared the value, we reissue the search
				if (!value) {
					this.getRecentResults(this.recentPage);    
				}
			}

			// Nothing to search
			if (!value) return;

			// Reset any validation rules so we can do on submit
			this.searchRules =  [];
			this.getDebouncedRecentResults(this.recentPage);
		},
		
		recentPage(pageNum, oldPageNum) {
			// Get the recent search results with the new page number
			if (oldPageNum > 0) {
				this.getRecentResults(pageNum);
			}
		},
	},

	created() {
		// Check login state or redirect
		if (!this.isLoggedIn) {
			this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
		}

		// Get the list of recent transactions
		this.getRecentResults();

		// Get list of seasons and top value creators
		this.getSeasonList(true);
	},

	// If we're not navigating to another exchange page, we clear the searchResults
	beforeRouteLeave(to,from,next) {
		const validRouteNames = ['valueHome', 'valueAddPerson', 'valueLogValue', 'valueLogged', 'valueConfirm', 'valueConfirmed', 'valueDisputed', 'valueView'];
		if (!to.name || validRouteNames.indexOf(to.name) === -1) {
			this.$store.dispatch('SearchResults/clearSearch', { key: 'value-searchResults' });
			this.$store.dispatch('SearchResults/clearSearch', { key: 'value-recentResults' });
		}
		next();
	},

	methods: {
		// Map our Snackbar methods into this component
		...mapActions({
			setSnackBar: 'SnackBar/setSnackBar',
			clearSnackBar: 'SnackBar/clearSnackBar',
			getSeasons: 'Seasons/getSeasons',
		}),

		capitalize(value) {
			if (!value) return ''
			value = value.toString()
			return value.charAt(0).toUpperCase() + value.slice(1)
		},
		
		valueHelp() {
			window.open('https://contribute.positivesum.community/adding-value-to-the-community/value-capital-types');
		},

		addValue(type) {
			this.$router.push({ name: 'valueAddPerson', params: { type }});
		},

		clickValue(value) {
			const id = Number(value.id);
			if (id !== Number(this.curValueId)) {
				this.$router.push({ name: 'valueView', params: { valueId: id }});
			}
		},

		select(userId) {
			if (userId) {
				this.$router.push({ name: 'directoryMember', params: { fetchId: userId }});
			}
		},

		changeSeason(season) {
			// Change the season
			this.currentSeason = season;

			// Check if we have data otherwise fetch it
			if (!Object.prototype.hasOwnProperty.call(this.topValuesCache, season)) {
				this.getTopValueCreators(season);
			}
		},

		clickChip(filter) {
			// Only action if not already selected
			if (!this.recentSearchFilter || !this.recentSearchFilter[filter]) {
				// Enable search filter - clear out all old filters
				let searchFilter = {};
				searchFilter[filter] = true;
				this.recentSearchFilter = searchFilter;
				
				// Reset original search results back to page 1
				if (this.recentPage !== 1) {
					this.recentPage = 1;
				}
				
				// If we were searching, reissue the search at page 1 now with the new chip
				if (this.searchString) {
					// Refresh the search results
					this.getDebouncedRecentResults(this.recentPage);
				} else {
					// Just filter the search results without validating search string
					this.getRecentResults(this.recentPage);
				}
			} else {
				// Turn off the filter
				this.clearChip(filter);
			}
		},

		clearChip(filter) {
			// Reset recent filter (all filters)
			let searchFilter = {};
			searchFilter[filter] = false;
			this.recentSearchFilter = searchFilter;

			// Reset recent page & results back to first (or if we were searching)
			if (this.recentPage !== 1) {
				this.recentPage = 1;
			}

			// Refresh the list to get latest results
			this.getRecentResults(this.recentPage);
		},

		// Delay the search function call by 500ms, to debounce API endpoint
		getDebouncedRecentResults(pageNum) {
			// If called as submit event, pageNum is the event object, so just reset to first page
			if (typeof pageNum !== 'number') {
				pageNum = 1;    
			}

			// Cancel any pending calls
			if (this.debounceRecentTimer) {
				clearTimeout(this.debounceRecentTimer);
			}

			// Delay the search call 500ms
			this.debounceRecentTimer = setTimeout(() => {
				// Validate the search form before we search
				this.searchRules = [
					(v) => {
						if (!v) {
							return true;
						} else {
							return v.length && v.length >= 4 || 'Please enter at least 4 characters';
						}
					}
				];
			
				// Have to do on nextTick to ensure search rules are correctly applied
				this.$nextTick(() => {
					// Only search if valid
					if (this.$refs.search && this.$refs.search.validate() && this.searchString) {
						this.getRecentResults(pageNum)
					}
				});
			}, 500);            
		},
		
		// Get search results from the API endpoint
		getRecentResults(pageNum) {
			this.loadingRecent = true;
			
			// Request the first page unless another page number is set
			let page = pageNum;
			if (!pageNum) {
				page = this.recentPage ? this.recentPage : 1;
			}

			// Get which filters are enabled
			const confirmed = this.recentSearchFilter && this.recentSearchFilter.confirmed ? this.recentSearchFilter.confirmed : null;
			const pending = this.recentSearchFilter && this.recentSearchFilter.pending ? this.recentSearchFilter.pending : null;
			const disputed = this.recentSearchFilter && this.recentSearchFilter.disputed ? this.recentSearchFilter.disputed : null;

			// Search via the API endpoint
			ValueExchangeAPI.recent(this.userid, page, this.searchString, confirmed, pending, disputed)
			.then( (response) => {
				// Check our expected data is in the response
				if (!response.recent) {
					throw new Error('There was a problem, please try again later');
				}

				// Set our expected nearby member results in scope
				this.recentResults = response.recent.items ? response.recent.items : [];
				this.recentTotalResults = response.recent.total ? response.recent.total : 0;
				if (this.recentTotalResults) {
					this.recentPage = response.recent.page ? response.recent.page : 1;
				} else {
					this.recentPage = 0;
				}
			})
			.catch( (error) => {
				// Clear the snackbar
				this.clearSnackBar();

				// Error message to show user
				let errorMessage = '';

				if (error instanceof NoResponseAPIError ) {
					errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
				} else if (error instanceof BadMethodAPIError) {
					errorMessage = 'We encountered a technical problem, please try again later';
				} else if (error instanceof BadRequestAPIError) {
					errorMessage = 'We encountered a problem, please try again later';
				} else if (error instanceof CredentialsRevokedAPIError) {
					this.errorMessage = 'You are not allowed to access this data, please contact us for support';
				} else if (error instanceof AuthenticationAPIError) {
					errorMessage = 'We encountered an authentication problem, please logout and try again';
				} else if (error instanceof InternalServerAPIError) {
					errorMessage = 'We encountered a server problem, please try again later';
				} else {
					errorMessage = 'There was a problem, please try again later';
				}

				// Show snackbar error message
				this.setSnackBar({ snack: errorMessage });
			}).finally(() => this.loadingRecent = false);
		},

		getSeasonList(isFirst=false) {
			// Show loading indicator
			this.loadingRecent = true;
			
			// Get season list from the season store
			this.getSeasons({ userId: this.userid })
			.then((seasons) => {
				if (!seasons) {
					throw new Error('There was a problem, please try again later');
				}

				// Stash current season
				const season = seasons ? seasons.find(s => Number(s.current) === 1) : null;
				this.currentSeason = season && season.season ? season.season : null;

				// If this is first request, we also get the top value creators for the current season
				if (isFirst) {
					return this.getTopValueCreators(this.currentSeason);
				}
			})
			.catch( (error) => {
				// Clear the snackbar
				this.clearSnackBar();

				// Error message to show user
				let errorMessage = '';

				if (error instanceof NoResponseAPIError ) {
					errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
				} else if (error instanceof BadMethodAPIError) {
					errorMessage = 'We encountered a technical problem, please try again later';
				} else if (error instanceof BadRequestAPIError) {
					errorMessage = 'We encountered a problem, please try again later';
				} else if (error instanceof CredentialsRevokedAPIError) {
					this.errorMessage = 'You are not allowed to access this data, please contact us for support';
				} else if (error instanceof AuthenticationAPIError) {
					errorMessage = 'We encountered an authentication problem, please logout and try again';
				} else if (error instanceof InternalServerAPIError) {
					errorMessage = 'We encountered a server problem, please try again later';
				} else {
					errorMessage = 'There was a problem, please try again later';
				}

				// Show snackbar error message
				this.setSnackBar({ snack: errorMessage });
			}).finally(() => this.loadingRecent = false);
		},

		// Get top value creators from the API endpoint
		getTopValueCreators(season) {
			// Show loading indicator
			this.loadingTop = true;
			
			// Search via the API endpoint
			ValueExchangeAPI.topValue(this.userid, season)
			.then( (response) => {
				// Check our expected data is in the response
				if (!response.topValue) {
					throw new Error('There was a problem, please try again later');
				}

				// Set our expected nearby member results in scope
				const topValues = response.topValue ? response.topValue : [];
				
				// Cache these results by the season id
				if (!Object.prototype.hasOwnProperty.call(this.topValuesCache, this.currentSeason)) {
					this.$set(this.topValuesCache, this.currentSeason, topValues);
				}
			})
			.catch( (error) => {
				// Clear the snackbar
				this.clearSnackBar();

				// Error message to show user
				let errorMessage = '';

				if (error instanceof NoResponseAPIError ) {
					errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
				} else if (error instanceof BadMethodAPIError) {
					errorMessage = 'We encountered a technical problem, please try again later';
				} else if (error instanceof BadRequestAPIError) {
					errorMessage = 'We encountered a problem, please try again later';
				} else if (error instanceof CredentialsRevokedAPIError) {
					this.errorMessage = 'You are not allowed to access this data, please contact us for support';
				} else if (error instanceof AuthenticationAPIError) {
					errorMessage = 'We encountered an authentication problem, please logout and try again';
				} else if (error instanceof InternalServerAPIError) {
					errorMessage = 'We encountered a server problem, please try again later';
				} else {
					errorMessage = 'There was a problem, please try again later';
				}

				// Show snackbar error message
				this.setSnackBar({ snack: errorMessage });
			}).finally(() => this.loadingTop = false);
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	margin: 40px 0 0;
}
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
a {
	color: #42b983;
}
.fill-parent-height{
	height:100%;
}

.top-row {
	background-color: #eeeeee;
	border-bottom: 1px solid #d1d1d1;
	-webkit-box-shadow: inset 0px -10px 17px -20px rgba(0,0,0,0.75);
	-moz-box-shadow: inset 0px -10px 17px -20px rgba(0,0,0,0.75);
	box-shadow: inset 0px -10px 17px -20px rgba(0,0,0,0.75);
	padding: 50px 10px;
}

.capital-type-container {
	cursor: pointer;
}

.v-sheet.v-card.capital-type {
	border-radius: 10px;
}

.capital-type-name {
	color: rgba(0,0,0,0.6);
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
}

.capital-type-type {
	text-align: center;
	font-size: 14px;
	color: rgba(0,0,0,0.6);
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
}

.capital-type-icon.v-icon {
	transition: none;
}

.capital-type-help {
	margin-top: -10px;
	margin-right: -10px;
}
</style>

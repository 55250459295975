/**
 * Vuex storage for application-wide snackbar data storage
 *
 * Corresponding getters, mutators, and actions for modifying this local data store
 * 
 * @file   Vuex data store for snackbar data
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Setup the default state
const getDefaultState = () => {
    return {
        // Snackbar details
        message: null,
    };
};

// Setup the store mutations
const mutations = {
    SET_SNACK: (state, snack) => {
        state.snack = snack;
    },

    RESET: state => {
        // Reset the state
        Object.assign(state, getDefaultState());
    },
};

// Setup our getters
const getters = {
    get: state => {
        return state.snack;
    },
};


// Setup our actions
const actions = {
    /*
     * function setSnackBar()
     *
     * Set the state for a new snack bar message and any associated callback and configuration parameters
     */
    setSnackBar: ({ commit }, snack) => {
        commit('SET_SNACK', snack);
    },

    /*
     * function clearSnackBar()
     *
     * Clears the snackbar message and associated parameters
     */
    clearSnackBar: ({ commit }) => {
        commit('SET_SNACK', null);
    },
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations,
    getters,
    actions,
};
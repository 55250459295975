/**
 * Front-end view controller for the user dashboard
 *
 * Render's the page template, and processes any UI functions and interactivity
 *
 * Note:
 *
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the user dashboard page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 *
 */
<template>
	<v-container v-if="isLoggedIn" class="mb-10">
		<v-row align="start" style="padding-bottom: 60px" v-if="!$vuetify.breakpoint.smAndDown">
			<v-col cols="12" class="pt-4 text-h4 font-weight-medium">
				+Σ DAO - Positive Sum - Platform
			</v-col>
		</v-row>

		<v-row align="start">
			<v-col cols="12" md="8" :class="{ 'text-left': !$vuetify.breakpoint.smAndDown }">
				<v-card elevation="2" style="min-height: 286px">
					<v-container>
						<v-row>
							<v-col cols="12" class="pa-0">
								<!-- Title -->
								<v-card-title class="text-h4 greeting font-weight-medium" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
									Welcome, {{capitalize(userFirstname)}}
								</v-card-title>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" class="pa-0">
								<!-- Subtitle -->
								<v-card-subtitle class="text-h6 font-weight-regular pt-0">
									How are you feeling {{timeGreetingDesc}}?
								</v-card-subtitle>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="8" class="pa-0">
								<v-card-text class="py-0 pr-0">
									<!-- Emotion buttons -->                                
									<div v-for="(e,index) in emotions" :key="index" style="display: inline-block; width: 25%" class="text-center">
										<v-btn :class="emotionSelected(e.emotion)" x-large dark class="checkin" @click="selectEmotion(e.emotion)">
											<v-img :src="checkinImage(e.imageSrc)" />
										</v-btn>
										<div class="mt-2 mb-0">{{e.emotion}}</div>
									</div>
								</v-card-text>
							</v-col>

							<!-- Check in button -->
							<v-col cols="12" md="4" class="ma-auto text-center pl-0 pr-2" :class="{ 'mb-12': !$vuetify.breakpoint.smAndDown, 'py-0': !$vuetify.breakpoint.smAndDown, 'pt-5': $vuetify.breakpoint.smAndDown}">
								<v-btn color="primary" :disabled="!selectedEmotions.length" @click="clickCheckin">Check In</v-btn>
							</v-col>
						</v-row>
							
						<v-row>
							<!-- Help text -->
							<v-col cols="12" class="pa-0 mb-3">
								<v-card-text class="text-body-2 mb-0">Get deeper connection & support from other community members by checking in.</v-card-text>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>

			<!-- Season details -->
			<v-col cols="12" md="4">
				<v-card elevation="2" style="min-height: 286px">
					<v-card-title class="font-weight-bold">Season {{seasonNumber}}</v-card-title>
					<v-card-title class="text-body-2 pt-0">📅 {{seasonFromDate}} - {{seasonToDate}}</v-card-title>
					<v-card-text class="text-body mt-0 py-1 text-left">{{daysRemainingLabel}}</v-card-text>

					<v-card-text class="text-body-2 py-4 text-left">{{seasonDesc}}</v-card-text>
					<v-card-text class="text-body-2 my-0 pt-0 text-left">Read more about the <a :href="seasonInfoUrl" target="_blank">current season's strategy &amp; goals</a> on our wiki.</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<!-- Show recently added people -->
        <v-row align="start" justify="center" v-show="isRecentResults">
            <v-col cols="12" class="text-left mt-3">
                <v-card elevation="2" style="position: relative">
					<!-- Value Exchange Link-->
					<div class="floating-link">
						<v-btn :to="{ name: 'directoryHome'}" small><v-icon left>mdi-account-multiple</v-icon>Search All Members</v-btn>
					</div>

					<!-- Title -->
					<v-card-title class="text-h4 greeting font-weight-medium pb-0" :class="{ 'text-center': $vuetify.breakpoint.smAndDown}">
						New Members
					</v-card-title>

					<!-- Show recent members results -->
					<vue-horizontal class="pl-5 pb-5">
						<section v-for="user in filteredRecentResults" :key="user.userid">
							<ProfileBox
								:id='user.id'
								:firstname='user.firstname'
								:lastname='user.lastname'
								:profileImgSrc='user.profileImg'
								:countryStr='user.countryName'
								:countryCode='user.countryCode'
								:regionStr='user.regionName'
								level=""
								@click="viewMember(user.id)" />
						</section>
					</vue-horizontal>
				</v-card>
            </v-col>
        </v-row>

		<!-- Show recent value created -->
        <v-row align="start" justify="center" v-show="isRecentValueResults">
            <v-col cols="12" class="text-left mt-3">
                <v-card elevation="2" style="position: relative">
					<!-- Value Exchange Link-->
					<div class="floating-link">
						<v-btn :to="{ name: 'valueHome'}" small><v-icon left>mdi-swap-horizontal</v-icon>Log Value Exchanged</v-btn>
						<router-link :to="{ name: 'valueHome'}"></router-link>
					</div>

					<!-- Title -->
					<v-card-title class="text-h4 greeting font-weight-medium pb-0" :class="{ 'text-center': $vuetify.breakpoint.smAndDown}">
						Recent Value Created
					</v-card-title>

					<v-container>
						<!-- No search results -->
						<v-row align="start" justify="space-between" v-if="!isRecentResults">
							<v-col cols="12">
								<h3 class="text-center">No transactions found</h3>
							</v-col>
						</v-row>
						
						<v-row align="start" justify="space-between" v-if="isRecentValueResults">
							<v-col cols="12" lg="4" md="6" sm="12" v-for="v in recentValueResults" :key="v.id">
								<ValueTransactionBox
									:id="v.id"
									:value="v.value"
									:status="v.status"
									:confirmedValue="v.confirmed_value"
									:type="v.type"
									:fromid="v.fromid"
									:fromFirstname="v.from_firstname"
									:fromLastname="v.from_lastname"
									:fromProfileImgSrc="v.from_profileImg"
									:toid="v.toid"
									:toFirstname="v.to_firstname"
									:toLastname="v.to_lastname"
									:toProfileImgSrc="v.to_profileImg"
									:createdAt="v.createdat_ts"
									@click="clickValue"
								></ValueTransactionBox>                             
							</v-col>
						</v-row>
					</v-container>
				</v-card>
            </v-col>
        </v-row>

		<!-- More/Coming soon -->
		<v-row>
			<v-col>
				<span style="display: inline-block">More platform features coming soon - <a @click="comingSoonExpanded = !comingSoonExpanded">{{comingSoonExpanded ? 'hide... &uarr; ' : 'show... &darr;'}}</a></span>
				<div v-bind:class="{ expanded: comingSoonExpanded, expander: true, 'mt-10': true }" @click="comingSoonExpanded = !comingSoonExpanded">		
					<img src="@/assets/imgs/dashboard.png" width="100%" />
					<div class="mt-5 mb-15">Feedback on these features or suggest new ones in our <a target="_blank" :href="platformChannel">#platform channel</a> in discord!</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters , mapActions} from 'vuex';
import { daysDiff } from '@/common/utilities';

// Shared components used in this page
import ProfileBox from '@/components/ProfileBox';
import ValueTransactionBox from '@/components/ValueTransactionBox';
import VueHorizontal from 'vue-horizontal';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';

// API Connectors
import DirectoryAPI from '@/services/DirectoryAPIService.js';
import ValueExchangeAPI from '@/services/ValueExchangeAPIService.js';

// The main dashboard component
export default {
	name: 'UserDashboard',

	data() {
		return {
			// Check-in related
			emotions: [
				{ emotion: 'glad', imageSrc: "emotion_glad", bgColor:"rgba(170, 240, 195, 0.5)", bgColorSelected: "rgba(170, 240, 195, 0.95)"},
				{ emotion: 'mad', imageSrc: "emotion_mad", bgColor:"rgba(245, 177, 178, 0.5)", bgColorSelected: "rgba(245, 177, 178, 0.95)"},
				{ emotion: 'sad', imageSrc: "emotion_sad", bgColor:"rgba(174, 201, 238, 0.5)", bgColorSelected: "rgba(174, 201, 238, 0.95)"},
				{ emotion: 'afraid', imageSrc: "emotion_afraid", bgColor:"rgba(243, 216, 180, 0.5)", bgColorSelected: "rgba(243, 216, 180, 0.95)"},
			],
			selectedEmotions: [],
			checkinChannel: 'https://discord.com/channels/946575162973773835/1032391033767596052',
			lastUpdatedGreeting: null,
			greetingTimer: null,

			// Track last number of hours so we know when to update season countdown
			lastNumHours: '',
			daysRemainingLabel: '',
			seasonLabelTimer: null,
			seasonInfoUrl: 'https://www.notion.so/positivesumdao/Current-Season-Strategy-Overview-07c591051b3343bfbd9af706fe790502',

			// Recent members parameters
            loadingRecent: false,
            recentMax: 8,

			// Recent value
			loadingRecentValue: false,

			// Coming soon section
			comingSoonExpanded: false,
			platformChannel: 'https://discord.com/channels/946575162973773835/946628696415027221',
		};
	},

	components: {
        ProfileBox,
		ValueTransactionBox,
		VueHorizontal,
    },

	computed: {
		// Map our Vuex getters
		...mapGetters({
			isLoggedIn: 'Auth/isLoggedIn',
			userid: 'User/userid',
			userFirstname: 'User/firstname',
			currentSeason: 'Seasons/currentSeason',
		}),

		checkinImage() {
			return (emotion) => require('@/assets/imgs/checkin/'+emotion+'.png');
		},

		timeGreetingDesc() {
			// We create a default 'Good XXX' greeting description based on the time of day
			let str = '';
			if (this.lastUpdatedGreeting >= 2 && this.lastUpdatedGreeting <= 12) {
				str = 'this morning';
			} else if (this.lastUpdatedGreeting >= 12 && this.lastUpdatedGreeting <= 16) {
				str = 'this afternoon';
			} else if (this.lastUpdatedGreeting >= 16 && this.lastUpdatedGreeting <= 21) {
				str = 'this evening';
			} else if (this.lastUpdatedGreeting >= 21 && this.lastUpdatedGreeting <= 2) {
				str = 'tonight';
			}
			return str;
		},

		// Season-related properties shown in the season box
		seasonNumber() {
			return (!this.currentSeason || !this.currentSeason.season) ? '' : this.currentSeason.season;
		},
		
		seasonFromDate() {
			if (!this.currentSeason || !this.currentSeason.fromdate) return '';
			const fromDate = new Date(this.currentSeason.fromdate*1000);
			return fromDate.toLocaleDateString("en", { month: "short", year: "numeric" });
		},

		seasonToDate() {
			if (!this.currentSeason || !this.currentSeason.todate) return '';
			const toDate = new Date(this.currentSeason.todate*1000);
			return toDate.toLocaleDateString("en", { month: "short", year: "numeric" });
		},

		seasonDesc() {
			return (!this.currentSeason || !this.currentSeason.description) ? '' : this.currentSeason.description;
		},

		// Recent new member results - save these details for when we come back to this page
        recentResults: {
            get() { return this.$store.getters['SearchResults/searchResults']('dashboard-recentResults'); },
            set(searchResults) { this.$store.dispatch('SearchResults/setSearchResults', { key: 'dashboard-recentResults', searchResults }); }
        },

        recentPage: {
            get() { return this.$store.getters['SearchResults/searchPage']('dashboard-recentResults'); },
            set(page) { this.$store.dispatch('SearchResults/setSearchPage', { key: 'dashboard-recentResults', page }); }
        },

        recentTotalResults: {
            get() { return this.$store.getters['SearchResults/searchTotalResults']('dashboard-recentResults'); },
            set(totalResults) { this.$store.dispatch('SearchResults/setSearchTotalResults', { key: 'dashboard-recentResults', totalResults }); }
        },

		isRecentResults() {
            return this.recentResults && this.recentResults.length;
        },

        filteredRecentResults() {
            // Cut the results list to a max size and add a search recent option instead to view more
            if (this.recentResults && this.recentResults.length > this.recentMax) {
                return this.recentResults.slice(0, this.recentMax - 1);  // -1 to allow room for the more items link
            } else {
                return this.recentResults;
            }
        },

		// Recent value results - save these details for when we come back to this page
		recentValueResults: {
			get() { return this.$store.getters['SearchResults/searchResults']('dashboard-recentValueResults'); },
			set(searchResults) { this.$store.dispatch('SearchResults/setSearchResults', { key: 'dashboard-recentValueResults', searchResults }); }
		},

		recentValuePage: {
			get() { return this.$store.getters['SearchResults/searchPage']('dashboard-recentValueResults'); },
			set(page) { this.$store.dispatch('SearchResults/setSearchPage', { key: 'dashboard-recentValueResults', page }); }
		},

		recentValueTotalResults: {
			get() { return this.$store.getters['SearchResults/searchTotalResults']('dashboard-recentValueResults'); },
			set(totalResults) { this.$store.dispatch('SearchResults/setSearchTotalResults', { key: 'dashboard-recentValueResults', totalResults }); }
		},

		isRecentValueResults() {
			return this.recentValueResults && this.recentValueResults.length;
		},
	},

	watch: {
		// whenever login state changes and we're no longer logged in, we redirect to login page
		isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
			if (!newIsLoggedIn && oldIsLoggedIn) {
				this.$router.push({ name: 'login' });
			}
		},
	},

	mounted() {
		// Get the list of seasons
		this.getSeasons({ userId: this.userid })
		.then(() => {
			this.daysRemainingLabel = this.getDaysRemainingLabel();

			// Setup a timer to update the label every hour
			this.seasonLabelTimer = this.callEveryFullHour(this.getDaysRemainingLabel);
		});

		// Setup a timer to update the time so the greeting changes
		this.updateGreeting();
		this.greetingTimer = this.callEveryFullHour(this.updateGreeting);
	},

	unmounted() {
		// Clear greeting timer
		if (this.greetingTimer) {
			clearTimeout(this.greetingTimer);
		}

		// Clear season label update timer
		if (this.seasonLabelTimer) {
			clearTimeout(this.seasonLabelTimer);
		}		
	},

	// Get the default lists for recent members
    beforeMount() {
        // Get the list of recent members
		this.getRecentResults();

		// Get the list of recent value transactions
		this.getRecentValueResults();
    },

	methods: {
		// Map our store methods into this component
		...mapActions({
			getSeasons: 'Seasons/getSeasons',
			setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
		}),

		viewMember(fetchId) {
            this.$router.push( { name: 'directoryMember', params: { fetchId }});
        },

		capitalize(value) {
			if (!value) return ''
			value = value.toString()
			return value.charAt(0).toUpperCase() + value.slice(1)
		},

		emotionSelected(emotion) {
			return (this.selectedEmotions.includes(emotion) ? emotion + '-selected' : '');
		},

		selectEmotion(emotion) {
			// Update the local array
			if (!this.selectedEmotions.includes(emotion)) {
				this.selectedEmotions.push(emotion);
			} else {
				this.selectedEmotions = this.selectedEmotions.filter(e => e !== emotion)
			}

			// Reorder the array
			const ordered = [];
			this.emotions.forEach(e => {
				if (this.selectedEmotions.includes(e.emotion)) {
					ordered.push(e.emotion);
				}
			});

			this.selectedEmotions = ordered;
		},

		clickCheckin() {
			if (this.checkinChannel.length) {
				window.open(this.checkinChannel);
				this.selectedEmotions = [];
			}
		},

		callEveryFullHour(my_function) {
			const now = new Date();
			const nextHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 0, 0, 0);
			const difference = nextHour - now;

			if (this.greetingTimer) {
				clearTimeout(this.greetingTimer);
			}

			return window.setTimeout(() => {
				// run it
				my_function();

				// schedule next run
				this.callEveryFullHour(my_function);
			}, difference);
		},

		updateGreeting() {
			this.lastUpdatedGreeting = new Date().getHours();
		},

		getDaysRemainingLabel() {
			if (!this.currentSeason || !this.currentSeason.todate) return '';
			
			// Calculate the number of days remaining in the season
			if (this.currentSeason.todate) {
				const { numDays, numHours, numWeeks } = daysDiff(new Date(), this.currentSeason.todate*1000);
				
				// Only update the label if it's changed
				if (numHours !== this.lastNumHours) {

					// Set the last updated values
					this.lastNumHours = numHours;
					
					// Update the channel name
					if (numDays < 0) {
						return 'Season finished';
					} else if (numDays < 2) {
						return `${numHours} hours remaining...`;
					} else if (numWeeks > 3) {
						return `${numWeeks} weeks remaining...`;
					} else {
						return `${numDays} days remaining...`;
					}
				}
			}

			return '';
		},

		// Get search results from the API endpoint
        getRecentResults(pageNum) {
            // Request the first page unless another page number is set
            let page = pageNum;
            if (!pageNum) {
                page = this.recentPage ? this.recentPage : 1;
            }

			// Set loading indicators
			this.loadingRecent = true;

            // Search via the API endpoint
            DirectoryAPI.recent(this.userid, page, false)
            .then( (response) => {
                // Check our expected data is in the response
                if (!response.recent) {
                    throw new Error('There was a problem, please try again later');
                }

                // Set our expected nearby member results in scope
                this.recentResults = response.recent.items ? response.recent.items : [];
                this.recentTotalResults = response.recent.total ? response.recent.total : 0;
                if (this.recentTotalResults) {
                    this.recentPage = response.recent.page ? response.recent.page : 1;
                } else {
                    this.recentPage = 0;
                }
            })
            .catch( (error) => {
                // Clear the snackbar
                this.clearSnackBar();

                // Error message to show user
                let errorMessage = '';

                if (error instanceof NoResponseAPIError ) {
                    errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                } else if (error instanceof BadMethodAPIError) {
                    errorMessage = 'We encountered a technical problem, please try again later';
                } else if (error instanceof BadRequestAPIError) {
                    errorMessage = 'We encountered a problem, please try again later';
                } else if (error instanceof CredentialsRevokedAPIError) {
                    this.errorMessage = 'You are not allowed to access this data, please contact us for support';
                } else if (error instanceof AuthenticationAPIError) {
                    errorMessage = 'We encountered an authentication problem, please logout and try again';
                } else if (error instanceof InternalServerAPIError) {
                    errorMessage = 'We encountered a server problem, please try again later';
                } else {
                    errorMessage = 'There was a problem, please try again later';
                }

                // Show snackbar error message
                this.setSnackBar({ snack: errorMessage });
            }).finally(() => this.loadingRecent = false);
        },

		// Get recent value transactions from the API endpoint
		getRecentValueResults(pageNum) {
			this.loadingRecentValue = true;
			
			// Request the first page unless another page number is set
			let page = pageNum;
			if (!pageNum) {
				page = this.recentValuePage ? this.recentValuePage : 1;
			}

			// Get which filters are enabled
			const confirmed = true;
			const pending = null;
			const disputed = null;

			// Search via the API endpoint
			ValueExchangeAPI.recent(this.userid, page, this.searchString, confirmed, pending, disputed)
			.then( (response) => {
				// Check our expected data is in the response
				if (!response.recent) {
					throw new Error('There was a problem, please try again later');
				}

				// Set our expected nearby member results in scope
				this.recentValueResults = response.recent.items ? response.recent.items : [];
				this.recentValueTotalResults = response.recent.total ? response.recent.total : 0;
				if (this.recentValueTotalResults) {
					this.recentValuePage = response.recent.page ? response.recent.page : 1;
				} else {
					this.recentValuePage = 0;
				}
			})
			.catch( (error) => {
				// Clear the snackbar
				this.clearSnackBar();

				// Error message to show user
				let errorMessage = '';

				if (error instanceof NoResponseAPIError ) {
					errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
				} else if (error instanceof BadMethodAPIError) {
					errorMessage = 'We encountered a technical problem, please try again later';
				} else if (error instanceof BadRequestAPIError) {
					errorMessage = 'We encountered a problem, please try again later';
				} else if (error instanceof CredentialsRevokedAPIError) {
					this.errorMessage = 'You are not allowed to access this data, please contact us for support';
				} else if (error instanceof AuthenticationAPIError) {
					errorMessage = 'We encountered an authentication problem, please logout and try again';
				} else if (error instanceof InternalServerAPIError) {
					errorMessage = 'We encountered a server problem, please try again later';
				} else {
					errorMessage = 'There was a problem, please try again later';
				}

				// Show snackbar error message
				this.setSnackBar({ snack: errorMessage });
			}).finally(() => this.loadingRecentValue = false);
		},

		clickValue(value) {
			const id = Number(value.id);
			if (id !== Number(this.curValueId)) {
				this.$router.push({ name: 'valueView', params: { valueId: id }});
			}
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	margin: 40px 0 0;
}
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
a {
	color: #42b983;
}

.greeting {
	font-size: 1.8rem !important;
}

button.v-btn.checkin {
	padding: 30px;
	height: 72px;
	width: 72px;
	min-width: 72px !important;
	background-color: #F5F5F5;
}

.glad-selected.v-btn.checkin {
	background-color: rgba(170, 240, 195, 0.5);
}

.mad-selected.v-btn.checkin {
	background-color: rgba(245, 177, 178, 0.5);
}

.sad-selected.v-btn.checkin {
	background-color: rgba(174, 201, 238, 0.5);
}

.afraid-selected.v-btn.checkin {
	background-color: rgba(243, 216, 180, 0.5);
}

.expander {
    transition: max-height 0.25s ease-out;
    transition-property: max-height;
    max-height: 0px;
    overflow: hidden;
    cursor: pointer;
}
.expanded {
    max-height: 1000px;
}

.floating-link {
	position: absolute;
	top: 0;
	right: 0;
	padding: 20px 15px;
}
</style>

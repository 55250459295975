/**
 * Front-end view controller for the user unsubscribed page
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * Note:
 * 
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the user unsubscribed page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container>
        <!-- Title and blurb -->
        <v-row align="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col>
                <h1>You've been unsubscribed</h1>
                <p class="mt-5" v-if="email">We've logged <strong>{{email}}</strong> on our no-email list.</p>
                <p>If you continue to receive emails through our service, please contact us at <a :href="`mailto:${appEmail}?subject=Unsubscribed`">{{appEmail}}</a> and let us know immediately.</p>
                <p>You will receive one final email from us to confirm that we unsubscribed you.</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

// The main page component
export default {
    name: 'ValueConfirmed',

    data() {
        return {
            appEmail: process.env.VUE_APP_CONTACT_EMAIL,
        };
    },

    // Incoming properties for this component
    props: {
        email: { type: String, default: null },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>

/**
 * Front-end view controller for the value exchange add person
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * Note:
 * 
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the admin home page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container v-if="isLoggedIn">
        <v-row align="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col cols="12" md="9" align="left">
                <!-- Back Link -->
                <a class="text-left" @click="navigateBack">&lt; Back</a>
            </v-col>
        </v-row>
        
        <!-- Title and blurb -->
        <v-row align="center" v-if="isLoggedIn">
            <v-col>
                <h1>Who Have You Created {{capitalize(type)}} Value For?</h1>
                <p>Search and choose the community member from the box below</p>
            </v-col>
        </v-row>

        <!-- People Search -->
        <v-row align="center" justify="center" v-if="isLoggedIn && !selectedUser">
            <v-col cols="9">
                <!-- Search box -->
                <v-form @submit.prevent="getDebouncedSearchResults">
                    <v-text-field :rules="searchRules" ref="search" v-model="searchString" solo label="Search by name or discord username..." prepend-inner-icon="mdi-magnify" :loading="loading" clearable></v-text-field>
                </v-form>                
            </v-col>
        </v-row>

        <!-- Search Results -->
        <v-row align="center" justify="center" v-if="isLoggedIn && searchString && !selectedUser">
            <v-col cols="12">
                <!-- Heading -->
                <h2 class="text-center" v-if="noSearchResults">No people found</h2>
                <h2 v-if="isSearchResults" class="text-center">Choose a person</h2>

                <!-- Show search results -->
                 <v-container v-if="isSearchResults">
                    <v-row align="center" justify="center">
                        <v-col cols="12" lg="2" md="3" sm="12" v-for="user in searchResults" :key="user.userid">
                            <ProfileBox
                                :id='user.id'
                                :firstname='user.firstname'
                                :lastname='user.lastname'
                                :profileImgSrc='user.profileImg'
                                :countryStr='user.countryName'
                                :countryCode='user.countryCode'
                                :regionStr='user.regionName'
                                level=""
                                :selectable="true"
                                :selected="selectedUser && user.id === selectedUser.id"
                                @click="select"
                                @select="select"
                                @deselect="deselect" />
                        </v-col>
                    </v-row>

                    <!-- Pagination for long result sets - we bring back -->
                    <v-row align="start" justify="center">
                        <v-col>
                            <v-pagination
                                v-model="page"
                                :length="numPages"
                                :total-visible="7"
                                color="blue-grey lighten-4'"
                                v-if="numPages > 1"
                            ></v-pagination>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <!-- Selected Person -->
        <v-row align="center" justify="center" v-if="isLoggedIn && selectedUser">
            <v-col cols="12" lg="2" md="3" sm="12">
                <ProfileBox
                    :id='selectedUser.id'
                    :firstname='selectedUser.firstname'
                    :lastname='selectedUser.lastname'
                    :profileImgSrc='selectedUser.profileImg'
                    :countryStr='selectedUser.countryName'
                    :countryCode='selectedUser.countryCode'
                    :regionStr='selectedUser.regionName'
                    level=""
                    :selectable="true"
                    :clickable="false"
                    :selected="selectedUser && selectedUser.id === selectedUser.id"
                    @deselect="deselect" />
            </v-col>
        </v-row>

        <!-- Submit Button -->
        <v-btn v-if="isLoggedIn && selectedUser" :disabled="!selectedUser" class="mt-10" @click="submit">Log Value</v-btn>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters, mapActions } from 'vuex';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';

// API Connectors
import ValueExchangeAPI from '@/services/ValueExchangeAPIService.js';

// Shared components used in this page
import ProfileBox from '@/components/ProfileBox';

// The main admin home page component
export default {
    name: 'AddPerson',

    data() {
        return {
            // Search bar props
            loading: false,
            debounceTimer: null,
            searchRules: [],
            numPerPage: 4,

            // Which user is selected
            selectedUser: null,
        };
    },

    // Incoming properties for this component
    props: {
        type: { type: String, default: null },
    },

    components: {
        ProfileBox,
    },

    computed: {
        // Map our Vuex getters
        ...mapGetters({
            isLoggedIn: 'Auth/isLoggedIn',
            userid: 'User/userid',
        }),

        // We save the search parameters to the searchResults store so we can avoid server-side request on viewing items and going back
        searchString: {
            get() { return this.$store.getters['SearchResults/searchString']('value-searchResults'); },
            set(searchString) { this.$store.dispatch('SearchResults/setSearchString', { key: 'value-searchResults', searchString }); }
        },

        searchResults: {
            get() { return this.$store.getters['SearchResults/searchResults']('value-searchResults'); },
            set(searchResults) { this.$store.dispatch('SearchResults/setSearchResults', { key: 'value-searchResults', searchResults }); }
        },

        page: {
            get() { return this.$store.getters['SearchResults/searchPage']('value-searchResults'); },
            set(page) { this.$store.dispatch('SearchResults/setSearchPage', { key: 'value-searchResults', page }); }
        },

        totalResults: {
            get() { return this.$store.getters['SearchResults/searchTotalResults']('value-searchResults'); },
            set(totalResults) { this.$store.dispatch('SearchResults/setSearchTotalResults', { key: 'value-searchResults', totalResults }); }
        },

        isSearchResults() {
            return !this.loading && this.searchResults && this.searchResults.length;
        },

        noSearchResults() {
            return !this.loading && (!this.searchResults || !this.searchResults.length);
        },

        numPages() {
            return !this.totalResults ? 0 : Math.ceil(this.totalResults / this.numPerPage);
        },
    },

    watch: {
        // whenever login state changes and we're no longer logged in, we redirect to login page
        isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
            if (!newIsLoggedIn && oldIsLoggedIn) {
                this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
            }
        },

        // Issue new search on search string changing
        searchString(value, old) {
            // If the value has changed, we reset the pagenumbers
            if (value !== old) {
                this.page = 1;
            }

            // Nothing to search
            if (!value) return;

            // Reset any validation rules so we can do on submit
            this.searchRules =  [];
            this.getDebouncedSearchResults(this.page);
        },

        page(pageNum) {
            // Get the search results with the new page number
            this.getDebouncedSearchResults(pageNum);
        },
    },

    beforeMount() {
        if (!this.type) {
            this.$router.replace({ name: 'valueHome' });
        }
    },

    // If we're not navigating to another exchange page, we clear the searchResults
    beforeRouteLeave(to,from,next) {
        const validRouteNames = ['valueHome', 'valueAddPerson', 'valueLogValue', 'valueLogged', 'valueConfirm', 'valueConfirmed', 'valueDisputed', 'valueView'];
        if (!to.name || validRouteNames.indexOf(to.name) === -1) {
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-searchResults' });
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-recentResults' });
        }
        next();
    },

    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
        }),

        capitalize(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        
        select(user) {
            // lookup this user in the search results
            if (user && user.id) {
                this.selectedUser = Object.assign({}, this.searchResults.find(u => Number(u.id) === Number(user.id)));

                // We immediately move to the next page upon selection
                this.submit();
            }
        },
        
        deselect() {
            this.selectedUser = null;
        },

        navigateBack() {
            // We explicitly go back to value exchange home in case the browser back goes somewhere else
            this.$router.replace({ name: 'valueHome' });
        },

        submit() {
            if (this.isLoggedIn && this.selectedUser && this.selectedUser.id) {
                this.$router.push({ name: 'valueLogValue', params: { type: this.type, user: this.selectedUser } });
            }
        },

        // Delay the search function call by 500ms, to debounce API endpoint
        getDebouncedSearchResults(pageNum) {
            // Cancel any pending calls
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }

            // Delay the search call 500ms
            this.loading = true;                    // do now so don't show no search results header prematurely
            this.debounceTimer = setTimeout(()=>this.getSearchResults(pageNum), 500);
        },

        // Get search results from the API endpoint
        getSearchResults(pageNum) {
            // Empty value, reset any pending search
            if (!this.searchString) {
                this.searchResults = [];
            }

            // Add a validate handler here so only validates on submit, not whilst typing
            this.searchRules = [
                (v) => {
                    if (!v) {
                        return true;
                    } else {
                        return v.trim().length && v.trim().length >= 4 || 'Please enter at least 4 characters';
                    }
                }
            ];
            
            // Validate on next tick so rules have change to be set
            this.$nextTick(() => {
                if (this.$refs.search && this.$refs.search.validate() && this.searchString){
                    // Request the first page unless another page number is set
                    let page = Number(pageNum);
                    if (!page) {
                        page = this.page ? this.page : 1;
                    }

                    // Search via the API endpoint
                    ValueExchangeAPI.search(this.userid, this.searchString, page)
                    .then( (response) => {
                        // Check our expected data is in the response
                        if (!response.results) {
                            throw new Error('There was a problem, please try again later');
                        }

                        // Set our expected results in scope
                        this.searchResults = response.results.items ? response.results.items : [];
                        this.totalResults = response.results.total ? response.results.total : 0;
                        if (this.totalResults) {
                            this.page = response.results.page ? response.results.page : 1;
                        } else {
                            this.page = 0;
                        }
                    })
                    .catch( (error) => {
                        // Clear the snackbar
                        this.clearSnackBar();

                        // Error message to show user
                        let errorMessage = '';

                        if (error instanceof NoResponseAPIError ) {
                            errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                        } else if (error instanceof BadMethodAPIError) {
                            errorMessage = 'We encountered a technical problem, please try again later';
                        } else if (error instanceof BadRequestAPIError) {
                            errorMessage = 'We encountered a problem, please try again later';
                        } else if (error instanceof CredentialsRevokedAPIError) {
                            this.errorMessage = 'You are not allowed to access this data, please contact us for support';
                        } else if (error instanceof AuthenticationAPIError) {
                            errorMessage = 'We encountered an authentication problem, please logout and try again';
                        } else if (error instanceof InternalServerAPIError) {
                            errorMessage = 'We encountered a server problem, please try again later';
                        } else {
                            errorMessage = 'There was a problem, please try again later';
                        }

                        // Show snackbar error message
                        this.setSnackBar({ snack: errorMessage });
                        console.log(error);
                    }).finally(() => this.loading = false);
                } else {
                    this.loading = false;
                }
            });
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>

/**
 * API Middleware for Auth-related API functions
 *
 * Contains transport-level functions to make API calls and process the responses,
 * Setting any relevant data in the Vuex stores, then passing back data back up to the UI for rendering
 * 
 * Notes:
 * 
 * This file also contains a number of utility functions to set auth headers in the underlying xml-rpc layer
 * And also to monitor xml-rpc responses for auth token expiries, and automatically refresh the access tokens
 * 
 * @file   API middleware for auth-related functions
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Common includes used in this file
import axios from '@/common/axios';
import store from '@/store/index';
import { MD5 } from 'crypto-es/lib/md5.js';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import ExpiredTokenAPIError from '@/errors/expiredtokenapierror';
import InvalidTokenAPIError from '@/errors/invalidtokenapierror';
import ForbiddenAPIError from '@/errors/forbiddenapierror';
import NotExistsAPIError from '@/errors/notexistsapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';

// We setup a global variable to hold the auth auto-refresh timer
let autoRefreshTimeout = null;

// Utility function to set the authorisation header
const setAuthHeader = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        axios.defaults.headers.common['Authorization'] = '';
    }
}

/*
 * function validateToken()
 *
 * Utility function to determine if the access token is valid based on aud, iat, nbf, and iss headers
 */
const validateToken = (decoded) => {
  // Initial state is invalid 
  let isValid = false;
  
  if (decoded) {        
      // Check valid issuer and not before time
      if (!decoded.iss || decoded.iss !== process.env.VUE_APP_JWT_ISS) {
          isValid = false;
      } else if (!decoded.nbf || decoded.nbf > Math.floor(Date.now() / 1000)) {
          isValid = false;
      } else {
          // Next check the token is for us by checking the audience field matches what we expect
          // Our audience key will be encoded as a ; separated list of authorised domains
          const audiences = decoded.aud.toLowerCase().split(';');
          const host = window.location.hostname;
          const hostParts = host.split('.').reverse();
          const hostLength = hostParts.length;
          let validAud = false;

          // eslint-disable-next-line
          for (let j=0; j < audiences.length; j++) {
              const aud = audiences[j];
  
              // Determine how many domain levels the audience key specifies and match with the host
              const audParts = aud.split('.').reverse();
              const audLength = audParts.length;
  
              // No match if audience is longer
              // eslint-disable-next-line
              if (!audLength || !hostLength || audLength > hostLength) { continue; }
  
              // Otherwise we match each level until we find a match
              // Note: if aud levels = 2, but host levels = 3, we still match if the top two levels
              // match E.g. if aud = localhost.localdomain, we match xxx.localhost.localdomain
              let audMatch = true;
              /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
              for (let i = 0; i < Math.min(audLength, hostLength); i++) {
                  audMatch = audMatch && (hostParts[i] === audParts[i]);
                  if (!audMatch) break;
              }
  
              // See if we have a match yet
              if (audMatch) {
                  validAud = true;
                  break;
              }
          }

          // Return whether the audience was valid
          isValid = validAud;
      }
  }

  // Return whether the token was validated
  return isValid;
};

/*
* function validateTokenExpiry()
*
* Utility function to determine if the access token has expired based on exp headers in the token
*/
const validateTokenExpiry = (decoded) => {
  // Initial state is expired
  let isExpired = true;

  if (decoded) {
      // Check not expired
      if (!decoded.exp || decoded.exp <= Math.floor(Date.now() / 1000)) {
          isExpired = true;
      } else {
          isExpired = false;
      }
  }
  
  // Return the new state
  return isExpired;
};

/*
 * function refreshAuthTokens ()
 *
 * Creates a singleton factory access to this function in the case of multiple api calls needing to be refreshed and avoiding
 * a race condition of each coming back with new acccess tokens - @see https://github.com/axios/axios/issues/450#issuecomment-247446276
 *
 */
let refreshRequest;
async function refreshAuthTokens(auto = false) {
  if (!refreshRequest) {
    refreshRequest = refresh(auto);
    refreshRequest.then(() => {
        refreshRequest = null;
    }).catch(() => {
        refreshRequest = null;
    });
  }
  return refreshRequest;
}

// Setup an interceptor in the xml-rpc library to handle 'token expired' return status and refresh the token
axios.interceptors.response.use(undefined, async (error) => {
  // If the error originated from a file download request, response.data will be a blob, we need to convert back to JSON first to get our reason
  if (error.request.responseType === 'blob' &&  error.response.data instanceof Blob && error.response.data.type && error.response.data.type.toLowerCase().indexOf('json') != -1) {
      await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      })
      .catch(() => {
        // couldn't decode blob => json, ignore
      });
  }

  // Intercept token expired from any API request and refresh the token first
  if (error.response && error.response.status === 401 && error.response.data.reason === 'AUTH_TOKEN_EXPIRED' && !error.config.isRetryRequest) {
    let refreshSuccessful = false;
    let accessToken = null;
    try {
      // Refresh the access tokens
      const response = await refreshAuthTokens();

      // Determine if we are remembering logins
      const rememberMe = store.getters['Auth/rememberMe'];

      // Set valid tokens from the response
      accessToken = response.data.authTokens.accessToken;
      const tokens = {
          accessToken,
          refreshToken: response.data.authTokens.refreshToken,
          email: response.data.user.email,
      };
      const user = response.data.user;

      // Set the new access tokens in scope
      await store.dispatch('Auth/Login', {
        tokens,
        user,
        rememberMe,
      });
      refreshSuccessful = true;
    } catch (e) {
      // Failed to refresh, so just reset auth tokens and logout
      await store.dispatch('Auth/Logout');
      return Promise.reject(e);
    }

    // Now we retry the original request if successful
    try {
      // Set the authorisation header and retry the original request
      if (refreshSuccessful) {
        const err = error;
        err.config.headers.Authorization = `Bearer ${accessToken}`;
        err.config.isRetryRequest = true;    
        return await axios.request(err.config);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  // This is for a user that isn't logged in correctly
  if (error.response && error.response.status === 401 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
      await store.dispatch('Auth/Logout');
      return Promise.reject(error);
  }

  // If someone gets here we don't want to log them out, because it's more of a general error
  return Promise.reject(error);
});

/*
* function login ()
*
* @param credentials { email: <string>, password: <string>, rememberMe: <boolean> }
*
* Logs a user into the backend using the details provided
*
* Once logged in, a user is given a short-lived accessToken which must be included on future requests to authenticate
* The user.  Additionally, they are given a longer lived refreshToken allowing the accessToken to be newed once it has expired
* For convenience, the login request also returns the user data of the person making the request so it can be cached in the store
*
* Note:
* 
* Login are saved either in the vuex store which will be removed when the browser is shut down, or in localstorage and persist
* According to browser rules if the 'rememberMe' flag is set upon login as part of the credtentials
*/
const login = async (credentials) => {
    // Generate a signed API request using our shared secret key
    if (credentials && credentials.email && credentials.password) {
      const signatureStr = 'auth/login' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + credentials.email + credentials.password;
      credentials.signature = MD5(signatureStr).toString();
    }

    // Post to the API endpoint
    return axios
      .post('auth/login', credentials)
      .then(async response => {
        // Set valid tokens from the response
        const tokens = {
            accessToken: response.data.authTokens.accessToken,
            refreshToken: response.data.authTokens.refreshToken,
            email: response.data.user.email,
        };
        const user = response.data.user;

        // Set the new access tokens in scope
        await store.dispatch('Auth/Login', {
          tokens,
          user,
          rememberMe: credentials.rememberMe,
        });

        // Return the data returned from the API so UI can access it
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
            throw new BadMethodAPIError(error.response, error.request. error);
          } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
            throw new BadRequestAPIError(error.response, error.request. error);
          } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
            throw new CredentialsRevokedAPIError(error.response, error.request. error);
          } else if (error.response.status === 401 && error.response.data.reason === 'TOKEN_EXPIRED') {
            throw new ExpiredTokenAPIError(error.response, error.request. error);
          } else if (error.response.status === 401 && error.response.data.reason === 'NOT_EXISTS') {
            throw new NotExistsAPIError(error.response, error.request. error);
          } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
            throw new InternalServerAPIError(error.response, error.request, error);
          } else {
            throw new Error(error);    
          }
        } else if (error.request) {
          // The request was made but no response was received
          throw new NoResponseAPIError(error.request, error);
        } else {
          // Something happened in setting up the request that triggered an Error
          throw new Error(error);
        }
    });
  }

/*
 * function refresh ()
 *
 * Refreshes A User's Access Token
 *
 * Attempts to get a new accessToken for a user by posting the refreshToken to the API endpoint,
 * including our original accessToken - note doesn't set new tokens in scope, so internal use
 */
const refresh = async (auto) => {
  // Add the current access token to the authorisation header
  const accessToken   = await store.getters['Auth/accessToken'];
  const refreshToken  = await store.getters['Auth/refreshToken'];
  if (!accessToken || !refreshToken) return Promise.reject();
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

  // Check we have some decoded data before we refresh
  const email = await store.getters['User/email'];
  if (!email) {
      throw new Error('Could not find decoded user tokens during auth token refresh');
  }

  // Generate a signed API request using our shared secret key
  const signatureStr = 'auth/refresh' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + email + refreshToken;
  const signature = MD5(signatureStr).toString();

  // Post the refresh endpoint
  return axios.post('auth/refresh', {
      email,
      refreshToken,
      auto,
      signature,
  })
  .then((response)=>{
    // If we don't receive the success message, it's likely something went wrong with the refresh
    if (!response || !response.data || !response.data.reason || response.data.reason !== "SUCCESS") {
      throw new NoResponseAPIError(response, '200 response received from refresh request, but no SUCCESSS reason in response');
    }

    // Return response
    return response;
  })
  .catch((error) => {
    if (error.response) {
      // Request made and server responded
      if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
        throw new BadMethodAPIError(error.response, error.request. error);
      } else if (error.response.status === 415) {
        throw new UnsupportedMediaAPIError(error.response, error.request. error);
      } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
        throw new BadRequestAPIError(error.response, error.request. error);
      } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
        throw new CredentialsRevokedAPIError(error.response, error.request. error);
      } else if (error.response.status === 401 && error.response.data.reason === 'TOKEN_EXPIRED') {
        throw new ExpiredTokenAPIError(error.response, error.request. error);
      } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
        throw new InternalServerAPIError(error.response, error.request, error);
      } else {
        throw new Error(error);
      }
    } else if (error.request) {
      // The request was made but no response was received
      throw new NoResponseAPIError(error.request, error);
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error(error);
    }
  });
}

/*
 * function forgot ()
 *
 * Password Reset Request Function
 *
 * Sends an API request to the password reset endpoint and processes it's response
 */
const forgot = async (credentials) => {
  // Generate a signed API request using our shared secret key
  if (credentials && credentials.email) {
    const signatureStr = 'auth/forgot' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + credentials.email;
    credentials.signature = MD5(signatureStr).toString();
  }

  // Post to the API endpoint
  return axios
    .post('auth/forgot', credentials)
    .then(response => response.data)
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
          throw new BadMethodAPIError(error.response, error.request. error);
        } else if (error.response.status === 415) {
          throw new UnsupportedMediaAPIError(error.response, error.request. error);
        } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
          throw new BadRequestAPIError(error.response, error.request. error);
        } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
          throw new InternalServerAPIError(error.response, error.request, error);
        } else {
          throw new Error(error);    
        }
      } else if (error.request) {
        // The request was made but no response was received
        throw new NoResponseAPIError(error.request, error);
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(error);
      }
  });
}

/*
 * function verifyReset ()
 *
 * Verify Password Reset Function
 *
 * Sends an API request to the verify token endpoint and processes it's response
 */
const verifyReset = async (credentials) => {
  // Generate a signed API request using our shared secret key
  if (credentials && credentials.email && credentials.resetToken) {
    const signatureStr = 'auth/verifyReset' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + credentials.email + credentials.resetToken;
    credentials.signature = MD5(signatureStr).toString();
  }

  // Post to the API endpoint
  return axios
    .post('auth/verifyReset', credentials)
    .then(response => response.data)
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
          throw new BadMethodAPIError(error.response, error.request. error);
        } else if (error.response.status === 415) {
          throw new UnsupportedMediaAPIError(error.response, error.request. error);
        } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
          throw new BadRequestAPIError(error.response, error.request. error);
        } else if (error.response.status === 401 && error.response.data.reason === 'INVALID_TOKEN') {
          throw new InvalidTokenAPIError(error.response, error.request. error);
        } else if (error.response.status === 401 && error.response.data.reason === 'TOKEN_EXPIRED') {
          throw new ExpiredTokenAPIError(error.response, error.request. error);
        } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
          throw new ForbiddenAPIError(error.response, error.request. error);
        } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
          throw new InternalServerAPIError(error.response, error.request, error);
        } else {
          throw new Error(error);    
        }
      } else if (error.request) {
        // The request was made but no response was received
        throw new NoResponseAPIError(error.request, error);
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(error);
      }
  });
}

/*
 * function reset ()
 *
 * Password Reset Change Function
 *
 * Sends an API request to the change password endpoint and processes it's response
 */
const reset = async (credentials) => {
  // Generate a signed API request using our shared secret key
  if (credentials && credentials.email && credentials.resetToken && credentials.password) {
    const signatureStr = 'auth/reset' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + credentials.email + credentials.resetToken + credentials.password;
    credentials.signature = MD5(signatureStr).toString();
  }

  // Post to the API endpoint
  return axios
    .post('auth/reset', credentials)
    .then(response => response.data)
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
          throw new BadMethodAPIError(error.response, error.request. error);
        } else if (error.response.status === 415) {
          throw new UnsupportedMediaAPIError(error.response, error.request. error);
        } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
          throw new BadRequestAPIError(error.response, error.request. error);
        } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
          throw new CredentialsRevokedAPIError(error.response, error.request. error);
        } else if (error.response.status === 401 && error.response.data.reason === 'INVALID_TOKEN') {
          throw new InvalidTokenAPIError(error.response, error.request. error);
        } else if (error.response.status === 401 && error.response.data.reason === 'TOKEN_EXPIRED') {
          throw new ExpiredTokenAPIError(error.response, error.request. error);
        } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
          throw new InternalServerAPIError(error.response, error.request, error);
        } else {
          throw new Error(error);    
        }
       } else if (error.request) {
        // The request was made but no response was received
        throw new NoResponseAPIError(error.request, error);
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(error);
      }
  });
}

/*
 * function register ()
 *
 * Requests a registration pre-auth request token
 *
 * Registration is done in two steps to stop maliciios abuse of the register endpoint.
 * First a user must call auth/register endpoint to get a token, then send that token in a user/register request
 * Note this pre-auth token is short-lived so is expected to be called one after the other
 */
const register = async () => {
  // Generate a signed API request using our shared secret key
  const signatureStr = 'auth/register' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET;
  const signature = MD5(signatureStr).toString();

  // Post to the API endpoint
  return axios
    .post('auth/register', { signature })
    .then(response => response.data)
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
          throw new BadMethodAPIError(error.response, error.request. error);
        } else if (error.response.status === 415) {
          throw new UnsupportedMediaAPIError(error.response, error.request. error);
        } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
          throw new BadRequestAPIError(error.response, error.request. error);
        } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
          throw new InternalServerAPIError(error.response, error.request, error);
        } else {
          throw new Error(error);    
        }
       } else if (error.request) {
        // The request was made but no response was received
        throw new NoResponseAPIError(error.request, error);
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(error);
      }
  });
}

// API endpoint functions
export default {
  setAuthHeader,
  autoRefreshTimeout,
  validateToken,
  validateTokenExpiry,
  refreshAuthTokens,
  login,
  refresh,
  forgot,
  verifyReset,
  reset,
  register,
};
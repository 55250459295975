/**
 * Front-end view controller for the about page
 *
 * Render's the page template, and processes any UI functions and interactivity
 *
 * Note:
 *
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the about page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 *
 */
<template>
    <v-container>
        <!-- Navigation -->
        <HomeNavigation />

        <v-row align="start">
            <v-col :class="{ 'text-left': this.$vuetify.breakpoint.mdAndUp, 'text-center': this.$vuetify.breakpoint.smAndDown }" style="padding-bottom: 200px">
                <!-- Banner -->
                <v-img style="cursor: pointer;" :src="require('@/assets/imgs/home/home3.png')" width="100%" contain />
                
                <!-- Title -->
                <h3 class="mt-10 mb-15">Positive Sum is a peer-to-peer community of startups, founders, and startup community 'enablers' who collaborate to help entrepreneurs and startups grow, and who capture that value across the entire community for the long term using Web3 as a platform</h3>

                <!-- Intro -->
                <p class="font-weight-bold mt-15 mb-10">We're an open and transparent community, operating as a <a href="https://www.forbes.com/sites/cathyhackl/2021/06/01/what-are-daos-and-why-you-should-pay-attention/" target="_blank">DAO</a> at the intersection of the creator x ownership x startup value economies, meaning that the more people share their networks, their knowledge, and their know-how to help others succeed, the more the community as a whole becomes more successful and we all win together.</p>
                <p>We're building a set of community-owned and operated programmes, services, and shared platform tools to help founders grow, rewarding contribution through a unique contribute-to earn model to help us pay it forward and ultimately create a flywheel to allow us to help more and more people on the journey.</p>
                
                <v-img class="my-15" style="cursor: pointer;" :src="require('@/assets/imgs/home/home6.png')" width="100%" contain />

                <h3 class="mt-15">Our Why?</h3>
                <p>We believe that entrepreneurship is one of the most important ways to have personal impact in the world, and whilst they say it 'takes a village to raise a startup', the value created by all participants of that village are rarely distributed back in fair proportions to help the entire village grow. Navigating that journey is unnecessarily hard, often exclusive, and primarily rewards founders and financiers, not everyone else who helps create value along the journey.</p>
                
                <p class="callout font-weight-bold mt-15">Our ultimate mission is to help more people create positive impact in the world through entrepreneurship.</p>

                <h3 class="mt-15">Get Involved</h3>
                <p>Our intention is to build a strong values-led, psychologically-safe, startup community with participants who support each other with a strong spirit-of-service to help each other get ahead.</p>
                <p>Setup your profile and use the community services here on this platform, then come join the community discussion in our discord server - we'd love to see you and support you on your journey.</p>

                <!-- Buttons -->
                <div class="text-center mt-15">
                    <v-btn outlined class="mt-5" :to="{name: 'register'}">Setup Profile</v-btn>
                    <v-btn outlined class="ml-5 mt-5" @click="openUrl('https://discord.gg/3dhddwGSMf')">Join Discord</v-btn>
                    <v-btn outlined class="mt-5" :class="{ 'ml-5': this.$vuetify.breakpoint.mdAndUp }" @click="openUrl('https://positivesum.community/')">Our Website</v-btn>
                    <v-btn outlined class="ml-5 mt-5" @click="openUrl('https://positivesumdao.notion.site/')">DAO Docs</v-btn>                
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Import our custom components
import HomeNavigation from '@/components/HomeNavigation';

// The about page component
export default {
    name: 'HomeAbout',

    components: {
        HomeNavigation,
    },
    
    methods: {
        openUrl(url) {
            if (url) {
                window.open(url);
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}

.callout {
    padding: 15px;
    background-color: #eeeeee;
    text-align: center;
}
</style>

function daysAgoLabel(timestamp, isTimestamp = true, isDateOnly = false, showTime = true) {
    if (!timestamp) return '';
  
    // If date only, then we set current time to current day at midnight so calculations are correct
    let today = new Date();
    if (isDateOnly) {
      today.setHours(0, 0, 0, 0);
    }
    today = today.getTime() / 1000;
  
    // Convert to timestamp
    let ago = timestamp;
    let parts = null;
    if (!isTimestamp) {
      if (isDateOnly) {
        parts = timestamp.match(/(\d{4})-(\d{2})-(\d{2})/);
        ago = new Date(+parts[1], +parts[2] - 1, +parts[3], 0, 0, 0).getTime() / 1000;
      } else {
        parts = timestamp.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
        ago = new Date(+parts[1], +parts[2] - 1, +parts[3], +parts[4], +parts[5], +parts[6])
          .getTime() / 1000;
      }
    }
    let secondsDiff = today - ago;
    const daysAgo = secondsDiff / 60 / 60 / 24;
    const weeksAgo = daysAgo / 7;
    const monthsAgo = daysAgo / 30;
    let daysTogo = -1;
    let weeksTogo = -1;
    let monthsTogo = -1;
  
    // Forward dates
    if (secondsDiff < 0) {
      secondsDiff = ago - today;
      daysTogo = secondsDiff / 60 / 60 / 24;
      weeksTogo = daysTogo / 7;
      monthsTogo = daysTogo / 30;
    }
  
    // Forward dates first
    const dStr = formatAMPM(new Date(ago * 1000));
    if (today - ago < 0) {
      const now = today;
      let tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      tomorrow = tomorrow.getTime() / 1000;
      const secsTilMidnight = tomorrow - now;
  
      if (secondsDiff < secsTilMidnight) {
        return `today${(!isDateOnly && showTime ? ` at ${dStr}` : '')}`;
      } else if (daysTogo < 2) {
        return `tomorrow${(!isDateOnly && showTime ? `at ${dStr}` : '')}`;
      } else if (Math.floor(weeksTogo) > 4 && Math.floor(weeksTogo) < 8) {
        return `in ${Math.floor(weeksTogo)} weeks`;
      } else if (Math.floor(weeksTogo) === 4) {
        return 'in 1 month';
      } else if (monthsTogo > 12) {
        return `in ${Math.floor(monthsTogo / 12)}+ year${Math.floor(monthsTogo) / 12 > 1 ? 's' : ''}`;
      } else if (monthsTogo > 1) {
        return `in ~ ${Math.ceil(monthsTogo)} months`;
      } else if (Math.floor(daysTogo) === 7) {
        return 'in 1 week';
      } else if (Math.floor(daysTogo) % 7 === 0) {
        return `in ${Math.floor(daysTogo) / 7} week${Math.floor(daysTogo) / 7 > 1 ? 's' : ''}`;
      }
      return `in ${Math.floor(daysTogo)} days`;
    }
  
    if (daysAgo < 1) {
      return `today${(!isDateOnly && showTime ? ` at ${dStr}` : '')}`;
    } else if (daysAgo < 2) {
      return `yesterday${(!isDateOnly && showTime ? ` at ${dStr}` : '')}`;
    } else if (monthsAgo > 12) {
      return `${Math.floor(monthsAgo / 12)}+ years ago`;
    } else if (monthsAgo > 1) {
      return `${Math.ceil(monthsAgo)} months ago`;
    } else if (Math.ceil(weeksAgo) === 4) {
      return '1 month ago';
    } else if (weeksAgo > 1) {
      return `${Math.ceil(weeksAgo)} weeks ago`;
    } else if (daysAgo >= 6 && daysAgo <= 7) {
      return '1 week ago';
    }
    return `${Math.ceil(daysAgo)} days ago`;
}

function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    if (!hours) hours = 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    return strTime;
}

const getCurrentTimestamp = () => {
  let current = new Date();
  let cDate = current.toISOString().split('T')[0];
  let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
  let dateTime = cDate + ' ' + cTime;
  return dateTime;
};

const daysDiff = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // convert ms to hours
  const oneHour = oneDay / 24;

  // convert ms to weeks
  const oneWeek = oneDay * 7;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Convert to days
  const diffInDays = Math.floor(diffInTime / oneDay);

  // Convert to hours
  const diffInHours = Math.floor(diffInTime / oneHour);

  // Convert to weeks
  const diffInWeeks = Math.floor(diffInTime / oneWeek);

  return {
    numDays: diffInDays,
    numHours: diffInHours,
    numWeeks: diffInWeeks,
    numMs: diffInTime
  };
}

export {
  daysAgoLabel,
  formatAMPM,
  getCurrentTimestamp,
  daysDiff,
};
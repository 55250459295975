/**
 * Home navigation component
 *
 * This renders the home navigation links and shows which item is currently selected
 * 
 *
 * @file   Side navigation component
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <nav id="top">
        <!-- The menu bar -->
        <v-app-bar app color="#fffffff0" elevate-on-scroll dark extended extension-height="40" fixed flat>
            <!-- Logo/Heading --->
            <v-hover v-slot="{ hover }">
                <h1 color="#ffd700" class="logo mt-10 mx-5" :style="{ 'color': hover ? '#BB86FC' : '#6A6A6A' }" @click="navigateHome">+Σ Platform</h1>
            </v-hover>
            
            <v-spacer></v-spacer>

            <!-- Menu Items -->
            <template v-for="(item, i) in items">
                <v-hover :key="i" v-slot="{ hover }" v-if="!$vuetify.breakpoint.smAndDown">
                    <v-btn v-if="item.icon" text style="min-width: auto;" light large class="mx-2 px-2 mt-10" :color="hover ? '#BB86FC' : '#6A6A6A'" :to="item.route ? { name: item.route, hash: (item.hash ? item.hash : null) } : null" @click="openUrl(item.url)">
                        <img :src="hover ? item.iconHover : item.icon" height='30' />
                    </v-btn>
                    <v-btn v-else :outlined="item.outlined" text light large style="min-width: auto; text-transform: none;" class="mx-2 px-2 mt-10 nav" :color="hover ? '#BB86FC' : '#6A6A6A'" :class="{ 'ml-5': item.outlined }" :to="item.route ? { name: item.route, hash: (item.hash ? item.hash: '') } : null" @click="openUrl(item.url)">{{item.text}}</v-btn>
                </v-hover>
            </template>
            
            <!-- Mobile menu opener -->
            <v-hover v-slot="{ hover }" v-if="$vuetify.breakpoint.smAndDown">
                <v-app-bar-nav-icon class="mt-10 mr-0" large :style="{ 'color': hover ? '#BB86FC' : '#6A6A6A' }" @click.stop="drawer = !drawer" />
            </v-hover>
        </v-app-bar>

        <!-- Side nav for small browsers -->
        <v-navigation-drawer v-model="drawer" :permanent="false" app mini-variant mini-variant-width="60%" v-if="$vuetify.breakpoint.smAndDown">
            <v-list class="pb-0">
                <v-list-item v-for="(item, i) in items" :key="i">
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-hover v-slot="{ hover }">
                                <v-btn v-if="item.icon" text large class="mt-5 text-left" :to="item.route ? { name: item.route, hash: (item.hash ? item.hash: '') } : null" @click="openUrl(item.url)">
                                    <img :src="hover ? item.iconHover : item.icon" height='30' />
                                </v-btn>
                                <v-btn v-else :outlined="item.outlined" height='50' text large style="text-transform: none;" class="text-left mt-5 nav" :color="hover ? '#BB86FC' : '#6A6A6A'" :to="item.route ? { name: item.route, hash: (item.hash ? item.hash: '') } : null" @click="openUrl(item.url)">{{item.text}}</v-btn>
                            </v-hover>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </nav>
</template>

<script>

// The Home Navigation component
export default {
    name: 'HomeNavigation',

    data() {
        return {
            // Visibility
            drawer: null,

            // The menu items
            items: [
                { text: 'Why Join?', route: 'home', hash: '#why-join'},
                { text: 'How It Works', route: 'home', hash: '#how-it-works'},
                { text: 'FAQ', route: 'home', hash: '#faq'},
                { text: 'About +Σ', route: 'homeAbout', },
                { url: 'https://discord.gg/3dhddwGSMf', icon: require('@/assets/imgs/nav/discord.png'), iconHover: require('@/assets/imgs/nav/discord_hover.png') },        // Attribution: https://www.flaticon.com/free-icons/discord"
                { text: 'Launch App', route: 'dashboard', outlined: true },
            ],
        };
    },

    methods: {
        navigateHome() {
            if (this.$route.name !== 'home') {
                this.$router.push({ name: 'home' });
                this.drawer = false;
            } else {
                this.$vuetify.goTo('#top');
            }
        },

        openUrl(url) {
            if (url) {
                this.drawer = false;
                window.open(url);
            }
        },
    }
}
</script>

<style scoped>
.logo {
    cursor: pointer;
}

.nav {
    font-size: 1rem;
}
</style>

/**
 * API Middleware for Value Exchange-related API functions
 *
 * Contains transport-level functions to make API calls and process the responses,
 * Setting any relevant data in the Vuex stores, then passing back data back up to the UI for rendering
 * 
 * @file   API middleware for value exchange-related functions
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Common includes used in this file
import axios from '@/common/axios';
import { MD5 } from 'crypto-es/lib/md5.js';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';
import AlreadyActionedAPIError from '@/errors/alreadyactionedapierror';
import NotExistsAPIError from '@/errors/notexistsapierror';

/*
 * function search ()
 *
 * API call to search the people to exchange value for and return search results
 *
 */
const search = async (userid, search, page) => {
    // Check we have something to search for
    if (!search) {
        return;
    }

    // Generate a signed API request using our shared secret key
    const signatureStr = 'value/searchPeople' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userid + search.trim().toLowerCase() + page;
    const signature = MD5(signatureStr).toString();

    // Post to the user endpoint
    return axios.post('value/searchPeople', {
        userid,
        search,
        page,
        signature,
    }).then(response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function logValue()
 *
 * API call to log a value transaction between two people
 *
 */
const logValue = async (type, value, fromId, toId, data) => {
    // Check our fields are specified
    if (!type|| !value || !fromId || !toId) {
        return false;
    }

    // Generate a signed API request using our shared secret key
    const signatureStr = 'value/logValue' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + type + value + fromId + toId;
    const signature = MD5(signatureStr).toString();
    data.signature = signature;

    // Post to the logValue endpoint
    return axios.post('value/logValue', data).then(async response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function getTags()
 *
 * API call to get the tags for a user and a capital type
 *
 */
const getTags = async (type, userId) => {
    // Check our fields are specified
    if (!type || !userId) {
        return false;
    }

    // Generate a signed API request using our shared secret key
    const signatureStr = 'value/getTags' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + type + userId;
    const signature = MD5(signatureStr).toString();
    
    // Post to the logValue endpoint
    return axios.post('value/getTags', {
        type,
        userId,
        signature,
    }).then(async response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function getValue()
 *
 * API call to get a value transaction between two people
 *
 */
const getValue = async (userId, valueId) => {
    // Check our fields are specified
    if (!userId || !valueId) {
        return false;
    }

    // Generate a signed API request using our shared secret key
    const signatureStr = 'value/getValue' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userId + valueId;
    const signature = MD5(signatureStr).toString();
    
    // Post to the logValue endpoint
    return axios.post('value/getValue', {
        userId,
        valueId,
        signature,
    }).then(async response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'NOT_EXISTS') {
                throw new NotExistsAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function confirmValue()
 *
 * API call to confirm a value transaction between two people
 *
 */
const confirmValue = async (valueId, value, userId, data) => {
    // Check our fields are specified
    if (!valueId || !value || !userId) {
        return false;
    }

    // Generate a signed API request using our shared secret key
    const signatureStr = 'value/confirmValue' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + valueId + value + userId;
    const signature = MD5(signatureStr).toString();
    data.signature = signature;

    // Post to the confirmValue endpoint
    return axios.post('value/confirmValue', data).then(async response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'NOT_EXISTS') {
                throw new NotExistsAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'ALREADY_ACTIONED') {
                throw new AlreadyActionedAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function getLatestValue()
 *
 * API call to get a latest value transaction list for a user
 *
 */
const getLatestValue = async (userId) => {
    // Check our fields are specified
    if (!userId) {
        return false;
    }

    // Generate a signed API request using our shared secret key
    const signatureStr = 'value/getLatestValue' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userId;
    const signature = MD5(signatureStr).toString();
    
    // Post to the logValue endpoint
    return axios.post('value/getLatestValue', {
        userId,
        signature,
    }).then(async response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'NOT_EXISTS') {
                throw new NotExistsAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function recent()
 *
 * API call to get the list of most recent value transactions
 *
 */
const recent = async (userId, page, searchString, confirmed, pending, disputed, given, received, fetchUserId, season) => {
    // Check our fields are specified
    if (!userId) {
        return false;
    }

    // Generate a signed API request using our shared secret key
    const signatureStr = 'value/recent' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userId + page;
    const signature = MD5(signatureStr).toString();

    // Build submit data based on whether searching or filters set
    let submitData = {
        userId,
        page,
        signature,
    };

    if (searchString && searchString !== '') {
        submitData.search = searchString;
    }

    if (confirmed) {
        submitData.confirmed = confirmed;
    }

    if (pending) {
        submitData.pending = pending;
    }

    if (disputed) {
        submitData.disputed = disputed;
    }

    if (given) {
        submitData.given = given;
    }

    if (received) {
        submitData.received = received;
    }

    if (fetchUserId) {
        submitData.fetchUserId = Number(fetchUserId);
    }

    if (season) {
        submitData.season = Number(season);
    }
    
    // Post to the logValue endpoint
    return axios.post('value/recent', submitData).then(async response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'NOT_EXISTS') {
                throw new NotExistsAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function getSeasons()
 *
 * API call to get the list of seasons
 *
 */
const getSeasons = async (userId) => {
    // Check our fields are specified
    if (!userId) {
        return false;
    }

    // Generate a signed API request using our shared secret key
    const signatureStr = 'value/getSeasons' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userId;
    const signature = MD5(signatureStr).toString();

    // Post to the endpoint
    return axios.post('value/getSeasons', {
        userId,
        signature,
    }).then(async response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'NOT_EXISTS') {
                throw new NotExistsAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
* function topValue()
*
* API call to get the list of top value creators for the season
*
*/
const topValue = async (userId, season) => {
   // Check our fields are specified
   if (!userId) {
       return false;
   }

   // Generate a signed API request using our shared secret key
   const signatureStr = 'value/topValue' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userId + season;
   const signature = MD5(signatureStr).toString();

   // Post to the logValue endpoint
   return axios.post('value/topValue', {
       userId,
       season,
       signature,
   }).then(async response => {
       // Return the data returned from the API so UI can access it
       return response.data;
   })
   .catch((error) => {
       if (error.response) {
           // Request made and server responded
           if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
               throw new BadMethodAPIError(error.response, error.request. error);
           } else if (error.response.status === 401 && error.response.data.reason === 'NOT_EXISTS') {
               throw new NotExistsAPIError(error.response, error.request. error);
           } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
               throw new BadRequestAPIError(error.response, error.request. error);
           } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
               throw new CredentialsRevokedAPIError(error.response, error.request. error);
           } else if (error.response.status === 401 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
               throw new AuthenticationAPIError(error.response, error.request. error);
           } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
               throw new InternalServerAPIError(error.response, error.request, error);
           } else {
               throw new Error(error);    
           }
       } else if (error.request) {
           // The request was made but no response was received
           throw new NoResponseAPIError(error.request, error);
       } else {
           // Something happened in setting up the request that triggered an Error
           throw new Error(error);
       }
   });
}

// Export each API endpoint
export default {
    search,
    logValue,
    getTags,
    getValue,
    confirmValue,
    getLatestValue,
    recent,
    getSeasons,
    topValue,
};
/**
 * Front-end view controller for the member directory home page
 *
 * Renders the page template, and processes any UI functions and interactivity
 *
 * Note:
 *
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the member directory home page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 *
 */
<template>
    <v-container v-if="isLoggedIn">
        <v-row align="start" justify="center" class="mb-15" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col cols="11" xs="12">
                <!-- Search box -->
                <v-form @submit.prevent="getDebouncedSearchResults">
                    <v-text-field :rules="searchRules" ref="search" v-model="searchString" solo label="Search for people, skills, locations, and more..." :loading="loading" clearable>
                        <template v-slot:prepend-inner>
                            <v-icon class="mr-2">mdi-magnify</v-icon>
                            <v-chip v-if="nearbySearchFilter" color="orange lighten-1" text-color="white" class="mr-2" label small close @click:close="clearNearbyChip">Nearby</v-chip>
                            <v-chip v-if="recentSearchFilter" color="green lighten-1" text-color="white" class="mr-2" label small close @click:close="clearRecentChip">Recent</v-chip>
                        </template>
                    </v-text-field>
                </v-form>

                <!-- Chips for common searches -->
                <span class="mr-2 mb-2">Examples:</span>
                <v-chip color="blue-grey lighten-4'" dark label small class="mr-2 mb-2" @click="searchString = 'product'">Product</v-chip>
                <v-chip color="blue-grey lighten-4'" dark label small class="mr-2 mb-2" @click="searchString = 'strategy'">Strategy</v-chip>
                <v-chip color="blue-grey lighten-4'" dark label small class="mr-2 mb-2" @click="searchString = 'web3'">Web3</v-chip>
                <v-chip color="blue-grey lighten-4'" dark label small class="mr-2 mb-2" @click="searchString = 'fintech'">FinTech</v-chip>
                <v-chip color="blue-grey lighten-4'" dark label small class="mr-2 mb-2" @click="searchString = 'new zealand'">New Zealand</v-chip>
                <v-chip color="blue-grey lighten-4'" dark label small class="mr-2 mb-2" @click="searchString = 'investing (early-stage)'">Investing (Early-Stage)</v-chip>
                <v-chip color="orange lighten-1" :outlined="nearbySearchFilter" label small class="mr-2 mb-2" dark @click="clickNearbyChip">Nearby</v-chip>
                <v-chip color="green lighten-1" :outlined="recentSearchFilter" label small dark class="mb-2" @click="clickRecentChip">Recent</v-chip>
            </v-col>
        </v-row>

        <!-- Search Results -->
        <v-row align="start" justify="center" v-if="searchString">
            <v-col cols="12" class="text-left">
                <!-- Heading -->
                <h2 class="text-center" v-if="noSearchResults">No search results found</h2>
                <h2 v-if="isSearchResults" :class="{ 'text-center': $vuetify.breakpoint.smAndDown}">Search results</h2>

                <!-- Show search results -->
                 <v-container v-if="isSearchResults">
                    <v-row align="start" justify="start">
                        <v-col cols="12" lg="2" md="3" sm="12" v-for="user in searchResults" :key="user.userid">
                            <ProfileBox
                                :id='user.id'
                                :firstname='user.firstname'
                                :lastname='user.lastname'
                                :profileImgSrc='user.profileImg'
                                :countryStr='user.countryName'
                                :countryCode='user.countryCode'
                                :regionStr='user.regionName'
                                level=""
                                @click="viewMember(user.id)" />
                        </v-col>
                    </v-row>

                    <!-- Pagination for long result sets - we bring back -->
                    <v-row align="start" justify="center">
                        <v-col>
                            <v-pagination
                                v-model="page"
                                :length="numPages"
                                :total-visible="7"
                                color="blue-grey lighten-4'"
                                v-if="numPages > 1"
                            ></v-pagination>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <!-- Show recently added people (within last 3 months) if not searching -->
        <v-row align="start" justify="center" v-show="!searchString && isRecentResults">
            <v-col cols="12" class="text-left">
                <!-- Heading -->
                <h2 v-show="isRecentResults" :class="{ 'text-center': $vuetify.breakpoint.smAndDown}">Recent Members</h2>

                <!-- Show nearby results -->
                 <v-container v-show="isRecentResults">
                    <v-row align="start" justify="start">
                        <v-col cols="12" lg="2" md="3" sm="12" v-for="user in filteredRecentResults" :key="user.userid">
                            <ProfileBox
                                :id='user.id'
                                :firstname='user.firstname'
                                :lastname='user.lastname'
                                :profileImgSrc='user.profileImg'
                                :countryStr='user.countryName'
                                :countryCode='user.countryCode'
                                :regionStr='user.regionName'
                                level=""
                                @click="viewMember(user.id)" />
                        </v-col>

                        <!-- Add a view more if result set too large -->
                        <v-col cols="12" lg="2" md="3" sm="12" v-if="hasMoreRecentResults">
                            <ViewMoreBox @click="clickMoreRecent" title="More recent members" />
                        </v-col>
                    </v-row>

                    <!-- Loading indicator -->
                    <v-row align="start" justify="center" v-if="isRecentResults">
                        <v-col cols="9">
                            <v-progress-linear indeterminate rounded height="3" :background-color="loadingRecent ? null : 'white'" :color="loadingRecent ? 'primary' : 'white'" v-if="recentSearchFilter" />
                        </v-col>
                    </v-row>
                    
                    <!-- Nearby pagination for long result sets -->
                    <v-row align="start" justify="center">
                        <v-col>
                            <v-pagination
                                v-model="recentPage"
                                :length="numRecentPages"
                                :total-visible="7"
                                color="blue-grey lighten-4'"
                                v-if="numRecentPages > 1 && recentSearchFilter"
                            ></v-pagination>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <!-- Show people near you if not searching -->
        <v-row align="start" justify="center" v-if="!searchString && isNearbyResults">
            <v-col cols="12" class="text-left">
                <!-- Heading -->
                <h2 v-if="isNearbyResults" :class="{ 'text-center': $vuetify.breakpoint.smAndDown}">Local Members</h2>

                <!-- Show nearby results -->
                 <v-container v-if="isNearbyResults">
                    <v-row align="start" justify="start">
                        <v-col cols="12" lg="2" md="3" sm="12" v-for="user in filteredNearbyResults" :key="user.userid">
                            <ProfileBox
                                :id='user.id'
                                :firstname='user.firstname'
                                :lastname='user.lastname'
                                :profileImgSrc='user.profileImg'
                                :countryStr='user.countryName'
                                :countryCode='user.countryCode'
                                :regionStr='user.regionName'
                                level=""
                                @click="viewMember(user.id)" />
                        </v-col>

                        <!-- Add a view more if result set too large -->
                        <v-col cols="12" lg="2" md="3" sm="12" v-if="hasMoreNearbyResults">
                            <ViewMoreBox @click="clickMoreNearby" title="More local members" />
                        </v-col>
                    </v-row>

                    <!-- Loading indicator -->
                    <v-row align="start" justify="center" v-if="isNearbyResults">
                        <v-col cols="9">
                            <v-progress-linear indeterminate rounded height="3" :background-color="loadingNearby ? null : 'white'" :color="loadingNearby ? 'primary' : 'white'" v-if="nearbySearchFilter" />
                        </v-col>
                    </v-row>

                    <!-- Nearby pagination for long result sets -->
                    <v-row align="start" justify="center">
                        <v-col>
                            <v-pagination
                                v-model="nearbyPage"
                                :length="numNearbyPages"
                                :total-visible="7"
                                color="blue-grey lighten-4'"
                                v-if="numNearbyPages > 1 && nearbySearchFilter"
                            ></v-pagination>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters, mapActions } from 'vuex';

// Shared components used in this page
import ProfileBox from '@/components/ProfileBox';
import ViewMoreBox from '@/components/ViewMoreBox';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';

// API Connectors
import DirectoryAPI from '@/services/DirectoryAPIService.js';

// The main user home page component
export default {
    name: 'DirectoryHome',

    data() {
        return {
            // Search bar props
            loading: false,
            debounceTimer: null,
            searchRules: [],
            numPerPage: 20,

            // nearby search parameters
            loadingNearby: false,
            nearbyMax: 8,
            debounceNearbyTimer: null,

            // recent search parameters
            loadingRecent: false,
            recentMax: 8,
            debounceRecentTimer: null,
        };
    },

    components: {
        ProfileBox,
        ViewMoreBox,
    },

    computed: {
        // Map our Vuex getters
        ...mapGetters({
            isLoggedIn: 'Auth/isLoggedIn',
            userid: 'User/userid',
        }),

        // We save the search parameters to the searchResults store so we can avoid server-side request on viewing items and going back
        searchString: {
            get() { return this.$store.getters['SearchResults/searchString']('directory-searchResults'); },
            set(searchString) { this.$store.dispatch('SearchResults/setSearchString', { key: 'directory-searchResults', searchString }); }
        },

        searchResults: {
            get() { return this.$store.getters['SearchResults/searchResults']('directory-searchResults'); },
            set(searchResults) { this.$store.dispatch('SearchResults/setSearchResults', { key: 'directory-searchResults', searchResults }); }
        },

        page: {
            get() { return this.$store.getters['SearchResults/searchPage']('directory-searchResults'); },
            set(page) { this.$store.dispatch('SearchResults/setSearchPage', { key: 'directory-searchResults', page }); }
        },

        totalResults: {
            get() { return this.$store.getters['SearchResults/searchTotalResults']('directory-searchResults'); },
            set(totalResults) { this.$store.dispatch('SearchResults/setSearchTotalResults', { key: 'directory-searchResults', totalResults }); }
        },

        // Nearby saved results - save whether chip is enabled in the searchFilter slot
        nearbySearchFilter: {
            get() { return this.$store.getters['SearchResults/searchFilter']('directory-nearbyResults'); },
            set(searchFilter) { this.$store.dispatch('SearchResults/setSearchFilter', { key: 'directory-nearbyResults', searchFilter }); }
        },

        nearbyResults: {
            get() { return this.$store.getters['SearchResults/searchResults']('directory-nearbyResults'); },
            set(searchResults) { this.$store.dispatch('SearchResults/setSearchResults', { key: 'directory-nearbyResults', searchResults }); }
        },

        nearbyPage: {
            get() { return this.$store.getters['SearchResults/searchPage']('directory-nearbyResults'); },
            set(page) { this.$store.dispatch('SearchResults/setSearchPage', { key: 'directory-nearbyResults', page }); }
        },

        nearbyTotalResults: {
            get() { return this.$store.getters['SearchResults/searchTotalResults']('directory-nearbyResults'); },
            set(totalResults) { this.$store.dispatch('SearchResults/setSearchTotalResults', { key: 'directory-nearbyResults', totalResults }); }
        },

        // Recent saved results - save whether chip is enabled in the searchFilter slot
        recentSearchFilter: {
            get() { return this.$store.getters['SearchResults/searchFilter']('directory-recentResults'); },
            set(searchFilter) { this.$store.dispatch('SearchResults/setSearchFilter', { key: 'directory-recentResults', searchFilter }); }
        },

        recentResults: {
            get() { return this.$store.getters['SearchResults/searchResults']('directory-recentResults'); },
            set(searchResults) { this.$store.dispatch('SearchResults/setSearchResults', { key: 'directory-recentResults', searchResults }); }
        },

        recentPage: {
            get() { return this.$store.getters['SearchResults/searchPage']('directory-recentResults'); },
            set(page) { this.$store.dispatch('SearchResults/setSearchPage', { key: 'directory-recentResults', page }); }
        },

        recentTotalResults: {
            get() { return this.$store.getters['SearchResults/searchTotalResults']('directory-recentResults'); },
            set(totalResults) { this.$store.dispatch('SearchResults/setSearchTotalResults', { key: 'directory-recentResults', totalResults }); }
        },

        isSearchResults() {
            return !this.loading && this.searchResults && this.searchResults.length;
        },

        noSearchResults() {
            return !this.loading && (!this.searchResults || !this.searchResults.length);
        },

        isNearbyResults() {
            return this.nearbyResults && this.nearbyResults.length;
        },

        isRecentResults() {
            return this.recentResults && this.recentResults.length;
        },

        numPages() {
            return !this.totalResults ? 0 : Math.ceil(this.totalResults / this.numPerPage);
        },

        numNearbyPages() {
            return !this.nearbyTotalResults ? 0 : Math.ceil(this.nearbyTotalResults / this.numPerPage);
        },

        numRecentPages() {
            return !this.recentTotalResults ? 0 : Math.ceil(this.recentTotalResults / this.numPerPage);
        },

        filteredNearbyResults() {
            // Cut the results list to a max size and add a search nearby option instead to view more
            if (!this.nearbySearchFilter && this.nearbyResults && this.nearbyResults.length > this.nearbyMax) {
                return this.nearbyResults.slice(0, this.nearbyMax - 1);  // -1 to allow room for the more items link
            } else {
                return this.nearbyResults;
            }
        },

        filteredRecentResults() {
            // Cut the results list to a max size and add a search recent option instead to view more
            if (!this.recentSearchFilter && this.recentResults && this.recentResults.length > this.recentMax) {
                return this.recentResults.slice(0, this.recentMax - 1);  // -1 to allow room for the more items link
            } else {
                return this.recentResults;
            }
        },

        hasMoreNearbyResults() {
            return this.nearbyTotalResults > this.nearbyMax && !this.nearbySearchFilter && !this.loadingNearby;
        },

        hasMoreRecentResults() {
            return this.recentTotalResults > this.recentMax && !this.recentSearchFilter && !this.loadingRecent;
        }
    },
        
    watch: {
        // whenever login state changes and we're no longer logged in, we redirect to login page
        isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
           if (!newIsLoggedIn && oldIsLoggedIn) {
                this.$router.push({ name: 'login' });
            }
        },

        // Issue new search on search string changing
        searchString(value, old) {
            // If the value has changed, we reset the pagenumbers
            if (value !== old) {
                this.page = 1;
                this.nearbyPage = 1;
                this.recentPage = 1;
            }

            // Nothing to search
            if (!value) return;

            // Reset any validation rules so we can do on submit
            this.searchRules =  [];
            this.getDebouncedSearchResults();
        },

        page(pageNum) {
            // Get the search results with the new page number
            this.getDebouncedSearchResults(pageNum);
        },

        nearbyPage(pageNum, oldPageNum) {
            // Get the nearby search results with the new page number
            if (oldPageNum > 0 && this.nearbySearchFilter) {
                this.getDebouncedNearbyResults(pageNum);
            }
        },

        recentPage(pageNum, oldPageNum) {
            // Get the recent search results with the new page number
            if (oldPageNum > 0 && this.recentSearchFilter) {
                this.getDebouncedRecentResults(pageNum);
            }
        },
    },

    // Get the default lists for recent members and people near me
    beforeMount() {
        // Set initial chip state
        this.nearbySearchFilter = false;
        this.recentSearchFilter = false;
        
        // Set loading indicators
        this.loadingNearby = true;
        this.loadingRecent = true;

        // Get the list of recent and local people
        DirectoryAPI.recentNearby(this.userid)
        .then( (response) => {
            // Check our expected data is in the response
            if (!response.recent || !response.nearby) {
                throw new Error('There was a problem, please try again later');
            }

            // Set our expected recent member results in scope
            this.recentResults = response.recent.items ? response.recent.items : [];
            this.recentTotalResults = response.recent.total ? response.recent.total : 0;
            if (this.recentTotalResults) {
                this.recentPage = response.recent.page ? response.recent.page : 1;
            } else {
                this.recentPage = 0;
            }
            
            // Set our expected nearby member results in scope
            this.nearbyResults = response.nearby.items ? response.nearby.items : [];
            this.nearbyTotalResults = response.nearby.total ? response.nearby.total : 0;
            if (this.nearbyTotalResults) {
                this.nearbyPage = response.nearby.page ? response.nearby.page : 1;
            } else {
                this.nearbyPage = 1;
            }
        })
        .catch( (error) => {
            // Clear the snackbar
            this.clearSnackBar();

            // Error message to show user
            let errorMessage = '';

            if (error instanceof NoResponseAPIError ) {
                errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
            } else if (error instanceof BadMethodAPIError) {
                errorMessage = 'We encountered a technical problem, please try again later';
            } else if (error instanceof BadRequestAPIError) {
                errorMessage = 'We encountered a problem, please try again later';
            } else if (error instanceof CredentialsRevokedAPIError) {
                this.errorMessage = 'You are not allowed to access this data, please contact us for support';
            } else if (error instanceof AuthenticationAPIError) {
                errorMessage = 'We encountered an authentication problem, please logout and try again';
            } else if (error instanceof InternalServerAPIError) {
                errorMessage = 'We encountered a server problem, please try again later';
            } else {
                errorMessage = 'There was a problem fetching data, please try again later';
            }

            // Show snackbar error message
            this.setSnackBar({ snack: errorMessage });
        }).finally(() => {
            this.loadingRecent = false;
            this.loadingNearby = false;
        });
    },

    // If we're not navigating to another directory page, we clear the searchResults
    beforeRouteLeave(to,from,next) {
        const validRouteNames = ['directoryHome', 'directoryMember'];
        if (!to.name || validRouteNames.indexOf(to.name) === -1) {
            this.$store.dispatch('SearchResults/clearSearch', { key: 'directory-searchResults' });
            this.$store.dispatch('SearchResults/clearSearch', { key: 'directory-nearbyResults' });
            this.$store.dispatch('SearchResults/clearSearch', { key: 'directory-recentResults' });
            this.$store.dispatch('SearchResults/clearSearch', { key: 'directory-member-valueResults' });
        }
        next();
    },
    
    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
        }),

        viewMember(fetchId) {
            this.$router.push( { name: 'directoryMember', params: { fetchId }});
        },
            
        clickMoreNearby() {
            if (!this.nearbySearchFilter) {
                this.nearbySearchFilter = true;
                this.recentSearchFilter = false;
            }
        },

        clickMoreRecent() {
            if (!this.recentSearchFilter) {
                this.recentSearchFilter = true;
                this.nearbySearchFilter = false;
            }
        },

        clearNearbyChip() {
            // Reset nearby filter
            this.nearbySearchFilter = false;

            // Reset nearby page & results back to first
            if (this.nearbyPage !== 1) {
                this.nearbyPage = 1;

                // Refresh the list to get latest results
                this.getDebouncedNearbyResults(1);
            }

            // Reset recent page & results back to first
            if (this.recentPage !== 1) {
                this.recentPage = 1;

                // Refresh the list to get latest results
                this.getDebouncedRecentNearbyResults(1);
            }

            // If we were searching with this chip as filter, reissue the search at page 1
            if (this.searchString) {
                // Reset original search results back to page 1
                if (this.page !== 1) {
                    this.page = 1;
                }
                
                // Refresh the search results
                this.getDebouncedSearchResults(1);
            }
        },

        clearRecentChip() {
            // Reset recent filter
            this.recentSearchFilter = false;

            // Reset recent page & results back to first
            if (this.recentPage !== 1) {
                this.recentPage = 1;

                // Refresh the list to get latest results
                this.getDebouncedRecentNearbyResults(1);
            }

            // Reset nearby page & results back to first
            if (this.nearbyPage !== 1) {
                this.nearbyPage = 1;

                // Refresh the list to get latest results
                this.getDebouncedNearbyResults(1);
            }

            // If we were searching with this chip as filter, reissue the search at page 1
            if (this.searchString) {
                // Reset original search results back to page 1
                if (this.page !== 1) {
                    this.page = 1;
                }
                
                // Refresh the search results
                this.getDebouncedSearchResults(1);
            }
        },

        clickNearbyChip() {
            // Only action if not already selected
            if (!this.nearbySearchFilter) {
                // Enable search filter
                this.nearbySearchFilter = true;

                // If we were searching, reissue the search at page 1 now with the new chip
                if (this.searchString) {
                    // Reset original search results back to page 1
                    if (this.page !== 1) {
                        this.page = 1;
                    }
                    
                    // Refresh the search results
                    this.getDebouncedSearchResults(1);
                }
            } else {
                // Turn off the filter
                this.clearNearbyChip();
            }
        },

        clickRecentChip() {
            // Only action if not already selected
            if (!this.recentSearchFilter) {
                // Enable search filter
                this.recentSearchFilter = true;

                // If we were searching, reissue the search at page 1 now with the new chip
                if (this.searchString) {
                    // Reset original search results back to page 1
                    if (this.page !== 1) {
                        this.page = 1;
                    }
                    
                    // Refresh the search results
                    this.getDebouncedSearchResults(1);
                }
            } else {
                // Turn off the filter
                this.clearRecentChip();
            }
        },

        // Delay the search function call by 500ms, to debounce API endpoint
        getDebouncedSearchResults(pageNum) {
            // If called as submit event, pageNum is the event object, so just reset to first page
            if (typeof pageNum !== 'number') {
                pageNum = 1;    
            }

            // Cancel any pending calls
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }

            // Delay the search call 500ms
            this.loading = true;                    // do now so don't show no search results header prematurely
            this.debounceTimer = setTimeout(()=>this.getSearchResults(pageNum), 500);
        },

        // Delay the nearby search function call by 500ms, to debounce API endpoint
        getDebouncedNearbyResults(pageNum) {
            // If called as submit event, pageNum is the event object, so just reset to first page
            if (typeof pageNum !== 'number') {
                pageNum = 1;    
            }
            
            // Cancel any pending calls
            if (this.debounceNearbyTimer) {
                clearTimeout(this.debounceNearbyTimer);
            }

            // Delay the search call 500ms
            this.loadingNearby = true;                    // do now so don't show no search results header prematurely
            this.debounceNearbyTimer = setTimeout(()=>this.getNearbyResults(pageNum), 500);
        },

        // Delay the recent search function call by 500ms, to debounce API endpoint
        getDebouncedRecentResults(pageNum) {
            // If called as submit event, pageNum is the event object, so just reset to first page
            if (typeof pageNum !== 'number') {
                pageNum = 1;    
            }
            
            // Cancel any pending calls
            if (this.debounceRecentTimer) {
                clearTimeout(this.debounceRecentTimer);
            }

            // Delay the search call 500ms
            this.loadingRecent = true;                    // do now so don't show no search results header prematurely
            this.debounceRecentTimer = setTimeout(()=>this.getRecentResults(pageNum), 500);
        },

        // Get search results from the API endpoint
        getSearchResults(pageNum) {
            // Empty value, reset any pending search
            if (!this.searchString) {
                this.searchResults = [];
            }

            // Add a validate handler here so only validates on submit, not whilst typing
            this.searchRules = [
                (v) => {
                    if (!v) {
                        return true;
                    } else {
                        return v.trim().length && v.trim().length >= 4 || 'Please enter at least 4 characters';
                    }
                }
            ];
            
            // Validate on next tick so rules have change to be set
            this.$nextTick(() => {
                if (this.$refs.search && this.$refs.search.validate() && this.searchString){
                    // Request the first page unless another page number is set
                    let page = pageNum;
                    if (!pageNum) {
                        page = this.page ? this.page : 1;
                    }

                    // Pass extra parameter if we're only searching those nearby only
                    const nearby = this.nearbySearchFilter ? true : false;

                    // Pass extra parameter if we're searching recent users
                    const recent = this.recentSearchFilter ? true : false;

                    // Search via the API endpoint
                    DirectoryAPI.search(this.userid, this.searchString, page, nearby, recent)
                    .then( (response) => {
                        // Check our expected data is in the response
                        if (!response.results) {
                            throw new Error('There was a problem, please try again later');
                        }

                        // Set our expected results in scope
                        this.searchResults = response.results.items ? response.results.items : [];
                        this.totalResults = response.results.total ? response.results.total : 0;
                        if (this.totalResults) {
                            this.page = response.results.page ? response.results.page : 1;
                        } else {
                            this.page = 0;
                        }
                    })
                    .catch( (error) => {
                        // Clear the snackbar
                        this.clearSnackBar();

                        // Error message to show user
                        let errorMessage = '';

                        if (error instanceof NoResponseAPIError ) {
                            errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                        } else if (error instanceof BadMethodAPIError) {
                            errorMessage = 'We encountered a technical problem, please try again later';
                        } else if (error instanceof BadRequestAPIError) {
                            errorMessage = 'We encountered a problem, please try again later';
                        } else if (error instanceof CredentialsRevokedAPIError) {
                            this.errorMessage = 'You are not allowed to access this data, please contact us for support';
                        } else if (error instanceof AuthenticationAPIError) {
                            errorMessage = 'We encountered an authentication problem, please logout and try again';
                        } else if (error instanceof InternalServerAPIError) {
                            errorMessage = 'We encountered a server problem, please try again later';
                        } else {
                            errorMessage = 'There was a problem, please try again later';
                        }

                        // Show snackbar error message
                        this.setSnackBar({ snack: errorMessage });
                    }).finally(() => this.loading = false);
                } else {
                    this.loading = false;
                }
            });
        },

        // Get search results from the API endpoint
        getNearbyResults(pageNum) {
            // Request the first page unless another page number is set
            let page = pageNum;
            if (!pageNum) {
                page = this.nearbyPage ? this.nearbyPage : 1;
            }

            // Search via the API endpoint
            DirectoryAPI.nearby(this.userid, page)
            .then( (response) => {
                // Check our expected data is in the response
                if (!response.nearby) {
                    throw new Error('There was a problem, please try again later');
                }

                // Set our expected nearby member results in scope
                this.nearbyResults = response.nearby.items ? response.nearby.items : [];
                this.nearbyTotalResults = response.nearby.total ? response.nearby.total : 0;
                if (this.nearbyTotalResults) {
                    this.nearbyPage = response.nearby.page ? response.nearby.page : 1;
                } else {
                    this.nearbyPage = 0;
                }
            })
            .catch( (error) => {
                // Clear the snackbar
                this.clearSnackBar();

                // Error message to show user
                let errorMessage = '';

                if (error instanceof NoResponseAPIError ) {
                    errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                } else if (error instanceof BadMethodAPIError) {
                    errorMessage = 'We encountered a technical problem, please try again later';
                } else if (error instanceof BadRequestAPIError) {
                    errorMessage = 'We encountered a problem, please try again later';
                } else if (error instanceof CredentialsRevokedAPIError) {
                    this.errorMessage = 'You are not allowed to access this data, please contact us for support';
                } else if (error instanceof AuthenticationAPIError) {
                    errorMessage = 'We encountered an authentication problem, please logout and try again';
                } else if (error instanceof InternalServerAPIError) {
                    errorMessage = 'We encountered a server problem, please try again later';
                } else {
                    errorMessage = 'There was a problem, please try again later';
                }

                // Show snackbar error message
                this.setSnackBar({ snack: errorMessage });
            }).finally(() => this.loadingNearby = false);
        },

        // Get search results from the API endpoint
        getRecentResults(pageNum) {
            // Request the first page unless another page number is set
            let page = pageNum;
            if (!pageNum) {
                page = this.recentPage ? this.recentPage : 1;
            }

            // Search via the API endpoint
            DirectoryAPI.recent(this.userid, page)
            .then( (response) => {
                // Check our expected data is in the response
                if (!response.recent) {
                    throw new Error('There was a problem, please try again later');
                }

                // Set our expected nearby member results in scope
                this.recentResults = response.recent.items ? response.recent.items : [];
                this.recentTotalResults = response.recent.total ? response.recent.total : 0;
                if (this.recentTotalResults) {
                    this.recentPage = response.recent.page ? response.recent.page : 1;
                } else {
                    this.recentPage = 0;
                }
            })
            .catch( (error) => {
                // Clear the snackbar
                this.clearSnackBar();

                // Error message to show user
                let errorMessage = '';

                if (error instanceof NoResponseAPIError ) {
                    errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                } else if (error instanceof BadMethodAPIError) {
                    errorMessage = 'We encountered a technical problem, please try again later';
                } else if (error instanceof BadRequestAPIError) {
                    errorMessage = 'We encountered a problem, please try again later';
                } else if (error instanceof CredentialsRevokedAPIError) {
                    this.errorMessage = 'You are not allowed to access this data, please contact us for support';
                } else if (error instanceof AuthenticationAPIError) {
                    errorMessage = 'We encountered an authentication problem, please logout and try again';
                } else if (error instanceof InternalServerAPIError) {
                    errorMessage = 'We encountered a server problem, please try again later';
                } else {
                    errorMessage = 'There was a problem, please try again later';
                }

                // Show snackbar error message
                this.setSnackBar({ snack: errorMessage });
            }).finally(() => this.loadingRecent = false);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>

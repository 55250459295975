import { render, staticRenderFns } from "./CountrySelect.vue?vue&type=template&id=0f082838&scoped=true&"
import script from "./CountrySelect.vue?vue&type=script&lang=js&"
export * from "./CountrySelect.vue?vue&type=script&lang=js&"
import style0 from "./CountrySelect.vue?vue&type=style&index=0&id=0f082838&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f082838",
  null
  
)

/* custom blocks */
import block0 from "./CountrySelect.vue?vue&type=custom&index=0&blockType=CountrySelect&v-model=country&placeholder=Choose%20your%20country"
if (typeof block0 === 'function') block0(component)
import block1 from "./CountrySelect.vue?vue&type=custom&index=1&blockType=RegionSelect&v-model=region&%3Acountry=country.countryName%20%7C%7C%20''&placeholder=Choose%20your%20region"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})

/**
 * Front-end view controller for the how it works page
 *
 * Render's the page template, and processes any UI functions and interactivity
 *
 * Note:
 *
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the how it works page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 *
 */
<template>
    <v-container>
        <!-- Navigation -->
        <HomeNavigation />
        
        <v-row align="start" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col>
                <!-- Title -->
                <h1>How It Works</h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Import our custom components
import HomeNavigation from '@/components/HomeNavigation';

// The how it works page component
export default {
    name: 'HomeHowItWorks',

    components: {
        HomeNavigation,
    },
    
    data() {
        return {
        };
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>

/**
 * Front-end view controller for the value logged page
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * Note:
 * 
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the value logged page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container v-if="isLoggedIn">
        <!-- Title and blurb -->
        <v-row align="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col>
                <h1>Thank you for contributing to the community!</h1>
                <p class="mt-5">Awesome, thanks so much for adding value to {{firstName | capitalize}}, and to the community as a whole.</p>
                <p>The more we all give back, the stronger we collectively are so thank you for contributing!</p>

                <p class="my-10" id="emailicon">
                    🙌 
                </p>
                
                <p class="mb-0 font-weight-bold">We've asked {{firstName | capitalize}} to confirm the value and to provide some feedback.</p>
            </v-col>
        </v-row>

        <v-row align="center" justify="center">
            <v-col cols="12" md="9">
                <v-banner outlined rounded style="text-align: left" class="pb-1 text-body-2">
                    <v-avatar slot="icon" color="" size="80">
                        <v-icon icon="mdi-information-outline" color="primary accent-4">mdi-information</v-icon>
                    </v-avatar>
                    <div class="text-wrap">To help us realise the actual value we deliver vs. our perceived value, we encourage all recipients to provide psychologically safe feedback.  This means being genuine and honest, whilst providing specific and actionable feedback in a way that they'd like to receive themselves. <a href="https://contribute.positivesum.community/adding-value-to-the-community/capturing-feedback" target="_blank">Read more</a> about our community feedback guidelines</div>
                </v-banner>

                <p class="mt-5">Once {{firstName | capitalize}} has submitted feedback, you will be notified by email of any rewards and reputation awarded - hang tight!</p>
            </v-col>
        </v-row>

        <!-- Submit reminder & button -->
        <v-row align="center" justify="space-around">
            <v-col cols="12" md="9">
                <v-btn :to="{ name: 'valueHome' }">Done</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters } from 'vuex';

// Setup our confetti module
import Vue from 'vue';
import VueConfetti from 'vue-confetti';
Vue.use(VueConfetti);

// The main admin home page component
export default {
    name: 'ValueLogged',

    data() {
        return {
        };
    },

    // Incoming properties for this component
    props: {
        firstName: { type: String, default: null },
    },

    computed: {
        // Map our Vuex getters
        ...mapGetters({
            isLoggedIn: 'Auth/isLoggedIn',
        }),
    },

    filters: {
        capitalize: (value) => {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },

    watch: {
        // whenever login state changes and we're no longer logged in, we redirect to login page
        isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
            if (!newIsLoggedIn && oldIsLoggedIn) {
                this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
            }
        },
    },

    // If we're not navigating to another exchange page, we clear the searchResults
    beforeRouteLeave(to,from,next) {
        const validRouteNames = ['valueHome', 'valueAddPerson', 'valueLogValue', 'valueLogged', 'valueConfirm', 'valueConfirmed', 'valueDisputed', 'valueView'];
        if (!to.name || validRouteNames.indexOf(to.name) === -1) {
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-searchResults' });
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-recentResults' });
        }
        next();
    },

    mounted() {
        // Show the confetti effect
        this.$confetti.start({
          defaultType: 'heart',
          defaultSize: 20,
          defaultDropRate: 20,
        });
        setTimeout(() => this.$confetti.stop(), 2500);
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
#emailicon {
    font-size: 160px;
}
</style>

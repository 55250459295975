/**
 * API Middleware for User-related API functions
 *
 * Contains transport-level functions to make API calls and process the responses,
 * Setting any relevant data in the Vuex stores, then passing back data back up to the UI for rendering
 * 
 * @file   API middleware for user-related functions
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Common includes used in this file
import axios from '@/common/axios';
import store from '@/store/index';
import { MD5 } from 'crypto-es/lib/md5.js';

// Include the Auth API functions for register pre-auth
import jwtDecode from 'jwt-decode';
import AuthAPI from '@/services/AuthAPIService.js';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import AlreadyActionedAPIError from '@/errors/alreadyactionedapierror';
import InvalidTokenAPIError from '@/errors/invalidtokenapierror';
import ExpiredTokenAPIError from '@/errors/expiredtokenapierror';
import NotExistsAPIError from '@/errors/notexistsapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';

/*
 * function register ()
 *
 * API call to register a new user's details
 *
 */
const register = async (credentials) => {
    // Registration happens in two steps - first we request make a pre-auth request which returns a token
    // We then submit to the user registration API and pass the registration data and the token
    let token = '';
    let signature = '';
    try {
        const response = await AuthAPI.register();

        // If the server was unreachable or timedout, the request is cancelled and goes into the then handler - trap this as a NoResponseAPIError
        if (!response || !response.message) {
            throw new NoResponseAPIError();
        }

        // Extract the token from the response
        if (!response.token || !response.token.length) {
            throw new BadRequestAPIError(response);
        } else {
            // Set the token details
            token = response.token;

            // We decode the token to extract the key used in the data - this is forms part of the signature
            if (credentials && credentials.firstName && credentials.lastName && credentials.email && credentials.password) {
        
                // Make sure the token is still valid and not expired
                const decoded = jwtDecode(token);
                const isValid = AuthAPI.validateToken(decoded);
                const isExpired = AuthAPI.validateTokenExpiry(decoded);

                // Extract the data from the token to get the registerKey) and sign the payload with this key
                if (isValid && !isExpired && decoded.data && decoded.data && decoded.data.registerKey) {
                    // Generate a signed API request using our shared secret key
                    const signatureStr = 'user/register' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + credentials.firstName + credentials.lastName + credentials.email + credentials.password + decoded.data.registerKey;
                    signature = MD5(signatureStr).toString();
                }
            }
        }
    } catch (error) {
        if (error instanceof NoResponseAPIError ) {
            throw new NoResponseAPIError(error.response, error.request. error);
        } else if (error instanceof UnsupportedMediaAPIError) {
            throw new UnsupportedMediaAPIError(error.response, error.request. error);
        } else if (error instanceof BadMethodAPIError) {
            throw new BadMethodAPIError(error.response, error.request. error);
        } else if (error instanceof BadRequestAPIError) {
            throw new BadRequestAPIError(error.response, error.request. error);
        } else if (error instanceof InternalServerAPIError) {
            throw new InternalServerAPIError(error.response, error.request. error);
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }           
    }

    // Now submit the data and token to the User API registration endpoint
    if (token.length) {
        // Add the token to the credentials to act as a signature
        let submitData = {...credentials, token, signature };

        // Post the signed request to the register endpoint
        return axios.post('user/register', submitData)
        .then(response => response.data)
        .catch((error) => {
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                    throw new BadMethodAPIError(error.response, error.request. error);
                } else if (error.response.status === 415) {
                    throw new UnsupportedMediaAPIError(error.response, error.request. error);
                } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                    throw new BadRequestAPIError(error.response, error.request. error);
                } else if (error.response.status === 401 && error.response.data.reason === 'ALREADY_ACTIONED') {
                    throw new AlreadyActionedAPIError(error.response, error.request. error);
                } else if (error.response.status === 401 && error.response.data.reason === 'TOKEN_EXPIRED') {
                    throw new ExpiredTokenAPIError(error.response, error.request. error);
                } else if (error.response.status === 401 && error.response.data.reason === 'NOT_EXISTS') {
                    throw new NotExistsAPIError(error.response, error.request. error);
                } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                    throw new InternalServerAPIError(error.response, error.request, error);
                } else {
                    throw new Error(error);    
                }   
            } else if (error.request) {
                // The request was made but no response was received
                throw new NoResponseAPIError(error.request, error);
            } else {
                // Something happened in setting up the request that triggered an Error
                throw new Error(error);
            }
        });
    }
}

/*
 * function getUser ()
 *
 * API call to get details about the currently logged in user
 *
 */
const getUser = async (userid) => {
    // Generate a signed API request using our shared secret key
    const signatureStr = 'user/get' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userid;
    const signature = MD5(signatureStr).toString();

    // Post to the user endpoint
    return axios.post('user/get', {
        userid,
        signature,
    }).then(response => {
        // Update the newly fetched user data store
        const user = response.data.user;
        store.dispatch('User/SET_USER', { user });

        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function confirmEmail ()
 *
 * API call to verify a user's email address as part of the register process
 *
 */
const confirmEmail = async (credentials) => {
    // Generate a signed API request using our shared secret key
    if (credentials && credentials.email && credentials.code) {
        const signatureStr = 'user/confirmEmail' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + credentials.email + credentials.code;
        credentials.signature = MD5(signatureStr).toString();
    } else {
        return false;
    }

    // Post to the confirm email endpoint
    return axios.post('user/confirmEmail', credentials)
    .then(response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'INVALID_TOKEN') {
                throw new InvalidTokenAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'ALREADY_ACTIONED') {
                throw new AlreadyActionedAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'NOT_EXISTS') {
                throw new NotExistsAPIError(error.response, error.request, error);
            } else if (error.response.status === 401 && error.response.data.reason === 'TOKEN_EXPIRED') {
                throw new ExpiredTokenAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function editProfile()
 *
 * API call to edit a user's profile.  Note will send multipart form data since there may be images uploaded along with form fields
 *
 */
const editProfile = async (userId, rememberMe, fields, formData) => {
    // Check our fields are specified
    if (!fields.countryCode || !fields.regionCode) {
        return false;
    }

    // Generate a signed API request using our shared secret key
    if (formData && userId) {
        const signatureStr = 'user/editProfile' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userId + fields.countryCode + fields.regionCode;
        formData.append('signature', MD5(signatureStr).toString());
    } else {
        return false;
    }

    // Post to the editProfile endpoint
    return axios.post('user/editProfile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(async response => {
        // Set valid tokens from the response
        const tokens = {
            accessToken: response.data.authTokens.accessToken,
            refreshToken: response.data.authTokens.refreshToken,
            email: response.data.user.email,
        };
        const user = response.data.user;

        // Set the new access tokens in scope
        await store.dispatch('Auth/Login', {
            tokens,
            user,
            rememberMe: rememberMe,
        });

        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function getSkills ()
 *
 * API call to get details about the currently logged in user's skills
 *
 */
const getSkills = async (userid) => {
    // Generate a signed API request using our shared secret key
    const signatureStr = 'user/getSkills' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userid;
    const signature = MD5(signatureStr).toString();

    // Post to the user endpoint
    return axios.post('user/getSkills', {
        userid,
        signature,
    }).then(response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function unsubscribe()
 *
 * API call to unsubscribe an email address
 *
 */
const unsubscribe = async (credentials) => {
    // Generate a signed API request using our shared secret key
    if (credentials && credentials.email) {
        const signatureStr = 'user/unsubscribe' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + credentials.email;
        credentials.signature = MD5(signatureStr).toString();
    } else {
        return false;
    }

    // Post to the confirm email endpoint
    return axios.post('user/unsubscribe', credentials)
    .then(response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

// Export each API endpoint
export default {
    register,
    getUser,
    confirmEmail,
    editProfile,
    getSkills,
    unsubscribe,
};
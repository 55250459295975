/**
 * XML-RPC library
 *
 * Creates a singleton instance for the axios xml-rpc library
 * 
 * @file   XML-RPC library
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Common imports
import axios from 'axios';

// Create new global instance of the xml-http request object
const instance = axios.create({
  // Setup the base url for our API calls
  baseURL: `${process.env.VUE_APP_BASE_URL_API}`,
  
  // Uncomment this line to simulate a no repsonse error from the API
  //timeout: 10,

  // Ensure axios sends authentication credentials with requests
  withCredentials: true,
});

export default instance;

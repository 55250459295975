/**
 * Front-end view controller for the user view profile page/dashboard
 *
 * Render's the page template, and processes any UI functions and interactivity
 *
 * Note:
 *
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the user view profile page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 *
 */
<template>
    <v-container v-if="isLoggedIn">
        <v-row align="start" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col>
                <!-- Title -->
                <h1>User Profile</h1>

                <!-- Edit Profile Link -->
                <p><router-link :to="{ name: 'userEditProfile' }">Edit Profile</router-link></p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters } from 'vuex';

// The main user home page component
export default {
    name: 'UserProfile',

    data() {
        return {
        };
    },

    computed: {
        // Map our Vuex getters
        ...mapGetters({
            isLoggedIn: 'Auth/isLoggedIn',
            isAdmin: 'Auth/isAdmin',
        }),
    },

    watch: {
        // whenever login state changes and we're no longer logged in, we redirect to login page
        isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
            if (!newIsLoggedIn && oldIsLoggedIn) {
                this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
            }
        }
    },

    beforeMount() {
        // Check login state or redirect
        if (!this.isLoggedIn) {
            this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>

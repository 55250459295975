/**
 * Vuex storage for application-wide search results storage
 *
 * Corresponding getters, mutators, and actions for modifying this local data store
 * 
 * 
 * Note:
 *      To avoid hitting the server when navigating between related pages (e.g. search results > view individual results > back to search results)
 *      We save search results in a store.
 * 
 *      We setup our state as a dictionary, allowing us to save multiple search results indexed by key
 * 
 *      state =
 *      {
 *          'directory-search': {
 *              searchString: "search",
 *              searchResults: [{...}],
 *              page: 1,                // optional
 *              totalResults: 42,       // optional
 *              searchFilter: false,    // optional
 *          }
 *          'directory-recent': {
 *              searchString: "search",
 *              searchResults: [{...}],
 *              page: 1,                // optional
 *              totalResults: 42,       // optional
 *              searchFilter: false,    // optional
 *          }
 *          ...
 *      }
 * 
 * @file   Vuex data store for snackbar data
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Includes used in this module
import Vue from "vue";

// Setup the default state
const getDefaultState = () => {
    return {
    };
};

// Setup the store mutations
const mutations = {
    SET_SEARCH: (state, { key, searchString, searchResults, page, totalResults, searchFilter }) => {
        // Create the key if it doesn't exist
        if (!Object.prototype.hasOwnProperty.call(state, key)) {
            Vue.set(state, key, {});
        }

        // Save the details if they're set
        Vue.set(state[key], 'searchString', searchString);
        Vue.set(state[key], 'searchResults', searchResults);
        Vue.set(state[key], 'page', page);
        Vue.set(state[key], 'totalResults', totalResults);
        Vue.set(state[key], 'searchFilter', searchFilter);
    },

    SET_SEARCH_STRING: (state, { key, searchString }) => {
        // Create the key if it doesn't exist
        if (!Object.prototype.hasOwnProperty.call(state, key)) {
            Vue.set(state, key, {});
        }

        // Save the details if it's set
        Vue.set(state[key], 'searchString', searchString);
    },

    SET_SEARCH_RESULTS: (state, { key, searchResults }) => {
        // Create the key if it doesn't exist
        if (!Object.prototype.hasOwnProperty.call(state, key)) {
            Vue.set(state, key, {});
        }

        // Save the details if it's set
        Vue.set(state[key], 'searchResults', searchResults);
    },

    SET_SEARCH_PAGE: (state, { key, page }) => {
        // Create the key if it doesn't exist
        if (!Object.prototype.hasOwnProperty.call(state, key)) {
            Vue.set(state, key, {});
        }

        // Save the details if it's set
        Vue.set(state[key], 'page', page);
    },

    SET_SEARCH_TOTAL_RESULTS: (state, { key, totalResults }) => {
        // Create the key if it doesn't exist
        if (!Object.prototype.hasOwnProperty.call(state, key)) {
            Vue.set(state, key, {});
        }

        // Save the details if it's set
        Vue.set(state[key], 'totalResults', totalResults);
    },

    SET_SEARCH_FILTER: (state, { key, searchFilter }) => {
        // Create the key if it doesn't exist
        if (!Object.prototype.hasOwnProperty.call(state, key)) {
            Vue.set(state, key, {});
        }

        // Save the details if it's set
        Vue.set(state[key], 'searchFilter', searchFilter);
    },

    RESET: (state, { key }) => {
        // Reset the state for a specific key
        if (Object.prototype.hasOwnProperty.call(state, key)) {
            Vue.set(state[key], 'searchString', '');
            Vue.set(state[key], 'searchResults', []);
            Vue.set(state[key], 'page', 0);
            Vue.set(state[key], 'totalResults', 0);
            Vue.set(state[key], 'searchFilter', false);
        }
    },
};

// Setup our getters
const getters = {
    // Get search string for a specific key if it is set
    searchString(state) {
        return (key) => {
            // Check the key exists
            if (!Object.prototype.hasOwnProperty.call(state, key)) {
                return null;
            } else {
                return state[key].searchString;
            }
        }
    },

    // Get search results for a specific key if it is set
    searchResults(state) {
        return (key) => {
            // Check the key exists
            if (!Object.prototype.hasOwnProperty.call(state, key)) {
                return null;
            } else {
                return state[key].searchResults;
            }
        }
    },

    // Get search page for a specific key if it is set
    searchPage(state) {
        return (key) => {
            // Check the key exists
            if (!Object.prototype.hasOwnProperty.call(state, key)) {
                return 0;
            } else {
                return state[key].page;
            }
        }
    },

    // Get search total for a specific key if it is set
    searchTotalResults(state) {
        return (key) => {
            // Check the key exists
            if (!Object.prototype.hasOwnProperty.call(state, key)) {
                return 0;
            } else {
                return state[key].totalResults;
            }
        }
    },

    // Get search filter for a specific key if it is set
    searchFilter(state) {
        return (key) => {
            // Check the key exists
            if (!Object.prototype.hasOwnProperty.call(state, key)) {
                return null;
            } else {
                return state[key].searchFilter;
            }
        }
    },
};


// Setup our actions
const actions = {
    /*
     * function setSearch()
     *
     * set a new combined search results
     */
    setSearch: ({ commit }, results) => {
        if (results.key) {
            commit('SET_SEARCH', results);
        }
    },

    /*
     * function setSearchString()
     *
     * set search string for a specified key
     */
    setSearchString: ({ commit }, data) => {
        if (data.key) {
            commit('SET_SEARCH_STRING', data);
        }
    },

    /*
     * function setSearchResults()
     *
     * set search results for a specified key
     */
    setSearchResults: ({ commit }, data) => {
        if (data.key) {
            commit('SET_SEARCH_RESULTS', data);
        }
    },

    /*
     * function setSearchPage()
     *
     * set search page for a specified key
     */
    setSearchPage: ({ commit }, data) => {
        if (data.key) {
            commit('SET_SEARCH_PAGE', data);
        }
    },

    /*
     * function setSearchTotalResults()
     *
     * set search total for a specified key
     */
    setSearchTotalResults: ({ commit }, data) => {
        if (data.key) {
            commit('SET_SEARCH_TOTAL_RESULTS', data);
        }
    },

    /*
     * function setSearchPage()
     *
     * set search page for a specified key
     */
    setSearchFilter: ({ commit }, data) => {
        if (data.key) {
            commit('SET_SEARCH_FILTER', data);
        }
    },

    /*
     * function clearSearch()
     *
     * Clears the data for a specified key
     */
    clearSearch: ({ commit }, results) => {
        if (results.key) {
            commit('RESET', { key: results.key } );
        }
    },
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations,
    getters,
    actions,
};
/**
 * Front-end view controller for the value exchange log value function
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * Note:
 * 
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the admin home page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container v-if="isLoggedIn">
        <v-form ref="form" v-model="valid" @submit.prevent="submit" autocomplete="off">
            <v-row align="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
                <v-col cols="12" md="9" align="left">
                    <!-- Back Link -->
                    <a class="text-left" @click="navigateBack">&lt; Back</a>
                </v-col>
            </v-row>
            
            <!-- Add the profile boxes row -->
            <v-row align="center" justify="space-around" v-if="isLoggedIn && user">
                
                <!-- You -->
                <v-spacer />
                <v-col cols="12" md="3" lg="2" v-if="userid">
                    <ProfileBox
                        :id='userid'
                        :firstname='firstname'
                        :lastname='lastname'
                        :profileImgSrc='profileImg && profileImg.small ? profileImg.small : ""'
                        :countryStr='countryName'
                        :countryCode='countryCode'
                        :regionStr='regionName'
                        level=""
                        :clickable="false"
                        />
                </v-col>
                
                <!-- Value Delivered Arrows -->
                <v-col cols="12" md="2" style="margin-top: -25px;">
                    <span class="value_arrow">⟶</span>
                    <br />
                    <span class="text-center">{{type}} capital</span>
                </v-col>
                
                <!-- The person you're adding value to -->
                <v-col cols="12" md="3" lg="2">
                    <ProfileBox
                        :id='user.id'
                        :firstname='user.firstname'
                        :lastname='user.lastname'
                        :profileImgSrc='user.profileImg'
                        :countryStr='user.countryName'
                        :countryCode='user.countryCode'
                        :regionStr='user.regionName'
                        level=""
                        :selectable="true"
                        :clickable="false"
                        :selected="user && user.id === user.id"
                        @deselect="deselect" />
                </v-col>
                <v-spacer />
            </v-row>

            <!-- Title and blurb -->
            <v-row align="center" v-if="isLoggedIn" :class="{ 'mt-3': $vuetify.breakpoint.smAndDown, 'mt-15': !$vuetify.breakpoint.smAndDown}">
                <v-col>
                    <h1>{{heading}}</h1>
                    <p class="mt-5">{{description}}</p>
                </v-col>
            </v-row>

            <!-- Slider -->
            <v-row align="center" justify="space-around" v-if="isLoggedIn && user">
                <v-col cols="11" md="9" :class="{ 'mt-0': $vuetify.breakpoint.smAndDown, 'mt-5': !$vuetify.breakpoint.smAndDown}">
                    <v-slider v-model="value" :thumb-size="55" thumb-label="always" class="mt-15 slider" hint="How much value do you feel you added?" max="5" min="1" persistent-hint thumb-color="blue-grey" track-color="blue-grey lighten-3" color="blue-grey" ticks="always" tick-size="4" :tick-labels="ticksLabels">
                        <template v-slot:thumb-label="{ value }">
                            {{ valueScaleLabels[value-1] }}
                        </template>
                    </v-slider>
                </v-col>
                <v-col cols="11" md="9">
                    <v-card>
                        <v-card-text class="pb-1">{{example}}</v-card-text>
                        <v-card-subtitle class="pt-1">{{equivalent}}</v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Tag the value -->
            <v-row align="center" justify="space-around" v-if="isLoggedIn && user">
                <v-col cols="12" md="9" :class="{ 'mt-3': $vuetify.breakpoint.smAndDown, 'mt-15': !$vuetify.breakpoint.smAndDown}">
                    <v-combobox
                        v-model="selectedTags"
                        :filter="filterTags"
                        :hide-no-data="!search"
                        :items="filteredTags"
                        :search-input.sync="search"
                        :rules="skillTagsRules"
                        hide-selected
                        :label="tagsLabel"
                        multiple
                        small-chips
                        persistent-hint
                        filled
                        counter=10
                        class="mb-8 v-tagcombobox"
                        :hint="tagsHint"
                    >
                        <!-- Allow user to create new tag when no matching selection -->
                        <template v-slot:no-data>
                            <v-list-item>
                                <span class="subheading mr-2">Create</span>
                                <v-chip color="green lighten-3" label small>{{ search }}</v-chip>
                            </v-list-item>
                        </template>

                        <!-- Render selected tags as deletable chips to deselect an item -->
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip v-if="item === Object(item)" v-bind="attrs" :color="item.type === 'user' ? 'green lighten-3' : 'blue-grey lighten-3'" :input-value="selected" label small>
                                <span class="pr-2">{{ item.text }}</span>
                                <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                            </v-chip>
                        </template>

                        <!-- show an item in the list - if we're editing, show text-field, or show a delete and edit option for user-specific tags -->
                        <template v-slot:item="{ index, item }">
                            <v-text-field v-if="editing === item" v-model="editing.text" autofocus flat dense background-color="transparent" hide-details solo @keyup.enter="editTag(index, item)"></v-text-field>
                            <v-chip :color="item.type === 'user' ? 'green lighten-3' : 'blue-grey lighten-3'" label small>{{ item.text }}</v-chip>

                            <v-spacer></v-spacer>

                            <!-- delete button for custom tags if not editing -->
                            <v-list-item-action @click.stop v-if="item.type === 'user'">
                                <v-btn icon @click.stop.prevent="deleteTag(item)" v-if="!editing">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-list-item-action>

                            <!-- edit or save button if a custom tag -->
                            <v-list-item-action @click.stop v-if="item.type === 'user'">
                                <v-btn icon @click.stop.prevent="editTag(index, item)">
                                    <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </template>
                    </v-combobox>
                </v-col>
            </v-row>

            <!-- Private message -->
            <v-row align="center" justify="space-around" v-if="isLoggedIn && user">
                <v-col cols="12" md="9">
                    <v-textarea v-model="message" filled counter=1000 :auto-grow="isTextAreaSelected('message')" rows="1" row-height="20" :rules="messageRules" :label="`You may include an optional private note for ${capitalize(user.firstname)}`" @focus="selectTextArea('message')" @blur="deselectTextArea"></v-textarea>
                </v-col>
            </v-row>

            <!-- Submit reminder & button -->
            <v-row align="center" justify="space-around" v-if="isLoggedIn && user" class="mb-15">
                <p v-if="user" class="mt-10">Note: {{capitalize(user.firstname)}} will be asked to confirm the value exchange before the transaction is complete.</p>
                <v-col cols="12" md="9">
                    <!-- Submit Button -->
                    <v-btn type="submit" v-if="isLoggedIn && user" :loading="pending" :disabled="!user">Log Value</v-btn>
                </v-col>
            </v-row>

            <!-- The confirm dialog -->
            <ConfirmDialog ref="confirm" />
        </v-form>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters, mapActions } from 'vuex';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';

// API Connectors
import ValueExchangeAPIService from '@/services/ValueExchangeAPIService.js';

// Import our confirm dialog component
import ConfirmDialog from '@/components/ConfirmDialog';

// Shared components used in this page
import ProfileBox from '@/components/ProfileBox';

// The main admin home page component
export default {
    name: 'LogValue',

    data() {
        return {
            // form validation
            valid: false,
            pending: false,

            // Value scale
            value: 1,           // initial value on sliding scale = some value
            valueScaleLabels: ['Some value', 'Certain value', 'Solid value', 'Great value', 'Massive value'],
            ticksLabels: ['Some', 'Certain', 'Solid', 'Great', 'Massive'],
            exampleValue:{
                'network': [
                    'E.g. introduction to someone who could be found or relatively easily with research; low acceleration factor (e.g. intro to service provider, cold intro, or recommendation to lookup someone yourself)',
                    'E.g. introduction to someone who is relatively accessible, but who might require a warm introduction to get their attention (e.g. a busy executive, a founder, an expert, or investor)',
                    'E.g. introduction that would moderately accelerate their progress (e.g. an employee referral, well-qualified medium sized sales prospect, or someone who would have taken them some time to find themselves)',
                    'E.g. introduction to a significant key resource for them or their business (e.g. a key team member, a well-qualified sales prospect ready to buy, investor connections who are well aligned and in your domain)',
                    'E.g. introduction to a person or network that creates huge value that the person couldn\'t obtain themselves without a lot of work (e.g. a lead investor, a co-founder, a key large customer prospect, access to an exclusive network, etc)'
                ],
                'mentorship': [
                    'E.g. a shorter 1:1 unstructured session (e.g. no prep, and no follow-up/actions)',
                    'E.g. a meaningful 1:1 structured session with solid outcomes and follow ups',
                    'E.g. a longer 1:1 workshop-type session, providing more interactive/structured mentorship',
                    'E.g. a deep-dive type workshop/session, maybe for many team members and requiring more preparation/planning',
                    'E.g. delivery of a much more highly structured/longer programme or series delivering unique insights and world-class mentorship'
                ],
                'intellectual': [
                    'E.g. creation or consumption of your shorter form content sharing good overview of a topic or area (e.g. writing a short overview article or brief video content with low production quality)',
                    'E.g. creation or consumption of your medium-length content that requires some prep work or deeper/more structured thinking (e.g. a medium length article or video adding layers of insights over well-know facts)',
                    'E.g. creation or consumption of your longer-form content sharing deeper experience and connected insight (e.g. TedX, long-form article, workshop-type video with slides and prep work)',
                    'E.g. creation or consumption of your written or video content series that connects a number of ideas together with deep experience to form solid new insights, and/or delivered in format with high production quality/values.',
                    'E.g. creation or consumption of your long-form maybe multi-step deep insight (e.g. a multi-week programme or course, with exercises, worksheets, or insight series delivered in a more structured/programmatic way)'
                ],
                'community': [
                    'E.g. Provided minor support for an activity, in a non-leadership capacity, and without significant accountability (e.g. volunteer \'runner\' for event, produced minor collateral, spread the word through word of mouth, etc.)',
                    'E.g. provided more support for an activity where initiative was needed rather than purely directed; still in non-leadership capacity and/or without significant accountability.',
                    'E.g. provided significant support to the lead or manager in charge of the activity possibly with accountabilities (e.g. ran a subarea of an event like comms, marketing, or catering, or sponsorship for a smaller/mid sized event)',
                    'E.g. Took a shared or significant lead role in organising the community activity, maybe part of a bigger team, working with other members underneath them.  (e.g. responsible for ticket sales, led sponsorship, ran logistics/operations, etc)',
                    'E.g. Took an overall leadership role in operating a sizeable event and which isn\'t remunerated in any other way.  Required significant investment of time, sweat, expertise to pull-off.  (e.g. event director or primary lead on an activity managing a sizeable team of volunteers or people to make it happen)'
                ]
            },
            equivalentValue:{
                'network': [
                    'Could be worth up to $10,000 of equivalent future value',
                    'Could be worth up to $250,000 of equivalent future value',
                    'Could be worth up to $500,000 worth of equivalent future value',
                    'Could be worth up to $1,000,000 of equivalent future value',
                    'Could be worth more than $1,000,000 of equivalent future value.'
                ],
                'mentorship': [
                    'Equivalent to unpaid advice',
                    '≈ $250 - $500 type equivalent',
                    '≈ $500 - $2,000 type equivalent',
                    '≈ $2,000 - $10,000 type equivalent',
                    '≈ $10,000+ type equivalent'
                ],
                'intellectual': [
                    '≈ $50 - $250 type equivalent',
                    '≈ $250 - $2,000 type equivalent',
                    '≈ $2,000 - $5,000 type equivalent',
                    '≈ $5,000 - $10,000 type equivalent',
                    '≈ $10,000+ type equivalent'
                ],
                'community': [
                    '≈ $250 - $500 type equivalent',
                    '≈ $500 - $1,000 type equivalent',
                    '≈ $1,000 - $5,000 type equivalent',
                    '≈ $5,000 - $10,000 type equivalent',
                    '≈ $10,000+ type equivalent'
                ],
            },

            // Tags
            tags: [],
            tagsLabelList: {
                'network': 'Tag the type of introduction and domain made',
                'mentorship': 'Tag the type of mentorship given',
                'intellectual': 'Tag the type of content made and the topics it covers',
                'community': 'Tag the type of operational effort provided and the activity type',
            },
            tagsHintList: {
                'network': 'E.g. investor, engineer, customer, potential hire, fintech, saas, warm, cold, etc',
                'mentorship': 'E.g. coaching, leadership, product, growth, investment-readiness, 1:1, 1:many, etc',
                'intellectual': 'E.g. article, video, course, short-form, long-form, on which topics, etc',
                'community': 'E.g. catering, sponsorship, partnerships, for a hackathon, a community call, a speaker session, etc.)',
            },
            skillTagsRules: [
                (v) => {
                    if (!v || !v.length) {
                        return true;
                    } else {
                        return v.length && v.length <= 10 || 'You can only choose a total of 10 tags';
                    }
                }
            ],

            // Tags combobox
            editing: null,
            editingIndex: -1,
            search: null,

            // Tags loaded from API upon mount
            originalUserTags: [],
            originalTags: [],

            selectedTags: [],           // Which tags are selected
            deletedUserTags: [],        // track deleted tags that have a tagid so we can delete server-side
            updatedUserTags: [],        // track updated tags that have a tagid so we can update server-side

            // Private message
            message: '',
            messageRules: [
                (v) => {
                    if (!v) {
                        return true;
                    } else {
                        return v.length && v.length <= 1000 || 'Your message must be no more than 1,000 characters';
                    }
                }
            ],
            selectedTextArea: null,

            // Submit error messages
            errorMessage: '',
        };
    },

    // Incoming properties for this component
    props: {
        type: { type: String, default: null },
        user: { type: Object, default: null },
    },

    components: {
        ProfileBox,
        ConfirmDialog,
    },

    computed: {
        // Map our Vuex getters
        ...mapGetters({
            isLoggedIn: 'Auth/isLoggedIn',
            userid: 'User/userid',
            firstname:'User/firstname',
            lastname:'User/lastname',
            profileImg:'User/profileImagePath',
            countryName:'User/countryName',
            countryCode:'User/countryCode',
            regionName:'User/regionName',
        }),

        heading() {
            return this.user ? `How much value did you provide to ${this.capitalize(this.user.firstname)}?` : '';
        },
        
        description() {
            if (this.type === 'network') return `Rate the value you felt you delivered and describe the type of introduction made`;
            if (this.type === 'mentorship') return `Rate the value you felt you delivered and describe the type of mentorship given`;
            if (this.type === 'intellectual') return `Rate the value you felt you delivered, and tag both the type and topic of the content created`;
            if (this.type === 'community') return `Rate the value you felt you delivered, and describe the type(s) of operational effort provided`;
            return '';
        },

        example() {
            if (!this.value || !this.type) return '';
            return this.exampleValue[this.type][this.value-1];
        },

        equivalent() {
            if (!this.value || !this.type) return '';
            return this.equivalentValue[this.type][this.value-1];
        },

        filteredTags() {
            if (!this.type) return [];
            const tags = this.tags.filter(t => t.type === this.type).sort((a, b) => {
                return a.text < b.text;
            });
            const userTags = this.tags.filter(t => t.type === 'user').sort((a, b) => {
                return a.text < b.text;
            });
            return [{ header: 'Select a tag, or type to add your own'}, ...userTags, ...tags];
        },

        tagsLabel() {
            if (!this.type) return [];
            return this.tagsLabelList[this.type];
        },

        tagsHint() {
            if (!this.type) return [];
            return this.tagsHintList[this.type];
        },

        messageLabel() {
            return this.user ? `You may enter a private message to ${this.capitalize(this.user.firstname)}...` : '';
        },

        isTextAreaSelected() {
            return (fieldname) => this.selectedTextArea == fieldname;
        },
    },

    watch: {
        // whenever login state changes and we're no longer logged in, we redirect to login page
        isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
            if (!newIsLoggedIn && oldIsLoggedIn) {
                this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
            }
        },

        // Watch for user-defined new skills and create them to the list
        selectedTags(val, prev) {
            if (val.length === prev.length) return

            // Determine if we added a new item (it will be a string not an item)
            this.selectedTags = val.map(v => {
                if (typeof v === 'string') {
                    // Check if this matches an existing custom tag and add that, or create a new tag
                    const match = this.tags.find(i => {
                        return i.type == this.type && i.text.toString().toLowerCase() === v.toString().toLowerCase()
                    });

                    // Use the match if found
                    if (match) {
                        return Object.assign({}, match);
                    } else {
                        // Create a new tag
                        v = {
                            text: v,
                            type: "user",
                            tagid: -1,
                        }
                        this.tags.push(v);
                    }
                }

                return v;
            });
        },
    },

    beforeMount() {
        if (!this.type) {
            this.$router.replace({ name: 'valueHome' });
        }

        if (!this.user){
            this.navigateBack();
        }

        // Fetch the tags for the drop down
        ValueExchangeAPIService.getTags(this.type, this.userid)
        .then( (response) => {
            // If the server was unreachable or timedout, the request is cancelled and goes into the then handler trap this as a NoResponseAPIError
            if (!response || !response.message) {
                throw new NoResponseAPIError();
            }

            // Make sure our expected tags are in the response
            if (!response.tags[this.type] || !response.tags.user) {
                throw 'There was a problem fetching data, please try again later';
            }
            
            // Stash the list of user tags so we can check if anything changed
            this.originalUserTags = response.tags.user;

            // Stash the list of default skills
            this.originalTags = response.tags[this.type];
            
            // Build the list of all tags for the combobox
            this.tags = [...JSON.parse(JSON.stringify(this.originalUserTags)), ...JSON.parse(JSON.stringify(this.originalTags))];     // deep copy
        })
        .catch( (error) => {
            // Clear the snackbar
            this.clearSnackBar();

            if (error instanceof NoResponseAPIError ) {
                this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
            } else if (error instanceof UnsupportedMediaAPIError) {
                this.errorMessage = 'We encountered a server problem, please try again later';
            } else if (error instanceof BadMethodAPIError) {
                this.errorMessage = 'We encountered a technical problem, please try again later';
            } else if (error instanceof BadRequestAPIError) {
                this.errorMessage = 'We encountered a problem, please try again later';
            } else if (error instanceof AuthenticationAPIError) {
                this.errorMessage = 'We encountered an authentication problem, please logout and try again';
            } else if (error instanceof InternalServerAPIError) {
                this.errorMessage = 'We encountered a server problem, please try again later';
            } else {
                this.errorMessage = 'There was a problem, please try again later';
            }

            // Show snackbar error message
            this.setSnackBar({ snack: this.errorMessage });
        });
    },

    // If we're not navigating to another exchange page, we clear the searchResults
    beforeRouteLeave(to,from,next) {
        const validRouteNames = ['valueHome', 'valueAddPerson', 'valueLogValue', 'valueLogged', 'valueConfirm', 'valueConfirmed', 'valueDisputed', 'valueView'];
        if (!to.name || validRouteNames.indexOf(to.name) === -1) {
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-searchResults' });
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-recentResults' });
        }
        next();
    },

    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
        }),

        deselect() {
            this.navigateBack();
        },

        navigateBack() {
            // We explicitly go back to value exchange home in case the browser back goes somewhere else
            this.$router.replace({ name: 'valueAddPerson', params: { type: this.type }});
        },

        capitalize(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },

        // Selects a text area so we can make it auto-grow when focussed
        selectTextArea(fieldname) {
            this.selectedTextArea = fieldname;
        },

        deselectTextArea() {
            this.selectedTextArea = null;
        },

        // Filter the list of tags to remove items that are already selected
        filterTags(item, queryText, itemText) {
            if (item.header) return false;

            // Local function to check if item is empty and return empty string instead of object
            const hasValue = val => val != null ? val : '';
            const text = hasValue(itemText);
            const query = hasValue(queryText);

            // Check if the item matches the query
            return text.toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1;
        },

        // Remove a custom user tag from the list of available tags
        deleteTag(item) {
            // Find this matching item's index
            const index = this.tags.findIndex( i => {
                if (i.text === item.text && i.type === item.type && i.tagid === item.tagid) {
                    return true;
                }
            });

            // Delete this index
            if (index !== -1) {
                // If this item has a tagid, it means it's been saved server-side already, so we save these details so we can post to API to delete
                if (this.tags[index] && this.tags[index].tagid !== -1) {
                    this.deletedUserTags.push(this.tags[index]);
                }
                
                // Now delete this item
                this.tags.splice(index, 1);
            }

            // If this item is in the updatedTags list, there's no need to update it's name anymore, remove it
            const updatedIndex = this.updatedUserTags.findIndex( i => {
                if (i.tagid === item.tagid && item.type === 'user') {
                        return true;
                }
            });
            if (updatedIndex !== -1) {
                // Remove this item from the updatedUserTags array
                this.updatedUserTags.splice(updatedIndex, 1);
            }
        },

        // Edit the name of a tag - we need to stash and save if editing a tag already saved on the server so it can update server side too
        editTag(index, item) {
            if (!this.editing) {
                // Started editing
                this.editing = item;
                this.editingIndex = index;
            } else {
                // Finished editing
                
                // If we're editing a saved user tag, we add it to updatedUserTags so we can submit to server
                if (item.type === 'user' && item.tagid !== -1) {
                    // Stopped editing - check if the text changed from the original value
                    const originalItem = this.originalUserTags.find(i => i.tagid === item.tagid);

                    // See if the item is already in update list
                    const updatedIndex = this.updatedUserTags.findIndex( i => {
                        if (i.text === item.text && i.type === item.type && i.tagid === item.tagid) {
                            return true;
                        }
                    });

                    // Remove this item from the list first
                    if (updatedIndex !== -1) {
                        this.updatedUserTags.splice(updatedIndex, 1);
                    }

                    // Now add the new edited item back onto the end if it's changed
                    if (!originalItem || originalItem.text !== item.text) {
                        this.updatedUserTags.push(item);
                    }
                }

                // Reset the edited item
                this.editing = null;
                this.editingIndex = -1;
            }
        },

        async submit() {
            // User confirm
            if (!await this.$refs.confirm.open("Confirm", "Are you sure you want to log this value exchange?")) {
                return;
            }

            // Cancel submit if form invalid
            if (!this.$refs.form.validate()) {
                return;
            }

            // Clear the snackbar
            this.clearSnackBar();
                
            // Process a valid form submit
            this.pending = true;

            // Build an array of form values to submit
            let submitData = {
                fromId: Number(this.userid),
                toId: Number(this.user.id),
                value: Number(this.value),
                type: this.type,
                message: this.message,
            };

            // Format our skills fields for submitting (remove the popularity field)
            const submitSelectedTags = this.selectedTags.map((i) => {
                return {
                    text: i.text,
                    type: i.type,
                    tagid: Number(i.tagid),
                }
            });
            submitData.selectedTags = JSON.stringify(submitSelectedTags);

            // Are we deleting any custom user skills?
            const submitDeletedTags = this.deletedUserTags.map((i) => {
                return {
                    text: i.text,
                    type: i.type,
                    tagid: Number(i.tagid),
                }
            });
            submitData.deletedTags = JSON.stringify(submitDeletedTags);

            // Are we updating any custom user skills?  Just collapse into their respective tagids
            const submitUpdatedTags = this.updatedUserTags.map((i) => {
                return {
                    text: i.text,
                    type: i.type,
                    tagid: Number(i.tagid),
                }
            });
            submitData.updatedTags = JSON.stringify(submitUpdatedTags);

            // Send to API endpoint
            ValueExchangeAPIService.logValue(this.type, this.value, this.userid, this.user.id, submitData)
            .then((response) => {
                // If the server was unreachable or timedout, the request is cancelled and goes into the then handler - trap this as a NoResponseAPIError
                if (!response || !response.message) {
                    throw new NoResponseAPIError();
                }

                // Clear the error message
                this.errorMessage = '';

                // Goto the next steps page
                this.$router.replace({ name: 'valueLogged', params: { 'firstName': this.capitalize(this.user.firstname)} });
            })
            .catch((error) => {
                // Clear the snackbar
                this.clearSnackBar();

                if (error instanceof NoResponseAPIError ) {
                    this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                } else if (error instanceof UnsupportedMediaAPIError) {
                    this.errorMessage = 'We encountered a server problem logging the transaction, please try again later.';
                } else if (error instanceof BadMethodAPIError) {
                    this.errorMessage = 'We encountered a problem logging the transaction, please try again later.';
                } else if (error instanceof BadRequestAPIError) {
                    this.errorMessage = 'We encountered a problem logging the transaction, please try again.';
                } else if (error instanceof AuthenticationAPIError) {
                    this.errorMessage = 'We encountered a problem logging the transaction, please try again later.';
                } else if (error instanceof CredentialsRevokedAPIError) {
                    this.errorMessage = 'You are not allowed to log the transaction, please contact us for support.';
                } else if (error instanceof InternalServerAPIError) {
                    this.errorMessage = 'We encountered a server problem logging the transaction, please try again later.';
                } else {
                    this.errorMessage = 'There was a problem logging the transaction, please try again.';
                }

                // Show snackbar error message
                this.setSnackBar({ snack: this.errorMessage });
            })
            .finally(() => {
                // Reset server side submit state
                this.pending = false;
            });
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
.value_arrow {
    font-size: 40px;
    color: #d6d7d6;
}

.example {
    font-size: 12px;
}
</style>

<style>
.v-slider__tick--filled {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.v-slider--horizontal .v-slider__thumb-label {
    text-align: center;
}

.v-slider--horizontal .v-slider__tick .v-slider__tick-label {
    font-size: 10px;
}

.v-input__slider .v-messages__message {
    margin-top: 10px;
}

.v-tagcombobox .v-select__selections {
    margin-top: 10px;
    margin-bottom: 10px;
}

.v-tagcombobox .v-select__selections {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Shift label when not selected to fit new box height */
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small .v-select__selections {
    min-height: 52px;
}

/* Push label down to match increase min-height to allow for chips */
.v-tagcombobox .v-label {
    margin-top: 10px;
}

/* Shift label up when the combobox is selected */
.v-tagcombobox.v-input--is-label-active .v-label, .v-tagcombobox.v-select--is-menu-active .v-label {
    margin-top: 0px;
}

/* center align the arrow dropdown for our added margin */
.v-tagcombobox div[role=combobox] .v-input__append-inner {
    margin-top: 27px;
}
</style>

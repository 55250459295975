/**
 * API Middleware for Member Directory-related API functions
 *
 * Contains transport-level functions to make API calls and process the responses,
 * Setting any relevant data in the Vuex stores, then passing back data back up to the UI for rendering
 * 
 * @file   API middleware for member directory-related functions
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Common includes used in this file
import axios from '@/common/axios';
import { MD5 } from 'crypto-es/lib/md5.js';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';

/*
 * function search ()
 *
 * API call to search the member directory and return search results
 *
 */
const search = async (userid, search, page, nearby, recent) => {
    // Check we have something to search for
    if (!search) {
        return;
    }

    // Generate a signed API request using our shared secret key
    const signatureStr = 'directory/search' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userid + search.trim().toLowerCase() + page;
    const signature = MD5(signatureStr).toString();

    // Post to the user endpoint
    return axios.post('directory/search', {
        userid,
        search,
        page,
        signature,
        nearby,
        recent,
    }).then(response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function recentNearby ()
 *
 * API call to search the member directory and return recent members and nearby members for a user
 *
 */
const recentNearby = async (userid) => {
    // Generate a signed API request using our shared secret key
    const signatureStr = 'directory/default' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userid;
    const signature = MD5(signatureStr).toString();

    // Post to the user endpoint
    return axios.post('directory/default', {
        userid,
        signature,
    }).then(response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function nearby ()
 *
 * API call to search local users nearby the searcher
 *
 */
const nearby = async (userid, page) => {
    // Generate a signed API request using our shared secret key
    const signatureStr = 'directory/nearby' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userid + page;
    const signature = MD5(signatureStr).toString();

    // Post to the user endpoint
    return axios.post('directory/nearby', {
        userid,
        page,
        signature,
    }).then(response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function recent()
 *
 * API call to search recent users
 *
 */
const recent = async (userid, page, recent=true) => {
    // Generate a signed API request using our shared secret key
    const signatureStr = 'directory/recent' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userid + page;
    const signature = MD5(signatureStr).toString();

    // Post to the user endpoint
    return axios.post('directory/recent', {
        userid,
        page,
        recent,
        signature,
    }).then(response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

/*
 * function member ()
 *
 * API call to fetch a directory member
 *
 */
const member = async (userid, fetchid) => {
    // Generate a signed API request using our shared secret key
    const signatureStr = 'directory/member' + process.env.VUE_APP_API_SIGNATURE_SHARED_SECRET + userid + fetchid;
    const signature = MD5(signatureStr).toString();

    // Post to the user endpoint
    return axios.post('directory/member', {
        userid,
        fetchid,
        signature,
    }).then(response => {
        // Return the data returned from the API so UI can access it
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'FORBIDDEN') {
                throw new CredentialsRevokedAPIError(error.response, error.request. error);
            } else if (error.response.status === 403 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

// Export each API endpoint
export default {
    search,
    recentNearby,
    nearby,
    recent,
    member,
};
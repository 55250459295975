/**
 * Main application entry point
 *
 * Imports key libraries and does any necssary stup before instantiating the main app component
 * 
 * @file   Front-end view controller for the register page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Common include files used in the file
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';

// Disable the production tip
Vue.config.productionTip = false;

// Fire up the main application instance
new Vue({
 store,
 router,
 vuetify,
 render: h => h(App)
}).$mount('#app');

/**
 * Main include file for the Vuex data stores
 *
 * Setups the main store component and loads the various sub-module vuex stores
 * 
 * @file   Main include file for the Vuex data stores
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Common includes used in this file
import Vue from 'vue';
import Vuex from 'vuex';

// Import our store sub-modules
import Auth from './modules/auth';
import User from './modules/user';
import SnackBar from './modules/snackbar';
import SearchResults from './modules/searchresults';
import Seasons from './modules/seasons';

// Enable Vuex on the vue component
Vue.use(Vuex);

// Load up and configure the Vuex store
const store = new Vuex.Store({
    strict: true,
    modules: {
        Auth,
        User,
        SnackBar,
        SearchResults,
        Seasons,
    },
});

export default store;
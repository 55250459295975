import { render, staticRenderFns } from "./ValueTransactionBox.vue?vue&type=template&id=6c9a853d&scoped=true&"
import script from "./ValueTransactionBox.vue?vue&type=script&lang=js&"
export * from "./ValueTransactionBox.vue?vue&type=script&lang=js&"
import style0 from "./ValueTransactionBox.vue?vue&type=style&index=0&id=6c9a853d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c9a853d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCard,VCol,VContainer,VHover,VIcon,VRating,VRow})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{attrs:{"id":"top"}},[_c('v-app-bar',{attrs:{"app":"","color":"#fffffff0","elevate-on-scroll":"","dark":"","extended":"","extension-height":"40","fixed":"","flat":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('h1',{staticClass:"logo mt-10 mx-5",style:({ 'color': hover ? '#BB86FC' : '#6A6A6A' }),attrs:{"color":"#ffd700"},on:{"click":_vm.navigateHome}},[_vm._v("+Σ Platform")])]}}])}),_c('v-spacer'),_vm._l((_vm.items),function(item,i){return [(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-hover',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(item.icon)?_c('v-btn',{staticClass:"mx-2 px-2 mt-10",staticStyle:{"min-width":"auto"},attrs:{"text":"","light":"","large":"","color":hover ? '#BB86FC' : '#6A6A6A',"to":item.route ? { name: item.route, hash: (item.hash ? item.hash : null) } : null},on:{"click":function($event){return _vm.openUrl(item.url)}}},[_c('img',{attrs:{"src":hover ? item.iconHover : item.icon,"height":"30"}})]):_c('v-btn',{staticClass:"mx-2 px-2 mt-10 nav",class:{ 'ml-5': item.outlined },staticStyle:{"min-width":"auto","text-transform":"none"},attrs:{"outlined":item.outlined,"text":"","light":"","large":"","color":hover ? '#BB86FC' : '#6A6A6A',"to":item.route ? { name: item.route, hash: (item.hash ? item.hash: '') } : null},on:{"click":function($event){return _vm.openUrl(item.url)}}},[_vm._v(_vm._s(item.text))])]}}],null,true)}):_vm._e()]}),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-app-bar-nav-icon',{staticClass:"mt-10 mr-0",style:({ 'color': hover ? '#BB86FC' : '#6A6A6A' }),attrs:{"large":""},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})]}}],null,false,879884405)}):_vm._e()],2),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-navigation-drawer',{attrs:{"permanent":false,"app":"","mini-variant":"","mini-variant-width":"60%"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"pb-0"},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(item.icon)?_c('v-btn',{staticClass:"mt-5 text-left",attrs:{"text":"","large":"","to":item.route ? { name: item.route, hash: (item.hash ? item.hash: '') } : null},on:{"click":function($event){return _vm.openUrl(item.url)}}},[_c('img',{attrs:{"src":hover ? item.iconHover : item.icon,"height":"30"}})]):_c('v-btn',{staticClass:"text-left mt-5 nav",staticStyle:{"text-transform":"none"},attrs:{"outlined":item.outlined,"height":"50","text":"","large":"","color":hover ? '#BB86FC' : '#6A6A6A',"to":item.route ? { name: item.route, hash: (item.hash ? item.hash: '') } : null},on:{"click":function($event){return _vm.openUrl(item.url)}}},[_vm._v(_vm._s(item.text))])]}}],null,true)})],1)],1)],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
/**
 * Snack Bar component
 *
 * Renders a reusable application wide snack bar that listens for snackbar messages to the store and diplays them
 * using an application-wide snack bar vuetofy component
 *
 * @file   Snack Bar component
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
<template>
    <v-snackbar v-model="show" app v-bind="{ ...snackbarAttrs }">
        <!-- The snack bar message to display -->
        {{message}}

        <template v-slot:action="{ attrs }">
            <!-- An optional router link with title -->
            <v-btn v-if="routerLink && linkText" text color="white" @click.native="clickLinkRoute" v-bind="attrs">
                {{linkText}}
            </v-btn>

            <!-- An optional retry button to retry an action -->
            <v-btn v-if="showRetry && clickRetry" text icon color="white" @click.native="clickRetry" v-bind="attrs">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <!-- An optional close button to close a persistent Snackbar -->
            <v-btn v-if="showClose" text icon color="white" @click.native="show = false" v-bind="attrs">
                <v-icon>mdi-close</v-icon>
            </v-btn>
      </template>
  </v-snackbar>
</template>

<script>
export default {
    name: 'SnackBar',
    
    data() {
        return {
            show: false,
            showRetry: false,
            clickRetry: null,
            showClose: true,
            message: '',
            timeout: 5000,

            // Position
            top: false,
            bottom: true,
            centered: false,
            left: false,

            // Style
            light: false,
            dark: false,
            color: '',

            // Are we showing a router link
            routerLink: null,
            linkText: null,
        };
    },
  
    // Computed params
    computed: {
        snackbarAttrs() {
            return {
                timeout: this.timeout,
                bottom: this.bottom,
                top: this.top,
                color: this.color,
                centered: this.centered,
                left: this.left,
                light: this.light,
                dark: this.dark,
            }
        }
    },
    
    created() {
        // Listen to any snackbar mutations and grab the message and set up a timer to show it
        this.$store.subscribe((mutation) => {
            if (mutation.type === 'SnackBar/SET_SNACK') {
                // First clear out any old parameters
                this.timeout = 5000;
                this.bottom = true;
                this.top = false;
                this.color = '';
                this.centered = false;
                this.left = false;
                this.light = false;
                this.dark = false;
                this.routerLink = null;
                this.linkText = null;
                this.showRetry = false;
                this.clickRetry = null;
                this.showClose = true,
                this.message = '';
            
                // Now get new parameters from the payload
                const snack = (mutation.payload && mutation.payload.snack) ? mutation.payload.snack : null;
                const retry = (mutation.payload && mutation.payload.retry) ? mutation.payload.retry : null;
                const timeout = (mutation.payload && Object.prototype.hasOwnProperty.call(mutation.payload, 'timeout')) ? mutation.payload.timeout : this.timeout;
                const bottom = (mutation.payload && Object.prototype.hasOwnProperty.call(mutation.payload, 'bottom')) ? mutation.payload.timeout : this.bottom;
                const top = (mutation.payload && Object.prototype.hasOwnProperty.call(mutation.payload, 'top')) ? mutation.payload.top : this.top;
                const color = (mutation.payload && Object.prototype.hasOwnProperty.call(mutation.payload, 'color')) ? mutation.payload.color : 'error';
                const centered = (mutation.payload && Object.prototype.hasOwnProperty.call(mutation.payload, 'centered')) ? mutation.payload.timeout : this.centered;
                const left = (mutation.payload && Object.prototype.hasOwnProperty.call(mutation.payload, 'left')) ? mutation.payload.timeout : this.left;
                const light = (mutation.payload && Object.prototype.hasOwnProperty.call(mutation.payload, 'light')) ? mutation.payload.timeout : this.light;
                const dark = (mutation.payload && Object.prototype.hasOwnProperty.call(mutation.payload, 'dark')) ? mutation.payload.timeout : this.dark;

                // Extract whether we're showing a router link
                const routerLink = (mutation.payload && Object.prototype.hasOwnProperty.call(mutation.payload, 'routerLink')) ? mutation.payload.routerLink : this.routerLink;
                const linkText = (mutation.payload && Object.prototype.hasOwnProperty.call(mutation.payload, 'linkText')) ? mutation.payload.linkText : this.linkText;

                // Setup a new snack message and show the snackbar
                if (snack) {
                    // Add the retry handler
                    if (retry) {
                        this.showRetry = true;
                        this.clickRetry = retry;
                    } else {
                        this.showRetry = false;
                        this.clickRetry = null;
                    }

                    // Set the timeout and other parameters in scope
                    this.timeout = timeout;
                    this.bottom = bottom;
                    this.top = top;
                    this.color = color;
                    this.centered = centered;
                    this.left = left;
                    this.light = light;
                    this.dark = dark;
                    this.routerLink = routerLink;
                    this.linkText = linkText;

                    // Add the close button
                    if (close) {
                        this.showClose = close;
                    }

                    // Finally, show the message
                    if (snack) {
                        this.show = true;
                        this.message = snack;
                    }
                } else {
                    this.show = false;
                }
            }
        });
    },

    methods: {
        clickLinkRoute() {
            // hide the snackbar
            this.show = false;

            // goto the route specified
            this.$router.push(this.routerLink);
        }
    }
};
</script>

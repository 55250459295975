/**
 * Vuex storage for user-related data storage
 *
 * Corresponding getters, mutators, and actions for modifying this local data store
 * 
 * @file   Vuex data store for user data
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Vue object so can use .$set
import Vue from 'vue';

// API Connectors
import DirectoryAPI from '@/services/DirectoryAPIService.js';

// We use this store for user data that haa been fetched, storing a timestamp and caching user data for a preset amount of time
const USER_CACHE_SECONDS = 1 * 60 * 30;

// Setup the default state
const getDefaultState = () => {
    return {
        // User details
        user: {
            userid: null,
            firstname: '',
            lastname: '',
            email: '',
            countryName: '',
            countryCode: '',
            regionName: '',
            regionCode: '',
            region: null,
            permission: 0,
            profileImg: {},
        },
        userCache: {},              // array of objects, with a key fetchTime (unix_timestamp)
    };
};

// Setup the store mutations
const mutations = {
    SET_USER: (state, user) => {
        state.user = user;
    },

    SET_FETCHED_USER: (state, user) => {
        // Create the key if it doesn't exist
        if (!Object.prototype.hasOwnProperty.call(state.userCache, Number(user.userid))) {
            Vue.set(state.userCache, Number(user.userid), {});
        }

        // Save the details
        Vue.set(state.userCache, Number(user.userid), user);
    },

    RESET: state => {
        // Reset the state
        Object.assign(state, getDefaultState());
    },
};

// Setup our getters
const getters = {
    get: state => {
        return state.user;
    },

    userid: state => {
        return state.user && state.user.userid ? state.user.userid : null;
    },

    firstname: state => {
        return state.user && state.user.firstname ? state.user.firstname : null;
    },

    lastname: state => {
        return state.user && state.user.lastname ? state.user.lastname : null;
    },

    email: state => {
        return state.user && state.user.email ? state.user.email : null;
    },

    countryName: state => {
        return state.user && state.user.countryName ? state.user.countryName : null;
    },

    countryCode: state => {
        return state.user && state.user.countryCode ? state.user.countryCode : null;
    },

    regionName: state => {
        return state.user && state.user.regionName ? state.user.regionName : null;
    },

    regionCode: state => {
        return state.user && state.user.regionCode ? state.user.regionCode : null;
    },

    permission: state => {
        return state.user && state.user.permission ? state.user.permission : null;
    },

    profileImagePath: state => {
        return state.user && state.user.profileImg ? state.user.profileImg : null;
    },

    user: state => {
        return (userId) => {
            if (userId && Object.prototype.hasOwnProperty.call(state.userCache, Number(userId))) {
                return state.userCache[userId];
            } else {
                return null;
            }
        }
    },
};

// Setup our actions
const actions = {
    /*
     * function SetUser()
     *
     * Set the state for a new user's details (bite won;t update the encoded user state in the access token)
     */
    SET_USER: ({ commit }, { user }) => {
        commit('SET_USER', user);
    },

    fetchUser: ({ commit, getters }, { userId, fetchId }) => {
        // Check the key exists
        const user = getters.user(fetchId);
        let fetch = true;
        if (user) {
            // Get user details
            const fetchTime = user.fetchTs ? user.fetchTs : null;
            const now = Date.now() / 1000;
            const expireTime = fetchTime + USER_CACHE_SECONDS;

            // If expired, used cached details
            if (expireTime > now) {
                fetch = false;
            }
        }

        // Fetch user details from the API and cache results
        if (fetch) {
            DirectoryAPI.member(userId, fetchId)
            .then( (response) => {
                if (!response || !response.member) {
                    throw ('problem fetching user');
                }

                // Cache the user's details and save the fetch time
                let user = response.member;
                user.fetchTs = Date.now() / 1000;
                
                // Update the state
                commit('SET_FETCHED_USER', user);

                return Promise.resolve(user);
            })
            .catch((e) => {
                return Promise.reject(e);
            });
        }
    }
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations,
    getters,
    actions,
};
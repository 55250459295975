/**
 * Main application navigation router configuration
 *
 * Setups the application routes and page guards
 * 
 * @file   Main application navigation router configuration
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Common includes used in this file
import Vue from "vue";
import Router from "vue-router";
import AuthAPI from '@/services/AuthAPIService.js';
import goTo from 'vuetify/lib/services/goto';

// Main/default routes
import Home from '@/views/Home';
import HomeAbout from '@/views/static/About';
import HomeHowItWorks from '@/views/static/HowItWorks';
import Dashboard from "@/views/Dashboard";

// Static Routes
import NotFound from '@/views/static/NotFound';
import NotAuthorised from '@/views/static/NotAuthorised';

// Import our auth components for the routes
import Login from "@/views/auth/Login";
import Logout from '@/views/auth/Logout';
import Forgot from '@/views/auth/Forgot';
import ForgotCheckEmail from '@/views/auth/ForgotCheckEmail';
import Reset from '@/views/auth/Reset';

// Import our user components for the routes
import Register from "@/views/user/Register";
import RegisterConfirm from "@/views/user/RegisterConfirm";
import UserProfile from "@/views/user/Profile";
import EditProfile from "@/views/user/EditProfile";
import UserUnsubscribe from "@/views/user/Unsubscribe";
import UserUnsubscribed from "@/views/user/Unsubscribed";

// Import our admin components for the routes
import AdminHome from "@/views/admin/Home";

// Import our main application area routes

// Member Directory
import DirectoryHome from "@/views/directory/Home";
import DirectoryMember from "@/views/directory/Member";

// Community Calendar
import CalendarHome from "@/views/calendar/Home";

// Resource Library
import LibraryHome from "@/views/library/Home";

// Asks & Bounties
import BountiesHome from "@/views/bounties/Home";

// Value Exchange
import ValueHome from "@/views/value/Home";
import AddPerson from '@/views/value/AddPerson';
import LogValue from '@/views/value/LogValue';
import ValueLogged from '@/views/value/ValueLogged';
import ConfirmValue from '@/views/value/ConfirmValue';
import ValueConfirmed from '@/views/value/ValueConfirmed';
import ValueDisputed from '@/views/value/ValueDisputed';
import ViewValue from '@/views/value/ValueView';

// Add the router to the vue component
Vue.use(Router);

// Setup the new router and configure the supported routes
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  
  // Define our base routes
  // Note we add a couple of extra parameters to denote special access
  // requiresAuth: true = login required to access this page
  // requiresAdmin: true = login required to access this page and admin permissions too
  routes: [
    // Setup a wildcard route to a 404 page
    { path: '/notfound', name: 'notFound', component: NotFound, meta: { title: 'Not Found' } },
    { path: '*', redirect: 'notFound' },
    
    // Path for protected pages
    { path: '/notauthorised', name: 'notAuthorised', component: NotAuthorised, meta: { title: 'Not Authorised' } },

    // Main home page
    { path: "/", name: "home", component: Home, meta: { title: 'Home' } },
    { path: "/about", name: "homeAbout", component: HomeAbout, meta: { title: 'About +Σ Platform' } },
    { path: "/how-it-works", name: "homeHowItWorks", component: HomeHowItWorks, meta: { title: 'How +Σ Platform Works' } },

    // Main logged in home page
    { path: '/dashboard', name: 'dashboard', component: Dashboard, meta: { title: 'Dashboard', requiresAuth: true } },

    // Auth routes
    { path: '/auth/login', props: true, name: 'login', component: Login, meta: { title: 'Login' } },
    { path: '/auth/login/:fromemail', props: true, name: 'loginWithEmail', component: Login, meta: { title: 'Login' } },
    { path: '/auth/logout', name: 'logout', component: Logout, meta: { title: 'Logout' } },
    { path: '/auth/forgot', name: 'forgot', component: Forgot, meta: { title: 'Reset Password' } },
    { path: '/auth/forgot/:email', props: true, name: 'forgotCheckEmail', component: ForgotCheckEmail, meta: { title: 'Check Your Email' } },
    { path: '/auth/reset/:resetToken/:email', name: 'resetPassword', props: true, component: Reset, meta: { title: 'Change Password' } },
    
    // User routes
    { path: '/user', alias: '/user/profile', name: 'userProfile', component: UserProfile, meta: { title: 'Your Profile', requiresAuth: true } },
    { path: '/user/unsubscribe', name: 'userUnsubscribe', component: UserUnsubscribe, meta: { title: 'Unsubscribe' } },
    { path: '/user/unsubscribed', props: true, name: 'userUnsubscribed', component: UserUnsubscribed, meta: { title: 'Unsubscribed' } },
    { path: '/user/profile/edit', props: true, name: 'userEditProfile', component: EditProfile, meta: { title: 'Edit Profile' } },
    { path: '/user/register', name: 'register', component: Register, meta: { title: 'Register' } },
    { path: '/user/confirm/:email', props: true, name: 'registerConfirm', component: RegisterConfirm, meta: { title: 'Confirm Your Email' } },
        
    // Admin routes
    { path: "/admin", name: "admin", component: AdminHome, meta: { title: 'Admin', requiresAdmin: true } },

    // Directory routes
    { path: "/directory", name: "directoryHome", component: DirectoryHome, meta: { title: 'Member Directory', requiresAuth: true } },
    { path: '/directory/member/:fetchId', props: true, name: 'directoryMember', component: DirectoryMember, meta: { title: 'View Member', requiresAuth: true } },

    // Community Calendar routes
    { path: "/calendar", name: "calendarHome", component: CalendarHome, meta: { title: 'Community Calendar', requiresAuth: true } },

    // Resource Library routes
    { path: "/library", name: "libraryHome", component: LibraryHome, meta: { title: 'Resource Library', requiresAuth: true } },

    // Asks & Bounties routes
    { path: "/bounties", name: "bountiesHome", component: BountiesHome, meta: { title: 'Asks & Bounties', requiresAuth: true } },

    // Value Exchange routes
    { path: "/value", name: "valueHome", component: ValueHome, meta: { title: 'Value Exchange', requiresAuth: true } },
    { path: "/value/add/:type", props: true, name: "valueAddPerson", component: AddPerson, meta: { title: 'Value Exchange - Add Person', requiresAuth: true } },
    { path: "/value/add/:type/log", props: true, name: "valueLogValue", component: LogValue, meta: { title: 'Value Exchange - Log Value', requiresAuth: true } },
    { path: "/value/logged/:firstName", props: true, name: "valueLogged", component: ValueLogged, meta: { title: 'Value Exchange - Value Logged', requiresAuth: true } },
    { path: "/value/confirm/:valueId", props: true, name: "valueConfirm", component: ConfirmValue, meta: { title: 'Value Exchange - Confirm Value', requiresAuth: true } },
    { path: "/value/confirmed/:firstName", props: true, name: "valueConfirmed", component: ValueConfirmed, meta: { title: 'Value Exchange - Value Confirmed', requiresAuth: true } },
    { path: "/value/disputed", name: "valueDisputed", component: ValueDisputed, meta: { title: 'Value Exchange - Value Disputed', requiresAuth: true } },
    { path: "/value/:valueId", props: true, name: "valueView", component: ViewValue, meta: { title: 'Value Exchange - View Value', requiresAuth: true } },
  ],

  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

// Add a route guard to check login state of the target page, and ensure auth store is initialised
router.beforeEach((to, from, next) => {
  // Set the title of each route
  document.title = to.meta.title;

  // Initialise the auth store if it hasn't been already
  router.app.$options.store.dispatch('Auth/initialise')
    .then(() => {
      // Check if this requires the user to be logged in
      const isLoggedIn = router.app.$options.store.getters['Auth/isLoggedIn'];
      const accessToken = router.app.$options.store.getters['Auth/accessToken'];

      // Make sure the auth header is set in case we set it from localstorage before xml-rpc object was initialised
      if (isLoggedIn) {
        AuthAPI.setAuthHeader(accessToken);
      }

      // Redirect to login page or notAuthorised page if don't have permissions
      const isAdmin = router.app.$options.store.getters['Auth/isAdmin'];
      if ((to.meta.requiresAuth || to.meta.requiresAdmin) && !isLoggedIn) {
        next({ name: 'login', query: { return: to.fullPath } });
      } else if (to.meta.requiresAdmin && !isAdmin) {
        next({ name: 'notAuthorised' });
      }else {
        // All good, let them through
        next();
      }
  });
});

export default router;
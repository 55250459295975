/**
 * Front-end view controller for the logout page
 *
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * Note:
 * 
 *
 * @file   Front-end view controller for the logout page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container>
        <v-row align="start" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col>
                <!-- Title -->
                <h1>You have been logged out successfully</h1>
                
                <!-- Blurb -->
                <p>Thank you for using our platform, we hope to see you soon.</p>

                <!-- Back to Home page -->
                <ul>
                    <li><router-link to="/">Back to home page</router-link></li>
                </ul>

                <br />
                
                <!-- Login Again -->
                <ul>
                    <li><router-link to="/auth/login">Login In Again</router-link></li>
                </ul>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// The main logout component
export default {
    name: 'LogoutUser',

    async beforeMount() {
        // Logout the user
        await this.$store.dispatch('Auth/Logout');
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>

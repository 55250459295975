/**
 * Front-end view controller for the unsubscribe page
 *
 * Render's the page template, and processes any UI functions and interactivity
 *
 * @file   Front-end view controller for the unsubscribe email page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container>
        <v-row align="start" justify="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col cols="8">
                <!-- Title -->
                <h1>Unsubscribe</h1>
                
                <!-- Blurb -->
                <p>We're sorry you no longer want to receive notifications from us.</p>

                <!-- Forgot Form -->
                <v-form ref="form" v-model="valid" @submit.prevent="submit" autocomplete="off" style="margin-top: 60px;">
                    <!-- Email field -->
                    <v-text-field v-model="email" :rules="emailRules" required filled>
                        <template #label><span class="red--text"><strong>* </strong></span>Your email address</template>
                    </v-text-field>

                    <p>Any feedback you have will help improve our app for everyone else.</p>
                    <p>You can email us at <a :href="`mailto:${appEmail}'?subject=Unsubscribe%20Feedback'`" target="_blank">{{appEmail}}</a></p>
                    <p>Thank you!</p>
            
                    <!-- Submit button -->
                    <v-btn class="mt-15" type="submit" :disabled="!valid" :loading="pending">Unsubscribe</v-btn>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapActions } from 'vuex';

// API Connectors
import UserAPI from '@/services/UserAPIService.js';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';

export default {
    name: 'UserUnsubscribe',

    data() {
        return {
            // Form fields
            appEmail: process.env.VUE_APP_CONTACT_EMAIL,
            email: '',
            
            // Form submit state
            valid: false,
            submitted: false,
            pending: false,

            // Error Message
            errorMessage: '',

            // Validation Rules
            emailRules: [
                v => !!v || 'Email address is required',
                v => /.+@.+/.test(v) || 'Email address must be valid',
            ],
        };
    },

    beforeMount() {
        // Populate email address from the query string if any
        if (this.$route.query.email) {
            this.email = this.$route.query.email;
        }
    },
    
    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
        }),
        
        // Submit form - validates entered code via the API
        async submit() {
            // Cancel submit if form invalid
            if (!this.$refs.form.validate()) {
                return;
            }

            // Clear the snackbar
            this.clearSnackBar();

            // Process a valid form submit
            this.pending = true;

            // Build an array of form values to submit
            const submitData = {
                email: this.email,
            };

            // Dispatch to API
            UserAPI.unsubscribe(submitData)
            .then((response) => {
                // If the server was unreachable or timedout, the request is cancelled and goes into the then handler so trap this as a NoResponseAPIError
                if (!response || !response.message) {
                    throw new NoResponseAPIError();
                }

                // Set the code as confirmed
                if (response.reason && response.reason === 'SUCCESS') {
                    this.errorMessage = '';

                    // Reset the validators and clear input
                    const email = this.email;
                    this.$refs.form.reset();

                    // Tell user to check email (server will have sent if email was valid)
                    this.submitted = true;

                    // Goto the next page - replace so can't come back here
                    this.$router.replace({ name: 'userUnsubscribed', params: { email }});
                }
            })
            .catch((error) => {
                // Clear the snackbar
                this.clearSnackBar();

                // Reset submit state
                this.submitted = false;

                // Set the right error message based on the server response
                if (error instanceof NoResponseAPIError ) {
                    this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later';
                } else if (error instanceof UnsupportedMediaAPIError) {
                    this.errorMessage = 'We encountered a server problem, please try again later';
                } else if (error instanceof BadMethodAPIError) {
                    this.errorMessage = 'We encountered a technical problem, please try again later';
                } else if (error instanceof BadRequestAPIError) {
                    this.errorMessage = 'We encountered a problem, please check you entered the correct email address';
                } else if (error instanceof InternalServerAPIError) {
                    this.errorMessage = 'We encountered a server problem, please try again later';
                } else {
                    this.errorMessage = 'We encountered a problem, please try again later';
                }

                // Show snackbar error message
                this.setSnackBar({ snack: this.errorMessage });
            })
            .finally(() => {
                // Reset server side submit state
                this.pending = false;
            });
        },
    },
};
</script>

<style scoped>
h1 {
    margin: 40px 0 0;
}

a {
    color: #42b983;
    cursor: pointer;
}

.form-group--error, .form-group--error a {
  color: red;
}
</style>
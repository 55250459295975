/**
 * Front-end view controller for the view directory member page
 *
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * Note:
 * 
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the admin home page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container v-if="isLoggedIn">
        <v-row align="start" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col cols="12" md="9" align="left">
                <!-- Back Link -->
                <a class="text-left" @click="navigateBack">&lt; Back</a>
            </v-col>
        </v-row>
        
        <v-row align="start" v-if="isLoggedIn && memberDetails">
            <!-- Main LHS content pane -->
            <v-col cols="12" md="9" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'">
                <!-- Main member details content -->
                <v-container fill-height>
                    <v-row>
                        <!-- User Photo -->
                        <v-col cols="12" md="3" v-if="hasProfileImg">
                            <v-avatar tile size="140" v-if="memberDetails.profileImg.large.length">
                                <img class="profile" :src="memberDetails.profileImg.large" />
                            </v-avatar>
                        </v-col>
                        
                        <!-- Name & headline-->
                        <v-col cols="12" :md="hasProfileImg ? 9 : 12">
                            <p class="name text-h5 font-weight-bold">{{memberDetails.firstname}} {{memberDetails.lastname}}</p>
                            <p class="headline text-subtitle-1" v-if="memberDetails.headline">{{memberDetails.headline}}</p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <!-- How I can help -->
                            <h3 class="mb-3">How I can help</h3>
                            <div v-if="memberSkills.length">
                                <v-chip v-for="(chip, index) in memberSkills" :key="index" dark color="blue-grey lighten-4'" label small class="mr-2 mb-2">{{chip.text}}</v-chip>
                            </div>
                            <div v-else>
                                [None set]
                            </div>
                            
                            <!-- Bio -->
                            <h3 class="mb-3">Bio</h3>
                            <div v-bind:class="{ expanded: bioExpanded, expander: hasBioExpander }" v-html="markdown(bio)" @click="bioExpanded = !bioExpanded"></div>
                            <span v-if="hasBioExpander" style="display: inline-block"><a @click="bioExpanded = !bioExpanded">{{bioExpanded ? '&uarr; less...' : '&darr; more...'}}</a></span>

                            <!-- What you're working on -->
                            <h3 class="mb-3">What I'm working on</h3>
                            <div v-bind:class="{ expanded: workingonExpanded, expander: hasWorkingOnExpander }" v-html="markdown(workingon)" @click="workingonExpanded = !workingonExpanded"></div>
                            <span v-if="hasWorkingOnExpander" style="display: inline-block"><a @click="workingonExpanded = !workingonExpanded">{{workingonExpanded ? '&uarr; less...' : '&darr; more...'}}</a></span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>

            <!-- Main RHS info panel -->
            <v-col cols="12" md="3" align="center pa-0">
                <!-- Profile Box -->
                <ProfileBox
                    :id='memberDetails.userid'
                    :firstname='memberDetails.firstname'
                    :lastname='memberDetails.lastname'
                    :profileImgSrc='memberDetails.profileImg.medium ? memberDetails.profileImg.medium : ""'
                    :countryStr='memberDetails.countryName'
                    :countryCode='memberDetails.countryCode'
                    :regionStr='memberDetails.regionName'
                    :clickable="false"
                    level=""
                    v-if="memberDetails" />

                <div class="mt-6">
                    <!-- Edit Profile Button if your own -->
                    <p v-if="isSelf" class="mb-15"><v-btn elevation="2" color="primary" :to="{ name: 'userEditProfile', params: { 'from': 'profile' }}">Edit Profile</v-btn></p>

                    <!-- Ask for help button -->
                    <!--<p v-if="!isSelf"><v-btn elevation="2">Ask for help</v-btn></p>-->

                    <!-- Offer help button -->
                    <!--<p v-if="!isSelf"><v-btn elevation="2">Offer help</v-btn></p>-->

                    <!-- Discord Link -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <p v-if="memberDetails.discord && memberDetails.discord" @click="discordLink" v-bind="attrs" v-on="on">
                                <v-avatar size="30" rounded class="elevation-2 mr-2">
                                    <img :src="require('@/assets/imgs/discord.png')" class="discord" />
                                </v-avatar>
                                <a class="font-weight-bold">{{memberDetails.discord}}</a>
                            </p>
                        </template>
                        <span>Click to open discord, click add friend, and enter {{memberDetails.discord}}</span>
                    </v-tooltip>                        
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mx-3">
                <!-- Tabs -->
                <h3 class="mb-3 text-left">Activity</h3>
                <v-card style="min-height: 250px">
                    <v-tabs background-color="blue-grey lighten-5" color="blue-grey" v-model="tab">
                        <v-tab>Value Exchange</v-tab>
                        <v-tab>Asks/Bounties</v-tab>
                        <v-tab>Resources</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        
                        <!-- Value exchange -->
                        <v-tab-item>
                            <v-card flat>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" :md="6" class="text-left">
                                            <!-- Season filter -->
                                            <v-select
                                                v-model="currentSeason"
                                                :items="seasonListAndAllTimeList"
                                                label="Season"
                                                solo
                                                hide-details="auto"
                                                @change="changeSeason"
                                                v-if="seasons">
                                                <v-icon slot="prepend-inner" class="mr-3">
                                                    mdi-calendar
                                                </v-icon>
                                            </v-select>
                                        </v-col>
                                        <v-spacer />
                                        <v-col cols="12" :md="6" class="text-right">
                                            <!-- Search box -->
                                            <v-form @submit.prevent="getDebouncedValueResults">
                                                <v-text-field :rules="valueSearchRules" ref="valueSearch" v-model="valueSearchString" solo label="Search value..." clearable @focus="valueSearchFocus=true" @blur="valueSearchFocus=false" hide-details="auto">
                                                    <template v-slot:append>
                                                        <v-icon class="ml-2">mdi-magnify</v-icon>
                                                    </template>
                                                    <template v-slot:prepend-inner>
                                                        <v-chip v-if="givenValueSearchFilter" color="indigo lighten-1" text-color="white" class="mr-2" label small close @click:close="clearValueChip('given')"><v-icon left small>mdi-hand-heart-outline</v-icon></v-chip>
                                                        <v-chip v-if="receivedValueSearchFilter" color="teal lighten-1" text-color="white" class="mr-2" label small close @click:close="clearValueChip('received')"><v-icon left small>mdi-hands-pray</v-icon></v-chip>
                                                        <v-chip v-if="confirmedValueSearchFilter" color="green lighten-1" text-color="white" class="mr-2" label small close @click:close="clearValueChip('confirmed')"><v-icon left small>mdi-star</v-icon></v-chip>
                                                        <v-chip v-if="pendingValueSearchFilter" color="amber lighten-1" text-color="white" class="mr-2" label small close @click:close="clearValueChip('pending')"><v-icon left small>mdi-information</v-icon></v-chip>
                                                        <v-chip v-if="disputedValueSearchFilter" color="red lighten-1" text-color="white" class="mr-2" label small close @click:close="clearValueChip('disputed')"><v-icon left small>mdi-alert</v-icon></v-chip>
                                                    </template>
                                                </v-text-field>
                                            </v-form>
                                        </v-col>

                                        <!-- Chips for common searches -->
                                        <v-col cols="12" align="center" class="pt-3 mb-10">
                                            <v-chip color="indigo lighten-1" :outlined="givenValueSearchFilter" label small class="mr-2 mb-2" dark @click="clickValueChip('given')"><v-icon left small v-if="!$vuetify.breakpoint.smAndDown">mdi-hand-heart-outline</v-icon>Value Given</v-chip>
                                            <v-chip color="teal lighten-1" :outlined="receivedValueSearchFilter" label small class="mr-2 mb-2" dark @click="clickValueChip('received')"><v-icon left small v-if="!$vuetify.breakpoint.smAndDown">mdi-hands-pray</v-icon>Value Received</v-chip>
                                            <v-chip color="green lighten-1" :outlined="confirmedValueSearchFilter" label small class="mr-2 mb-2" dark @click="clickValueChip('confirmed')"><v-icon left small v-if="!$vuetify.breakpoint.smAndDown">mdi-star</v-icon>Confirmed</v-chip>
                                            <v-chip color="amber lighten-1" :outlined="pendingValueSearchFilter" label small class="mr-2 mb-2" dark @click="clickValueChip('pending')"><v-icon left small v-if="!$vuetify.breakpoint.smAndDown">mdi-information</v-icon>Pending</v-chip>
                                            <v-chip color="red lighten-1" :outlined="disputedValueSearchFilter" label small dark class="mb-2" @click="clickValueChip('disputed')"><v-icon left small v-if="!$vuetify.breakpoint.smAndDown">mdi-alert</v-icon>Disputed</v-chip>
                                        </v-col>
                                    </v-row>

                                    <!-- Recent Value Created -->
                                    <v-container>
                                        <!-- No search results -->
                                        <v-row align="start" justify="space-between" v-if="!isValueResults">
                                            <v-col cols="12">
                                                <h3 class="text-center mt-4 mb-15">None found</h3>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row align="start" justify="space-between" v-if="isValueResults">
                                            <v-col cols="12" lg="4" md="6" sm="12" v-for="v in valueResults" :key="v.id">
                                                <ValueTransactionBox
                                                    :id="v.id"
                                                    :value="v.value"
                                                    :status="v.status"
                                                    :confirmedValue="v.confirmed_value"
                                                    :type="v.type"
                                                    :fromid="v.fromid"
                                                    :fromFirstname="v.from_firstname"
                                                    :fromLastname="v.from_lastname"
                                                    :fromProfileImgSrc="v.from_profileImg"
                                                    :toid="v.toid"
                                                    :toFirstname="v.to_firstname"
                                                    :toLastname="v.to_lastname"
                                                    :toProfileImgSrc="v.to_profileImg"
                                                    :createdAt="v.createdat_ts"
                                                    @click="clickValue"
                                                ></ValueTransactionBox>                             
                                            </v-col>
                                        </v-row>

                                        <!-- pagination for long result sets -->
                                        <v-row align="start" justify="center" v-if="isValueResults">
                                            <v-col>
                                                <v-pagination
                                                    v-model="valuePage"
                                                    :length="numValuePages"
                                                    :total-visible="7"
                                                    color="blue-grey lighten-4'"
                                                    v-if="numValuePages > 1"
                                                ></v-pagination>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-container>
                            </v-card>
                        </v-tab-item>

                        <!-- Asks/Bounties -->
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <h3 class="text-center mt-4" style="line-height: 132px">None found</h3>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <!-- Resources -->
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <h3 class="text-center mt-4" style="line-height: 132px">None found</h3>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters, mapActions } from 'vuex';

// Shared components used in this page
import ProfileBox from '@/components/ProfileBox';
import ValueTransactionBox from '@/components/ValueTransactionBox';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';

// API Connectors
import ValueExchangeAPI from '@/services/ValueExchangeAPIService.js';

// The main admin home page component
export default {
    name: 'DirectoryMember',

    data() {
        return {
            // Tracker expanded fields state
            bioExpanded: false,
            workingonExpanded: false,

            // Tabs
            tab: null,
            
            // value exchange fields
            currentSeason: null,
            
            valueLoading: false,
            valueSearchFocus: false,
            valueNumPerPage: 10,
            valueSearchRules: [],
            valueDebounceTimer: null,
        };
    },

    // Incoming properties for this component
    props: {
        fetchId: { type: [String, Number], default: null },
    },

    components: {
        ProfileBox,
        ValueTransactionBox,
    },
    
    computed: {
        // Map our Vuex getters
        ...mapGetters({
            isLoggedIn: 'Auth/isLoggedIn',
            userId: 'User/userid',
            member: 'User/user',            // returns (userId) => {...}
            seasons: 'Seasons/seasons',
        }),
        
        /*
         * Member profile related
         */
        memberDetails() {
            return this.member(this.fetchId);
        },

        memberSkills() {
            if (this.memberDetails && this.memberDetails.skills) {
                return this.memberDetails.skills;
            } else {
                return [];
            }
        },

        hasProfileImg() {
            return this.memberDetails && this.memberDetails.profileImg && this.memberDetails.profileImg.large;
        },

        hasBioExpander() {
            return this.memberDetails && this.memberDetails.bio && this.memberDetails.bio.length > 350;
        },

        hasWorkingOnExpander() {
            return this.memberDetails && this.memberDetails.workingon && this.memberDetails.workingon.length > 350;
        },

        bio() {
            return this.memberDetails && this.memberDetails.bio && this.memberDetails.bio.length ? this.memberDetails.bio : '[None]';
        },
        
        workingon() {
            return this.memberDetails && this.memberDetails.workingon && this.memberDetails.workingon.length ? this.memberDetails.workingon : '[Nothing set]';
        },

        isSelf() {
            return Number(this.userId) === Number(this.fetchId);
        },

        /*
         * Season dropdown related
         */

        seasonList() {
            return this.seasons.map(s => {
                const fromDate = new Date(s.fromdate*1000);
                const toDate = new Date(s.todate*1000);

                var options = {
                    month: "2-digit",
                    year: "2-digit"
                };

                let obj = Object.assign({}, s);
                obj.text = 'Season ' + s.season + ' (' + fromDate.toLocaleDateString("en", options) + ' - ' + toDate.toLocaleDateString("en", options) + ')';
                obj.value = s.season;
                return obj;
            });
        },
        
        seasonListAndAllTimeList() {
            return [...this.seasonList, { text: 'All time (all seasons)', value: ''}];
        },

        /*
         * Value Search related
         */

        // Recent saved results - save whether chip is enabled in the searchFilter slot
        valueSearchString: {
            get() { return this.$store.getters['SearchResults/searchString']('directory-member-valueResults'); },
            set(searchString) { this.$store.dispatch('SearchResults/setSearchString', { key: 'directory-member-valueResults', searchString }); }
        },
        
        valueSearchFilter: {
            get() { return this.$store.getters['SearchResults/searchFilter']('directory-member-valueResults'); },
            set(searchFilter) { this.$store.dispatch('SearchResults/setSearchFilter', { key: 'directory-member-valueResults', searchFilter }); }
        },

        valueResults: {
            get() { return this.$store.getters['SearchResults/searchResults']('directory-member-valueResults'); },
            set(searchResults) { this.$store.dispatch('SearchResults/setSearchResults', { key: 'directory-member-valueResults', searchResults }); }
        },

        valuePage: {
            get() { return this.$store.getters['SearchResults/searchPage']('directory-member-valueResults'); },
            set(page) { this.$store.dispatch('SearchResults/setSearchPage', { key: 'directory-member-valueResults', page }); }
        },

        valueTotalResults: {
            get() { return this.$store.getters['SearchResults/searchTotalResults']('directory-member-valueResults'); },
            set(totalResults) { this.$store.dispatch('SearchResults/setSearchTotalResults', { key: 'directory-member-valueResults', totalResults }); }
        },

        isValueResults() {
            return this.valueResults && this.valueResults.length;
        },

        numValuePages() {
            return !this.valueTotalResults ? 0 : Math.ceil(this.valueTotalResults / this.valueNumPerPage);
        },

        givenValueSearchFilter() {
            return this.valueSearchFilter && this.valueSearchFilter.given ? this.valueSearchFilter.given : null;
        },
        
        receivedValueSearchFilter() {
            return this.valueSearchFilter && this.valueSearchFilter.received ? this.valueSearchFilter.received : null;
        },
        
        confirmedValueSearchFilter() {
            return this.valueSearchFilter && this.valueSearchFilter.confirmed ? this.valueSearchFilter.confirmed : null;
        },

        disputedValueSearchFilter() {
            return this.valueSearchFilter && this.valueSearchFilter.disputed ? this.valueSearchFilter.disputed : null;
        },

        pendingValueSearchFilter() {
            return this.valueSearchFilter && this.valueSearchFilter.pending ? this.valueSearchFilter.pending : null;
        },

        hasFilter() {
            return this.givenValueSearchFilter || this.receivedValueSearchFilter || this.confirmedValueSearchFilter || this.disputedValueSearchFilter || this.pendingValueSearchFilter;
        },
    },

    watch: {
        // whenever login state changes and we're no longer logged in, we redirect to login page
        isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
            if (!newIsLoggedIn && oldIsLoggedIn) {
                this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
            }
        },

        // Issue new search on search string changing
        valueSearchString(value, old) {
            // If the value has changed, we reset the page number
            if (value !== old && old) {
                this.valuePage = 1;

                // If we just cleared the value, we reissue the search
                if (!value) {
                    this.getValueResults(this.valuePage);    
                }
            }

            // Nothing to search
            if (!value) return;

            // Reset any validation rules so we can do on submit
            this.valueSearchRules =  [];
            this.getDebouncedValueResults(this.valuePage);
        },
        
        valuePage(pageNum, oldPageNum) {
            // Get the recent search results with the new page number
            if (oldPageNum > 0) {
                this.getValueResults(pageNum);
            }
        },
    },

    // If we're not navigating to another directory page, we clear the searchResults cache
    beforeRouteLeave(to,from,next) {
        const validRouteNames = ['directoryHome', 'directoryMember'];
        const validValueRouteNames = ['valueView'];
        if (!to.name || validRouteNames.indexOf(to.name) === -1) {
            this.$store.dispatch('SearchResults/clearSearch', { key: 'directory-searchResults' });
            this.$store.dispatch('SearchResults/clearSearch', { key: 'directory-nearbyResults' });
            this.$store.dispatch('SearchResults/clearSearch', { key: 'directory-recentResults' });
        }
        
        // Clear value search results only if we're not going to a directory page or the view value transaction page
        if (!to.name || validValueRouteNames.indexOf(to.name) === -1) {
            this.$store.dispatch('SearchResults/clearSearch', { key: 'directory-member-valueResults' });
        }
        next();
    },

    created() {
        // The user details
        this.getUserDetails();
        
        // Get list of seasons
        this.getSeasonList();
    },
    
    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
            getSeasons: 'Seasons/getSeasons',
        }),

        navigateBack() {
            this.$router.go(-1);
        },

        markdown(content, is_xhtml=true) {
            // Replace new lines with paragraphs
            if (typeof content === 'undefined' || content === null) {
                return '';
            }

            var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
            content = (content + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');

            // Replace any markdown links with html links
            return content.replace(/\[([^[\]]*)\]\((.*?)\)/gm, '<a href="$2" target="_blank">$1</a>');
        },

        discordLink() {
            if (this.memberDetails.discord) {
                window.open('https://discord.com/channels/@me');
            }
        },

        changeSeason(season) {
            // Change the season
            this.currentSeason = season;

            // Reset original search results back to page 1
            if (this.valuePage !== 1) {
                this.valuePage = 1;
            }
                
            // If we were searching, reissue the search at page 1 now with the new season
            if (this.valueSearchString) {
                // Refresh the search results
                this.getDebouncedValueResults(this.valuePage);
            } else {
                // Just filter the search results without validating search string
                this.getValueResults(this.valuePage);
            }
        },

        clickValueChip(filter) {
            // Only action if not already selected
            if (!this.valueSearchFilter || !this.valueSearchFilter[filter]) {
                // Enable search filter - clear out all old filters
                let searchFilter = {};
                searchFilter[filter] = true;
                this.valueSearchFilter = searchFilter;
                
                // Reset original search results back to page 1
                if (this.valuePage !== 1) {
                    this.valuePage = 1;
                }
                
                // If we were searching, reissue the search at page 1 now with the new chip
                if (this.valueSearchString) {
                    // Refresh the search results
                    this.getDebouncedValueResults(this.valuePage);
                } else {
                    // Just filter the search results without validating search string
                    this.getValueResults(this.valuePage);
                }
            } else {
                // Turn off the filter
                this.clearValueChip(filter);
            }
        },

        clearValueChip(filter) {
            // Reset recent filter (all filters)
            let searchFilter = {};
            searchFilter[filter] = false;
            this.valueSearchFilter = searchFilter;

            // Reset recent page & results back to first (or if we were searching)
            if (this.valuePage !== 1) {
                this.valuePage = 1;
            }

            // Refresh the list to get latest results
            this.getValueResults(this.valuePage);
        },

        clickValue(value) {
            const id = Number(value.id);
            if (id !== Number(this.curValueId)) {
                this.$router.push({ name: 'valueView', params: { valueId: id }});
            }
        },
        
        async getUserDetails() {
            // Fetch the user details
            this.$store.dispatch('User/fetchUser', { userId: this.userId, fetchId: this.fetchId })
            .catch(() => {
                // Clear the snackbar
                this.clearSnackBar();

                // Show snackbar error message
                this.setSnackBar({ snack: 'There was a problem fetching this member\'s details - please try again' });
            });
        },

        getSeasonList() {
            // Show loading indicator
            this.valueLoading = true;
                        
            // Get season list from the season store
			this.getSeasons({ userId: this.userId })
			.then((seasons) => {
				if (!seasons) {
					throw new Error('There was a problem fetching the value transactions, please try again later');
				}

                // Stash current season
                const season = seasons ? seasons.find(s => Number(s.current) === 1) : null;
                this.currentSeason = season && season.season ? season.season : null;

                // Get the list of recent value exchange transactions for the current season
                this.getValueResults();
            })
            .catch( (error) => {
                // Clear the snackbar
                this.clearSnackBar();
                
                // Error message to show user
                let errorMessage = '';
                
                if (error instanceof NoResponseAPIError ) {
                    errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                } else if (error instanceof BadMethodAPIError) {
                    errorMessage = 'We encountered a technical problem, please try again later';
                } else if (error instanceof BadRequestAPIError) {
                    errorMessage = 'We encountered a problem, please try again later';
                } else if (error instanceof CredentialsRevokedAPIError) {
                    this.errorMessage = 'You are not allowed to access this data, please contact us for support';
                } else if (error instanceof AuthenticationAPIError) {
                    errorMessage = 'We encountered an authentication problem, please logout and try again';
                } else if (error instanceof InternalServerAPIError) {
                    errorMessage = 'We encountered a server problem, please try again later';
                } else {
                    errorMessage = 'There was a problem, please try again later';
                }

                // Show snackbar error message
                this.setSnackBar({ snack: errorMessage });
            }).finally(() => this.valueLoading = false);
        },

        // Delay the search function call by 500ms, to debounce API endpoint
        getDebouncedValueResults(pageNum) {
            // If called as submit event, pageNum is the event object, so just reset to first page
            if (typeof pageNum !== 'number') {
                pageNum = 1;    
            }

            // Cancel any pending calls
            if (this.valueDebounceTimer) {
                clearTimeout(this.valueDebounceTimer);
            }

            // Delay the search call 500ms
            this.valueDebounceTimer = setTimeout(() => {
                // Validate the search form before we search
                this.valueSearchRules = [
                    (v) => {
                        if (!v) {
                            return true;
                        } else {
                            return v.length && v.length >= 4 || 'Please enter at least 4 characters';
                        }
                    }
                ];
            
                // Have to do on nextTick to ensure search rules are correctly applied
                this.$nextTick(() => {
                    // Only search if valid
                    if (this.$refs.valueSearch && this.$refs.valueSearch.validate() && this.valueSearchString) {
                        this.getValueResults(pageNum)
                    }
                });
            }, 500);            
        },

        // Get value exchange search results from the API endpoint
        getValueResults(pageNum) {
            this.valueLoading = true;
            
            // Request the first page unless another page number is set
            let page = pageNum;
            if (!pageNum) {
                page = this.valuePage ? this.valuePage : 1;
            }

            // Get which filters are enabled
            const confirmed = this.valueSearchFilter && this.valueSearchFilter.confirmed ? this.valueSearchFilter.confirmed : null;
            const pending = this.valueSearchFilter && this.valueSearchFilter.pending ? this.valueSearchFilter.pending : null;
            const disputed = this.valueSearchFilter && this.valueSearchFilter.disputed ? this.valueSearchFilter.disputed : null;
            const given = this.valueSearchFilter && this.valueSearchFilter.given ? this.valueSearchFilter.given : null;
            const received = this.valueSearchFilter && this.valueSearchFilter.received ? this.valueSearchFilter.received : null;

            // Search via the API endpoint - filter by the fetched user id and the additional given/received fields
            ValueExchangeAPI.recent(this.userId, page, this.valueSearchString, confirmed, pending, disputed, given, received, this.fetchId, this.currentSeason)
            .then( (response) => {
                // Check our expected data is in the response
                if (!response.recent) {
                    throw new Error('There was a problem getting the value exchange transactions, please try again later');
                }

                // Set our expected nearby member results in scope
                this.valueResults = response.recent.items ? response.recent.items : [];
                this.valueTotalResults = response.recent.total ? response.recent.total : 0;
                if (this.valueTotalResults) {
                    this.valuePage = response.recent.page ? response.recent.page : 1;
                } else {
                    this.valuePage = 0;
                }
            })
            .catch( (error) => {
                // Clear the snackbar
                this.clearSnackBar();

                // Error message to show user
                let errorMessage = '';

                if (error instanceof NoResponseAPIError ) {
                    errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                } else if (error instanceof BadMethodAPIError) {
                    errorMessage = 'We encountered a technical problem getting the value exchange transactions, please try again later';
                } else if (error instanceof BadRequestAPIError) {
                    errorMessage = 'We encountered a problem getting the value exchange transactions, please try again later';
                } else if (error instanceof CredentialsRevokedAPIError) {
                    this.errorMessage = 'You are not allowed to access this data, please contact us for support';
                } else if (error instanceof AuthenticationAPIError) {
                    errorMessage = 'We encountered an authentication problem, please logout and try again';
                } else if (error instanceof InternalServerAPIError) {
                    errorMessage = 'We encountered a server problem getting the value exchange transactions, please try again later';
                } else {
                    errorMessage = 'There was a problem getting the value exchange transactions, please try again later';
                }

                // Show snackbar error message
                this.setSnackBar({ snack: errorMessage });
            }).finally(() => this.valueLoading = false);
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}

.help-buttons {
    margin-top: 50px;
}

.v-avatar img.profile {
    border-radius: 12px;
}

.v-avatar img.discord {
    cursor: pointer;
}

.expander {
    transition: max-height 0.25s ease-out;
    transition-property: max-height;
    max-height: 150px;
    overflow: hidden;
    cursor: pointer;
}
.expanded {
    max-height: 1000px;
}
</style>

/**
 * Front-end view controller for the register confirm page
 *
 * Render's the page template, and processes any UI functions and interactivity
 *
 * @file   Front-end view controller for the register confirm page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
<template>
    <v-container>
        <v-row align="start" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col>
                <!-- Title -->
                <h1>Check Your Email!</h1>

                <!-- Blurb -->
                <p>We've sent a six-digit confirmation code to <strong>{{ email }}</strong>.</p>
                <p>It will expire shortly, so please check your email and enter it soon.</p>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col cols="5">
                <!-- Code Confirmation Form -->
                <v-form ref="verifyform" @submit.prevent autocomplete="off" style="position: relative;">
                    <!-- The one-time password field -->
                    <v-otp-input v-model="otp" :disabled="pending" @finish="submit" />
                    
                    <!-- Loading overlay -->
                    <v-overlay absolute :value="pending">
                        <v-progress-circular indeterminate color="primary" />
                    </v-overlay>
                </v-form>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <!-- Check your spam folder or contact us message -->
                <p>If you did not receive a confirmation email, please check your spam folder, or <a :href="getContactLink" target="_blank">contact us</a> for help.</p>

                <a @click="$router.back()">Go Back</a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapActions } from 'vuex';

// API Connectors
import UserAPI from '@/services/UserAPIService.js';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import ExpiredTokenAPIError from '@/errors/expiredtokenapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import AlreadyActionedAPIError from '@/errors/alreadyactionedapierror';
import InvalidTokenAPIError from '@/errors/invalidtokenapierror';
import NotExistsAPIError from '@/errors/notexistsapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';

// The register page component
export default {
    name: 'RegisterConfirm',

    // URL parameters passed as properties /:email
    props: [
        'email'
    ],

    data() {
        return {
            // The form fields
            otp: '',
            otpVerified: false,

            // Note in UI when waiting for response
            pending: false,           
            
            // Track any errors
            errorMessage: '',
        };
    },
  
    // Computed params
    computed: {
        getContactLink() {
            return 'mailto:' + process.env.VUE_APP_CONTACT_EMAIL + '?subject=Registration%20Sign%20Up%20Problem';
        },
    },

    // Check the email address is defined otherwise go back to register page to generate a new code
    beforeMount() {
        if (!this.email || !this.email.length) {
            this.$router.push({ name: 'register' });
        }
    },

    mounted() {
        // Set focus on first form field
        document.getElementsByClassName('otp-field-box--0')[0].focus();
    },

    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
        }),
        
        // Submit form - validates entered code via the API
        async submit() {
            // Check we've got the right length
            if (this.otp.length !== 6) {
                this.errorMessage = 'Hmm, that doesn\'t look right, please try again.';
                
                // Focus back on first field
                document.getElementsByClassName('otp-field-box--0')[0].focus();
                return
            } else {
                this.otpVerified = false;
            }

            // Clear the snackbar
            this.clearSnackBar();
                
            // Let UI now we're submitting
            this.pending = true;

            // If got here, valid form fields, build submit data
            const submitData = {
                email: this.email,
                code: this.otp,
            };

            // Verify the code via the API
            UserAPI.confirmEmail(submitData)
            .then((response) => {
                // If the server was unreachable or timedout, the request is cancelled and goes into the then handler so trap this as a NoResponseAPIError
                if (!response || !response.message) {
                    throw new NoResponseAPIError();
                }

                // Set the code as confirmed
                if (response.reason && response.reason === 'SUCCESS') {
                    // this.form.codeVerified = true;
                    this.otpVerified = true;
                    this.errorMessage = '';

                    // Reset form dirty state
                    this.$refs.verifyform.reset();

                    // Goto the next page - replace so can't come back here
                    this.$router.replace({ name: 'login', params: { from: 'register' }});
                }
            })
            .catch((error) => {
                // Set the right error message based on the server response
                if (error instanceof NoResponseAPIError ) {
                    this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                } else if (error instanceof UnsupportedMediaAPIError) {
                    this.errorMessage = 'We encountered a server problem confirming your email, please try again later.';
                } else if (error instanceof BadMethodAPIError) {
                    this.errorMessage = 'We encountered a technical problem fetching your details, please try again later.';
                } else if (error instanceof BadRequestAPIError || (error instanceof InvalidTokenAPIError)) {
                    this.errorMessage = 'Hmm, that doesn\'t look right, please try again.';
                } else if (error instanceof ExpiredTokenAPIError) {
                    this.errorMessage = 'This confirmation code has expired, we\'ve sent you a new one, please check your email.';
                } else if (error instanceof NotExistsAPIError) {
                    const registerRoute = this.$router.getRoutes().find(x => x.name === 'register').path;
                    this.errorMessage = 'This email has not yet registered - please <a href="'+registerRoute+'">register</a> to continue.';
                } else if (error instanceof InternalServerAPIError) {
                    this.errorMessage = 'We encountered a server problem confirming your email, please try again later.';
                } else if (error instanceof AlreadyActionedAPIError) {
                    // Already verified, goto login - replace so can't come back here
                    this.$router.replace({ name: 'login' });
                }
                else {
                    this.errorMessage = 'We encountered a server problem confirming your email, please try again later.';
                }

                // Set code as invalid and force validation to show message
                this.otpVerified = false;
                
                // Reset the form validators and field values
                this.$refs.verifyform.reset();

                // Reset focus back onto first field
                document.getElementsByClassName('otp-field-box--0')[0].focus();

                // Show snackbar error message
                this.setSnackBar({ snack: this.errorMessage });
            })
            .finally(() => {
                this.pending = false;
            })
        },
    },
};
</script>

<style scoped>
h1 {
    margin: 40px 0 0;
}

a {
    color: #42b983;
    cursor: pointer;
}

.form-group--error, .form-group--error a {
    color: red;
}

.form-control {
    font-size: 1.5em;
    width: 1.5em;
    text-align: center;
    padding: 0.25em;
}

.message {
    padding: 1em 0 0 0;
}
</style>
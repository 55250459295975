/**
 * Vuex storage for season-related data storage
 *
 * Corresponding getters, mutators, and actions for modifying this local data store
 * 
 * @file   Vuex data store for season data
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// API Connectors
import ValueExchangeAPI from '@/services/ValueExchangeAPIService.js';

// We use this store for season data that has been fetched, storing a timestamp and caching data for a preset amount of time
const SEASON_CACHE_SECONDS = 1 * 60 * 60 * 24;          // 1 day

// Setup the default state
const getDefaultState = () => {
    return {
        // Seasons list
        seasons: null,
        lastFetchedTs: null,
    };
};

// Setup the store mutations
const mutations = {
    SET_SEASONS: (state, seasons) => {
        state.seasons = seasons;
    },

    SET_LASTFETCHEDTS: (state, lastFetchedTs) => {
        state.lastFetchedTs = lastFetchedTs;
    },

    RESET: state => {
        // Reset the state
        Object.assign(state, getDefaultState());
    },
};

// Setup our getters
const getters = {
    seasons: state => {
        return state.seasons ? state.seasons : [];
    },

    currentSeason: state => {
        return state.seasons && state.seasons.findIndex(s => Number(s.current) === 1) !== -1 ? state.seasons.find(s => Number(s.current) === 1) : null;
    },

    lastFetchedTs: state => {
        return state.lastFetchedTs;
    },
};

// Setup our actions
const actions = {
    /*
     * function SetSeasons()
     *
     * Set the state for a season list
     */
    SET_SEASONS: ({ commit }, { seasons }) => {
        commit('SET_SEASONS', seasons);
    },

    getSeasons: ({ commit, getters }, { userId }) => {
        return new Promise((resolve, reject) => {
            // Check if the season list cache is still valid
            const seasons = getters.seasons;
            let fetch = true;
            if (seasons) {
                // Get details
                const fetchTime = getters.lastFetchedTs ? getters.lastFetchedTs : null;
                const now = Date.now() / 1000;
                const expireTime = fetchTime + SEASON_CACHE_SECONDS;

                // If expired, used cached details
                if (expireTime > now) {
                    fetch = false;
                }
            }

            // Fetch user details from the API and cache results
            if (fetch) {
                ValueExchangeAPI.getSeasons(userId)
                .then( (response) => {
                    if (!response || !response.seasons) {
                        throw ('Problem fetching seasons');
                    }

                    // Cache the details and save the fetch time
                    commit('SET_SEASONS', response.seasons);
                    commit('SET_LASTFETCHEDTS', Date.now() / 1000);

                    return resolve(response.seasons);
                })
                .catch((e) => {
                    return reject(e);
                });
            } else {
                return resolve(seasons);
            }
        });
    }
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations,
    getters,
    actions,
};
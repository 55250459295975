/**
 * Side navigation component
 *
 * This renders the side navigation links and shows which item is currently selected
 * 
 * Note:
 * 
 * @props
 *      current (string): the currently selected route
 *
 * @file   Side navigation component
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <div>
        <!-- Add an app-bar for mobile layouts -->
        <v-app-bar app clipped-left v-if="!permanent && showNav">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-toolbar-title>+Σ - Positive Sum - Platform ⚙️</v-toolbar-title>
        </v-app-bar>

        <!-- Nav drawer for menu options -->
        <v-navigation-drawer v-model="drawer" :permanent="permanent" :expand-on-hover="collapsed" app class="pt-4" color="grey lighten-3" :mini-variant="collapsed" ref="navDrawer" @transitionend="onNavBarTransition" v-if="isLoggedIn && showNav">
            <!-- The Logged In User Avatar -->
            <a @click="viewProfile" style="text-decoration: none; color: inherit;">
                <v-avatar v-if="userProfileImageUrl" color="grey darken-1" size=40 class="text-center">
                    <img :src="userProfileImageUrl" :alt="userFullName">
                </v-avatar>
                
                <!-- Show initials if no avatar image -->
                <v-avatar v-else color="grey darken-1" size=40 class="text-center">
                    <span class="avatar-initials white--text text-h6">{{userInitials}}</span>
                </v-avatar>
            </a>
            
            <!-- Show full name if expanded -->
            <v-list class="pb-0">
                <v-list-item @click="viewProfile" color="indigo">
                    <v-list-item-content>
                        <v-list-item-title class="text-h6" v-if="isMouseover || (!isMouseover && !collapsed)">{{userFullName}}</v-list-item-title>
                        <v-list-item-title class="text-h6" v-else>&nbsp;</v-list-item-title>
                        <v-list-item-subtitle v-if="isMouseover || (!isMouseover && !collapsed)">{{userEmail}}</v-list-item-subtitle>
                        <v-list-item-subtitle v-else>&nbsp;</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            
            <!-- Icons for application links -->
            <v-list>
                <v-list-item-group color="indigo">
                    <v-list-item exact v-for="(item, i) in items" :key="i" :to="item.route ? { name: item.route } : null" :disabled="item.divider" @click="openUrl(item.url)">
                        <v-list-item-icon v-if="!item.divider && item.icon">
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        
                        <v-list-item-content v-if="!item.divider">
                            <v-list-item-title class="text-left" v-text="item.text"></v-list-item-title>
                        </v-list-item-content>

                        <v-divider v-if="item.divider" />
                    </v-list-item>

                    <!-- Add the discord button to the end of the list -->
                    <v-list-item exact @click="openUrl('https://discord.gg/3dhddwGSMf')">
                        <v-list-item-icon>
                            <img :src="require('@/assets/imgs/nav/discord.png')" height='25'>
                        </v-list-item-icon>
                        
                        <v-list-item-content>
                            <v-list-item-title class="text-left">Discord</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <!-- template for the logout button in the append slot -->
            <template v-slot:append>
                <v-dialog v-model="dialog" max-width="400" :style="{ zIndex: 200 }" @keydown.esc="cancel">
                    <template v-slot:activator="{ on, attrs }">
                        <v-list>
                            <v-divider class="mb-3" />
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-icon>
                                    <v-icon>{{logoutLink}}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="text-left">Logout</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </template>
                    <v-card>
                        <v-toolbar dark color="grey lighten-3" dense flat>
                            <v-toolbar-title class="text-body-2 font-weight-bold grey--text">Confirm</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pa-4 black--text">Are you sure you wish to logout?</v-card-text>
                        <v-card-actions class="pt-3">
                            <v-spacer></v-spacer>
                            <v-btn color="grey" text class="body-2 font-weight-bold" @click.native="dialog = false">Cancel</v-btn>
                            <v-btn color="primary" class="body-2 font-weight-bold" outlined @click.native="onLogout">OK</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>        
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

// The icons we're using on this page
import { mdiHome, mdiAccountMultiple, mdiSwapHorizontal, mdiCalendarMonth, mdiLibrary, mdiAccountQuestion, mdiLogoutVariant, mdiTextBoxMultiple } from '@mdi/js';

// The App page component
export default {
    name: 'SideNavigation',

    // Take details about the user in
    props: [
        // User details for the avatar icon
        'userFullName',
        'userInitials',
        'userEmail',
        'userProfileImageUrl',

        // Current route so we can show what's selected
        'currentRoute',
    ],

    data() {
        return {
            // Visibility
            drawer: null,

            // The application links to render
            items: [
                {
                    icon: mdiHome,
                    text: 'Dashboard',
                    route: 'dashboard',
                },
                {
                    divider: true,
                },
                {
                    icon: mdiAccountMultiple,
                    text: 'Member Directory',
                    route: 'directoryHome',
                },
                {
                    icon: mdiSwapHorizontal,
                    text: 'Value Exchange',
                    route: 'valueHome',
                },
                {
                    icon: mdiCalendarMonth,
                    text: 'Community Calendar',
                    route: 'calendarHome',
                },
                {
                    icon: mdiLibrary,
                    text: 'Resource Library',
                    route: 'libraryHome',
                },
                {
                    icon: mdiAccountQuestion,
                    text: 'Asks & Bounties',
                    route: 'bountiesHome',
                },
                {
                    divider: true,
                },
                {
                    icon: mdiTextBoxMultiple,
                    text: 'DAO Docs / Wiki',
                    url: 'https://positivesumdao.notion.site/',
                },
            ],

            // Keep track of whether the mouse is over the nav drawer so we can show the full name
            isMouseover: false,

            // Whether the logout dialog is shown
            dialog: false,

            // icon used for logout link
            logoutLink: mdiLogoutVariant,

            // Is menu expanding on hover?
            collapse: true,
        };
    },
    
    computed: {
        // Map our Vuex getters
        ...mapGetters({
            userId: 'User/userid',
            firstname: 'User/firstname',
            lastname: 'User/lastname',
            email: 'User/email',
            isLoggedIn: 'Auth/isLoggedIn',
        }),

        getUserProfileImageUrl() {
            // Build the reference to the uloaded image
            return this.userProfileImageUrl;
        },

        collapsed() {
            return this.permanent && this.collapse;
        },

        permanent() {
            return !this.$vuetify.breakpoint.smAndDown;
        },

        showNav() {
            return (this.$route.meta && this.$route.meta.requiresAuth) || (this.isLoggedIn && (this.$route.name !== 'home' || this.$route.name !== 'homeAbout'));
        }
    },
    
    beforeRouteLeave () {
        // Reset state
        this.collapse = false;
    },
    
    methods: {
        // If the nav drawer is open, set the full name and hide the email
        onNavBarTransition() {
            this.isMouseover = this.$refs.navDrawer.isMouseover;
        },

        // Logout the user
        onLogout() {
            this.dialog = false;
            this.$router.push({ name: 'logout'});
        },

        openUrl(url) {
            if (url) {
                window.open(url);
            }
        },

        viewProfile() {
            if (this.$route.name !== 'directoryMember' || (this.$route.params && this.$route.params.fetchId && Number(this.$route.params.fetchId) !== Number(this.userId))) {
                this.$router.push( { name: 'directoryMember', params: { fetchId: this.userId }});
            }
        },
    }
}
</script>

<style scoped>
</style>

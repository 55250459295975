/**
 * Region Select component
 *
 * This renders a region select drop down field
 * 
 * Note:
 * Use in tandem with country select, by passing in the country object returned from the country select box
 * 
 * import CountrySelect from '@/components/CountrySelect';
 * import RegionSelect from '@/components/RegionSelect';
 *
 * Template:
 *      <CountrySelect v-model="country" placeholder="Choose your country" />
 *      <RegionSelect v-model="region" :country="country" placeholder="Choose your region" />
 *
 * Component:
 *
 *      components: { CountrySelect, RegionSelect },
 *
 *      data() {
 *          return {
 *              country: {},
 *              region: {},
 *          }
 *      },
 *
 * @file   Region Select component
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
<template>
    <v-select v-bind="{ ...$props, ...commonAttrs }" v-on="$listeners">
        <template v-slot:prepend-inner> 
            <img v-if="countryIcon" :src="`data:image/png;base64,${countryIcon}`" class="flag" />
        </template>
        <template #label><span class="red--text" v-if="$attrs.required"><strong>* </strong></span>{{label}}</template>
    </v-select>
</template>

<script>
// Import vuetify select component to subclass
import { VSelect } from 'vuetify/lib'

// Import the countries data in JSON format
import allCountries from 'country-region-data/data.json';

// Import the flag data in JSON format
import { countries as flags } from '@/data/countries.js';

/*
 * Note: country data is in the following format:
 * 
 * [
 * {
 *   "countryName":"Ecuador",
 *   "countryShortCode":"EC",
 *   "regions":[
 *     {
 *       "name":"Azuay",
 *       "shortCode":"A"
 *     },
 *     ...
 *   ]
 * },
 * ... 
 * ]
 * 
 * Flag data is in following format:
 * 
 * [
 * {
 *    country: 'Albania',
 *    iso2: 'al',
 *    flag: '[data]...';
 * }
 * ...
 * ]
 */

// The countries component
export default {
    
    name: "RegionSelect",
    
    extends: VSelect,
    
    props: {
        country: Object,
    },

    data() {
        return {
            regions: [],
        }
    },
    
    computed: {
        countryIcon() {
            if (!this.country || !this.country.countryShortCode) return null;

            // Lookup the current selected country in the flag array and return the base64 image
            const found = flags.find(f => f.iso2 === this.country.countryShortCode.toLowerCase());
            return found && found.flag ? found.flag : null;
        },
        
        commonAttrs() {
            return {
                items: this.regions,
                itemText: 'name',
                returnObject: true,
                autocomplete: 'off',
            }
        }
    },

    // Watch country changes and sync the region list
    watch: {
      country(newCountry, oldCountry) {
        // Let parent know we changed to force revalidation
        if (oldCountry !== '') {
            this.$emit('input', {});
        }

        // Look up this country
        if (this.country) {
            this.getRegionsForCountry()
        } else {
            this.regions = []
        }
      }
    },
    
    mounted() {
        // Load the initial regions
        if (this.country) {
            this.getRegionsForCountry();
        }
    },

    methods: {
        getRegionsForCountry() {
            const country = allCountries.find(c => c.countryName === this.country.countryName);
            this.regions = country ? country.regions : null;
        }
    },
};
</script>

<style scoped>
.flag {
    width: 30px;
    margin-right: 10px;
}
</style>
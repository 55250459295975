/**
 * Front-end view controller for the register/signup page
 *
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * @file   Front-end view controller for the register page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
<template>
    <v-container>
        <v-row align="start" justify="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col cols="8">
                <!-- Title-->
                <h1>Register</h1>

                <!-- Blurb -->
                <p>Enter your details below to setup a new account</p>

                <!-- Register form -->
                <v-form ref="form" v-model="valid" @submit.prevent="submit" autocomplete="off" style="margin-top: 60px;">

                    <!-- First name field -->
                    <v-text-field v-model="firstName" :rules="firstNameRules" required filled>
                        <template #label><span class="red--text"><strong>* </strong></span>Your first name</template>
                    </v-text-field>
          
                    <!-- Last name field -->
                    <v-text-field v-model="lastName" :rules="lastNameRules" required filled>
                        <template #label><span class="red--text"><strong>* </strong></span>Your last name</template>
                    </v-text-field>
                    
                    <!-- Email field -->
                    <v-text-field v-model="email" :rules="emailRules" required filled>
                        <template #label><span class="red--text"><strong>* </strong></span>Your email address</template>
                    </v-text-field>

                    <!-- Password field -->
                    <StrongPassword v-model="password" @score="saveScore" @feedback="showFeedback" @input="$refs.confirmField.validate()" placeholder="* Choose a password">
                        <template v-for="(_, slotName) in $scopedSlots" v-slot:[slotName]="slotData">
                            <slot :name="slotName" v-bind="slotData" />
                        </template>
                    </StrongPassword>
                    
                    <!-- Password Hints -->
                    <div class="passwordHints" v-if="showHintsLink">
                        <p><a @click="toggleHints()">stronger password tips &rarr;</a></p>
                        <div v-if="showHints">
                            <div v-for="(hint, index) in this.passwordHints" :key="`hint-${index}`" :class="{'hint-warning': hint.type==='warning', 'hint-suggestion': hint.type==='suggestion' }">
                                {{hint.msg}}
                            </div>
                        </div>
                    </div>

                    <!-- Confirm Password field -->
                    <v-text-field ref="confirmField" v-model="passwordConfirm" type="password" :rules="passwordConfirmRules" required filled>
                        <template #label><span class="red--text"><strong>* </strong></span>Confirm password</template>
                    </v-text-field>

                    <p></p>
                    
                    <!-- Submit button -->
                    <v-btn type="submit" :disabled="!valid" :loading="pending">Register</v-btn>

                    <br />
                    <br />

                    <!-- Login links -->
                    <div id="loginlinks">
                        <router-link :to="{name: 'login'}">Already registered? Login now</router-link><br />
                    </div>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapActions } from 'vuex';

// User API Functions
import UserAPIService from '@/services/UserAPIService';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import AlreadyActionedAPIError from '@/errors/alreadyactionedapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import ExpiredTokenAPIError from '@/errors/expiredtokenapierror';
import NotExistsAPIError from '@/errors/notexistsapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';

// use our custom Strong Password component
import StrongPassword from '@/components/StrongPassword';

// The register page component
export default {
    name: 'UserRegister',
  
    data() {
        return {
            // Form fields
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
      
            // Validation rules
            firstNameRules: [
                v => !!v || 'First name is required',
                v => v && v.length && v.length < 191 || 'First name must be less than 191 characters',
            ],
            
            lastNameRules: [
                v => !!v || 'First name is required',
                v => v && v.length && v.length < 191 || 'First name must be less than 191 characters',
            ],
            
            emailRules: [
                v => !!v || 'Email address is required',
                v => /.+@.+/.test(v) || 'Email address must be valid',
            ],

            passwordRules: [
                v => !!v || 'Password is required',
                v => v && v.length && v.length < 191 || 'Password must be less than 191 characters',
                v => v && v.length && v.length > 6 || 'Password must be at least 7 characters',
                () => this.score < 1 || 'Please choose a stronger password'
            ],
            
            // Form submit state
            valid: false,
            submitted: false,
            pending: false,
            
            // Error Message
            errorMessage: '',

            // Password strength fields
            passwordHints: '',
            score: 0,
            showHints: false,
        };
    },

    // Our password validation component
    components: { StrongPassword },

    // Computed params
    computed: {
        showHintsLink() {
            return this.password && this.password.length > 0 && this.score <= 1 && this.passwordHints.length > 0;
        },

        passwordConfirmRules() {
            return [
                (v) => !!v || 'Password confirmation is required',
                (v) => (v === this.password) || 'Passwords do not match',
            ];
        },
    },

    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
        }),
        
        // Show secure password tips
        toggleHints() {
            this.showHints = !this.showHints;
        },
    
        // Save the password score for the validator
        saveScore(score) {
            this.score = score;
        },

        // Show password help/suggestions
        showFeedback({ suggestions, warning }) {
            // Put any strings into an array
            let aWarnings = [];
            let aSuggestions = [];
            if (warning && typeof warning === 'string') {
                aWarnings.push(warning);
            } else {
                aWarnings = warning;
            }

            if (suggestions && typeof suggestions === 'string') {
                aSuggestions.push(suggestions);
            } else {
                aSuggestions = suggestions;
            }

            // Reset the hints
            const passwordHints = [];

            // Add all hints into the component
            if (aWarnings.length) {
                aWarnings.forEach((w) => {
                if (w) {
                    passwordHints.push({ type: 'warning', msg: w });
                }
                });
            }
            if (aSuggestions.length) {
                aSuggestions.forEach((s) => {
                if (s) {
                    passwordHints.push({ type: 'suggestion', msg: s });
                }
                });
            }
            this.passwordHints = passwordHints;
        },
        
        // Function to execute a user-initiated register action
        async submit() {
            // Cancel submit if form invalid
            if (!this.$refs.form.validate()) {
                return;
            }

            // Clear the snackbar
            this.clearSnackBar();
                
            // Process a valid form submit
            this.pending = true;
            
            // Build an array of form values to submit
            let submitData = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                password: this.password,
            };

            UserAPIService.register(submitData)
            .then((response) => {
                // If the server was unreachable or timedout, the request is cancelled and goes into the then handler - trap this as a NoResponseAPIError
                if (!response || !response.message) {
                    throw new NoResponseAPIError();
                }
                
                // Reset the validators and clear input
                const email = this.email;
                this.$refs.form.reset();

                // Set the form to show success message
                this.submitted = true;

                // Redirect to check your email page
                this.$router.push({ name: 'registerConfirm', params: { email }});
            })
            .catch((error) => {
                // registerConfirm route for constructing error messages - replace :email in route with actual email
                let routerLink = this.$router.getRoutes().find(x => x.name === 'registerConfirm').path;
                routerLink = routerLink.slice(0,-6) + encodeURI(this.email);
                let linkText = null;
                
                if (error instanceof NoResponseAPIError ) {
                    this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later';
                } else if (error instanceof UnsupportedMediaAPIError) {
                    this.errorMessage = 'We encountered a server problem, please try again later';
                } else if (error instanceof BadMethodAPIError) {
                    this.errorMessage = 'We encountered a problem, please try again later';
                } else if (error instanceof BadRequestAPIError) {
                    this.errorMessage = 'We encountered a problem, please try again';
                } else if (error instanceof AlreadyActionedAPIError) {
                    this.errorMessage = 'This email address is already registered - please login instead';

                    // Login route
                    routerLink = this.$router.getRoutes().find(x => x.name === 'login').path;
                    linkText = 'Login';
                } else if (error instanceof ExpiredTokenAPIError) {
                    this.errorMessage = 'You have already registered - please enter the confirmation code just emailed to you to continue';
                    
                    // Confirm route
                    linkText = 'Confirm Email';
                } else if (error instanceof NotExistsAPIError) {
                    this.errorMessage = 'You have already registered - please enter the confirmation code that was emailed to you when you registered to continue';
                    
                    // Confirm route
                    linkText = 'Confirm Email';
                } else if (error instanceof InternalServerAPIError) {
                    this.errorMessage = 'We encountered a server problem, please try again later';
                } else {
                    this.errorMessage = 'There was a problem, please try again';
                }

                // Show snackbar error message
                this.setSnackBar({ snack: this.errorMessage, routerLink, linkText });
            })
            .finally(() => {
                // Reset server side submit state
                this.pending = false;
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
  cursor: pointer;
}

.form-group--error, .form-group--error a {
  color: red;
}

.passwordHints {
  margin-top: 20px;
  margin-bottom: 30px;
}

.hint-suggestion, .hint-warning {
  color: orangered;
  font-style: italic;
  font-size: 14px;
}

#loginlinks {
  margin-top: 50px;
}
</style>

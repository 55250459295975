/**
 * Front-end view controller for the reset password page
 *
 * URL Parameters
 * 
 * @param resetToken (string) - unique token generated when requesting password reset
 * @param email (string) - email used to sign up
 *
 * Render's the page template, and processes any UI functions and interactivity
 *
 * @file   Front-end view controller for the reset password page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
<template>
   <v-container>
        <v-row align="start" justify="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col cols="8">
                <!-- Change title based on whether this was token and email was valid -->
                <h1 v-if="!isVerified && !pending">Oops!</h1>
                <h1 v-if="isVerified">Reset Password</h1>

                <!-- Show reason if not verified -->
                <div v-if="!isVerified && !pending">
                    <p>{{ errorMessage }}</p>
                    <p>Or you can send a new <router-link :to="{ name: 'forgot' }">password reminder</router-link>.</p>
                </div>

                <!-- Show the change password form -->
                <div v-if="isVerified">
                    <p style="margin-top: 60px;">Enter your new password below - you must choose a strong password.</p>

                    <v-form ref="form" v-model="valid" @submit.prevent="submit" autocomplete="off" style="margin-top: 60px;">

                        <!-- Password field -->
                        <StrongPassword v-model="password" @score="saveScore" @feedback="showFeedback" @input="$refs.confirmField.validate()" placeholder="New password">
                            <template v-for="(_, slotName) in $scopedSlots" v-slot:[slotName]="slotData">
                                <slot :name="slotName" v-bind="slotData" />
                            </template>
                        </StrongPassword>
                        
                        <!-- Password Hints -->
                        <div class="passwordHints" v-if="showHintsLink">
                            <p><a @click="toggleHints()">stronger password tips &rarr;</a></p>
                            <div v-if="showHints">
                                <div v-for="(hint, index) in this.passwordHints" :key="`hint-${index}`" :class="{'hint-warning': hint.type==='warning', 'hint-suggestion': hint.type==='suggestion' }">
                                    {{hint.msg}}
                                </div>
                            </div>
                        </div>

                        <!-- Confirm Password field -->
                        <v-text-field ref="confirmField" v-model="passwordConfirm" type="password" :rules="passwordConfirmRules" label="Confirm new password" required filled></v-text-field>

                        <p></p>
                        
                        <!-- Submit button -->
                        <v-btn type="submit" :disabled="!valid" :loading="pending">Register</v-btn>
                    </v-form>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapActions } from 'vuex';

// API Connectors
import AuthAPI from '@/services/AuthAPIService.js';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import ExpiredTokenAPIError from '@/errors/expiredtokenapierror';
import InvalidTokenAPIError from '@/errors/invalidtokenapierror';
import ForbiddenAPIError from '@/errors/forbiddenapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';

// use our custom Strong Password component
import StrongPassword from '@/components/StrongPassword';

export default {
    name: 'ResetPassword',

    // URL parameters passed as properties /auth/reset/:resetToken/:email
    props: [
        'resetToken',
        'email'
    ],

    // Our password validation component
    //components: { Password },
    components: { StrongPassword },

    data() {
        return {
            // For verifying the reset token is valid
            isVerified: false,
            errorMessage: '',

            // Form fields
            password: '',
            passwordConfirm: '',

            // Submit props
            valid: false,
            pending: false,
            submitted: false,
            verified: false,
            
            // Password strength fields
            passwordHints: [],
            score: 0,
            showHints: false,

            passwordRules: [
                v => !!v || 'Password is required',
                v => v && v.length && v.length < 191 || 'Password must be less than 191 characters',
                v => v && v.length && v.length > 6 || 'Password must be at least 7 characters',
                () => this.score < 1 || 'Please choose a stronger password'
            ],
        };
    },

    // Computed params
    computed: {
        showHintsLink() {
            return this.passwordHints.length > 0;
        },

        passwordConfirmRules() {
            return [
                (v) => !!v || 'Password confirmation is required',
                (v) => (v === this.password) || 'Passwords do not match',
            ];
        },
    },

    async beforeMount() {
        // Clear the snackbar
        this.clearSnackBar();
            
        // Set the page into pending state so doesn't show the oops page
        this.pending = true;
        
        // Validate the email and password reset token
        const credentials = {
            resetToken: this.resetToken,
            email: this.email,
        };
        AuthAPI.verifyReset(credentials)
        .then((response) => {
            // If the server was unreachable or timedout, the request is cancelled and goes into the then handler so trap this as a NoResponseAPIError
            if (!response || !response.message) {
                throw new NoResponseAPIError();
            }

            // Set the state as verified
            if (response.reason && response.reason === 'SUCCESS') {
                this.isVerified = true;
                this.errorMessage = '';
            }
        })
        .catch((error) => {
            // Clear the snackbar
            this.clearSnackBar();

            // Forgot route for constructing error messages
            let routerLink = this.$router.getRoutes().find(x => x.name === 'forgot').path;
            let linkText = null;

            if (error instanceof NoResponseAPIError ) {
                this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later';
            } else if (error instanceof UnsupportedMediaAPIError) {
                this.errorMessage = 'We encountered a server problem, please try again later';
            } else if (error instanceof BadMethodAPIError) {
                this.errorMessage = 'We encountered a technical problem, please try again later';
            } else if (error instanceof BadRequestAPIError) {
                this.errorMessage = 'We encountered a problem, please try again later';
            } else if (error instanceof ExpiredTokenAPIError) {
                this.errorMessage = 'Your password reset link has expired. Please request a new password reset link';
                
                // Add snackbar button to reset page and increase timeout
                linkText = 'Reset Password';
            } else if (error instanceof CredentialsRevokedAPIError) {
                this.errorMessage = 'You are not allowed to reset your password - please contact us for support';
            } else if (error instanceof InvalidTokenAPIError) {
                this.errorMessage = 'The link you entered was not valid - please check the link sent in the email, and try again';
            } else if (error instanceof InternalServerAPIError) {
                this.errorMessage = 'We encountered a server problem - please try again later';
            } else {
                this.isVerified = false;
                this.errorMessage = 'The link you entered was not valid - please check the link sent in the email, and try again';
            }

            // Show snackbar error message
            this.setSnackBar({ snack: this.errorMessage, routerLink, linkText });
        })
        .finally(() => this.pending = false)
    },

    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
        }),

        // Show secure password tips
        toggleHints() {
            this.showHints = !this.showHints;
        },
    
        // Save the password score for the validator
        saveScore(score) {
            this.score = score;
        },

        // Show password help/suggestions
        showFeedback({ suggestions, warning }) {
            // Put any strings into an array
            let aWarnings = [];
            let aSuggestions = [];
            if (warning && typeof warning === 'string') {
                aWarnings.push(warning);
            } else {
                aWarnings = warning;
            }

            if (suggestions && typeof suggestions === 'string') {
                aSuggestions.push(suggestions);
            } else {
                aSuggestions = suggestions;
            }

            // Reset the hints
            const passwordHints = [];

            // Add all hints into the component
            if (aWarnings.length) {
                aWarnings.forEach((w) => {
                if (w) {
                    passwordHints.push({ type: 'warning', msg: w });
                }
                });
            }
            if (aSuggestions.length) {
                aSuggestions.forEach((s) => {
                if (s) {
                    passwordHints.push({ type: 'suggestion', msg: s });
                }
                });
            }

            this.passwordHints = passwordHints;
        },

        async submit() {
            // Cancel submit if form invalid
            if (!this.$refs.form.validate()) {
                return;
            }

            // Clear the snackbar
            this.clearSnackBar();
                
            // Process a valid form submit
            this.pending = true;

            // Build an array of form values to submit
            const submitData = {
                email: this.email,
                resetToken: this.resetToken,
                password: this.password,
            };

            // Dispatch to API
            AuthAPI.reset(submitData)
            .then((response) => {
                // If the server was unreachable or timedout, the request is cancelled and goes into the then handler so trap this as a NoResponseAPIError
                if (!response || !response.message) {
                    throw new NoResponseAPIError();
                }

                // Set the code as confirmed
                if (response.reason && response.reason === 'SUCCESS') {
                    this.errorMessage = '';

                    // Reset the form fields
                    this.$refs.form.reset();

                    // Set the form to show success message
                    this.submitted = true;

                    // Goto login page
                    this.$router.push({ name: 'login', params: { from: 'reset' }});
                }
            })
            .catch((error) => {
                // Clear the snackbar
                this.clearSnackBar();

                // Reset submit state
                this.submitted = false;

                // Forgot route for constructing error messages
                let routerLink = this.$router.getRoutes().find(x => x.name === 'forgot').path;
                let linkText = null;

                // Set the right error message based on the server response
                if (error instanceof NoResponseAPIError ) {
                    this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later';
                } else if (error instanceof BadMethodAPIError) {
                    this.errorMessage = 'We encountered a technical problem, please try again later';
                } else if (error instanceof BadRequestAPIError) {
                    this.errorMessage = 'We encountered a problem, please try again later';
                } else if (error instanceof ExpiredTokenAPIError) {
                    this.errorMessage = 'Your password reset link has expired. Please request a new password reset link';
                    
                    // Add snackbar button to reset page and increase timeout
                    linkText = 'Reset Password';
                } else if (error instanceof ForbiddenAPIError) {
                    this.errorMessage = 'You are not allowed to reset your password - please contact us for support';
                } else if (error instanceof InvalidTokenAPIError) {
                    this.errorMessage = 'The link you entered was not valid - please check the link sent in the email, and try again';
                } else if (error instanceof InternalServerAPIError) {
                    this.errorMessage = 'We encountered a server problem - please try again later';
                } else {
                    this.isVerified = false;
                    this.errorMessage = 'The link you entered was not valid - please check the link sent in the email, and try again';
                }

                // Show snackbar error message
                this.setSnackBar({ snack: this.errorMessage, routerLink, linkText });
            })
            .finally(() => {
                // Reset server side submit state
                this.pending = false;
            });
        },
    },
};
</script>

<style scoped>
h1 {
    margin: 40px 0 0;
}

a {
    color: #42b983;
    cursor: pointer;
}

.form-group--error, .form-group--error a {
  color: red;
}

.passwordHints {
  margin-bottom: 30px;
}

.hint-suggestion, .hint-warning {
  color: orangered;
  font-style: italic;
}
</style>
/**
 * Front-end view controller for the main application page
 *
 * This is a container view for all other application pages
 *
 * Note:
 *
 *
 * @file   Front-end view controller for the main app page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 *
 */
<template>
    <v-app id="app">
        <!-- The floating action button value exchange selector -->
        <v-speed-dial v-model="fab" absolute fixed bottom right transition="slide-y-reverse-transition" v-if="isLoggedIn && showNav">
            <!-- Action Button -->
            <template v-slot:activator>
                <v-btn v-model="fab" color="blue darken-2" dark fab>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else class="text-h6 fab-text">+Σ</v-icon>
                </v-btn>
            </template>
                
            <!-- Value exchange buttons/types -->
            <v-tooltip left color="grey" v-for="(v, index) in valueTypes" :key="index">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn fab dark color="grey" small slot="activator" v-bind="attrs" v-on="on" @click="addValue(v.type)">
                        <v-icon small>{{v.icon}}</v-icon>
                    </v-btn>
                </template>
                <span>{{v.title}}</span>
            </v-tooltip>
        </v-speed-dial>
        
        <!-- Side Navigation -->
        <SideNavigation
            :currentRoute="currentRoute"
            :userFullName="userFullName"
            :userInitials="userInitials"
            :userProfileImageUrl="userProfileImageUrl"
            :userEmail="email">
        </SideNavigation>

        <!-- Render the appropriate router view in the main window -->
        <v-main>
            <router-view />
        </v-main>

        <!-- Snackbar used to show application messages -->
        <SnackBar />
    </v-app>
</template>

<script>
// Functions used by this page
import { mapGetters } from 'vuex';

// Import our custom components
import SideNavigation from '@/components/SideNavigation';
import SnackBar from '@/components/SnackBar';

// The App page component
export default {
    name: 'App',

    // Side nav links
    components: { SideNavigation, SnackBar },

    data() {
        return {
            // Floating action button-related
            fab: false,
            tooltips: false,
            valueTypes: [
                { type: 'network', icon: 'mdi-card-account-details-outline', title: 'Log Introduction Made' },
                { type: 'mentorship', icon: 'mdi-human-male-board-poll', title: 'Log Mentorship Given' },
                { type: 'intellectual', icon: 'mdi-text-box-plus-outline', title: 'Log Content Created' },
                { type: 'community', icon: 'mdi-cog-outline', title: 'Log Ops Support Provided' }
            ],
        }
    },
    
    computed: {
        // Map our Vuex getters
        ...mapGetters({
            userId: 'User/userid',
            firstname: 'User/firstname',
            lastname: 'User/lastname',
            email: 'User/email',
            profileImg: 'User/profileImagePath',
            isLoggedIn: 'Auth/isLoggedIn',
        }),

        // Current page name for side nav
        currentRoute() {
            return {...this.$route};
        },

        userFullName() {
            return this.firstname && this.lastname ? this.firstname + ' ' + this.lastname : '';
        },

        userInitials() {
            let initials = '';
            if (this.firstname) {
                initials += this.firstname.slice(0,1).toUpperCase();
            }
            if (this.lastname) {
                initials += this.lastname.slice(0,1).toUpperCase();
            }
            return initials;
        },

        userProfileImageUrl() {
            return this.profileImg && this.profileImg.small ? this.profileImg.small : '';
        },

        showNav() {
            return this.$route.meta && this.$route.meta.requiresAuth;
        }
    },

    watch: {
        fab (val) {
            // Reset tooltips position
            this.tooltips = false;
        
            // Set a timeout to show tooltips after button is shown
            val && setTimeout(() => {
                this.tooltips = true;
            }, 250);
        },
    },

    methods: {
        addValue(type) {
            this.$router.push({ name: 'valueAddPerson', params: { type }});
        },
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0;
}

.fab-text {
    font-style:normal;
}
</style>

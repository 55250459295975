import { render, staticRenderFns } from "./RegionSelect.vue?vue&type=template&id=40f417b8&scoped=true&"
import script from "./RegionSelect.vue?vue&type=script&lang=js&"
export * from "./RegionSelect.vue?vue&type=script&lang=js&"
import style0 from "./RegionSelect.vue?vue&type=style&index=0&id=40f417b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40f417b8",
  null
  
)

/* custom blocks */
import block0 from "./RegionSelect.vue?vue&type=custom&index=0&blockType=CountrySelect&v-model=country&placeholder=Choose%20your%20country"
if (typeof block0 === 'function') block0(component)
import block1 from "./RegionSelect.vue?vue&type=custom&index=1&blockType=RegionSelect&v-model=region&%3Acountry=country&placeholder=Choose%20your%20region"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})

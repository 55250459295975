/**
 * Front-end view controller for the main home page
 *
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * Note:
 * 
 *
 * @file   Front-end view controller for the user home page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container fluid>
        <!-- Menus -->
        <HomeNavigation />

        <!-- Section 1 - UVP and CTA -->
        <v-row align="center" justify="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 80px' : 'padding-top: 50px'">
            <!-- Heading and buttons -->
            <v-col cols="12" md="5" class="" :class="{ 'text-left': !$vuetify.breakpoint.smAndDown, 'pl-10': !$vuetify.breakpoint.smAndDown }">
                <h1>Open access to the support, networks, &amp; mentorship you need on your startup journey</h1>
                <h3 class="mt-7">Join our positive-sum community of startups, innovators, and community-builders, who are openly sharing their knowledge, their networks, and their know-how to help each other succeed.</h3>
                <v-btn class="mt-10" color="primary" :to="{name: 'register'}">Get Started</v-btn>
                <v-btn class="ml-5 mt-10" outlined :to="{name: 'login'}">Login</v-btn>
            </v-col>

            <!-- Product images carousel -->
            <v-col cols="12" md="7" class="px-6" :style="!$vuetify.breakpoint.smAndDown ? null : 'padding-top: 50px'">    
                <v-carousel cycle height="400" hide-delimiters show-arrows-on-hover>
                    <v-carousel-item v-for="(item,i) in carousel" :key="i">
                        <v-img :src="item.src" contain max-height="500"></v-img>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>

        <!-- Section 2 - why use our platform -->
        <v-row align="center" justify="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 130px' : 'padding-top: 50px'">
            <v-col cols="12" class="text-center px-10">
                <h1 id="why-join">Why Join Our Community?</h1>
            </v-col>

            <!-- Reason 1 -->
            <v-col cols="12" md="5" :class="{ 'text-right': $vuetify.breakpoint.mdAndUp, 'px-10': $vuetify.breakpoint.mdAndUp }" class="mt-10" order-sm="1" order-md="2">
                <v-img style="cursor: pointer;" :src="require('@/assets/imgs/home/home1.png')" width="100%" contain max-height="400" @click="openPic(require('@/assets/imgs/home/home1.png'))" />
                <p class="text-caption text-center" v-if="$vuetify.breakpoint.mdAndUp">click to enlarge</p>
            </v-col>
            
            <v-col cols="12" md="7" :class="{ 'text-left': $vuetify.breakpoint.mdAndUp, 'text-center': $vuetify.breakpoint.smAndDown, 'px-10': $vuetify.breakpoint.mdAndUp }" class="mt-10" order-sm="2" order-md="1">
                <h3 class="mt-0">For the community, by the community</h3>
                <p>We all know that it takes a village to grow a startup and provide the emotional support, networks, and mentorship needed for success. We're building an intimate, inclusive community of like-minds who own, operate, and run these shared assets together.</p>
            </v-col>

            <!-- Reason 2 -->
            <v-col cols="12" md="5" :class="{ 'text-right': $vuetify.breakpoint.mdAndUp, 'px-10': $vuetify.breakpoint.mdAndUp }" class="mt-10" order-sm="3" order-md="3">
                <v-img style="cursor: pointer;" :src="require('@/assets/imgs/home/home2.png')" width="100%" contain max-height="400" @click="openPic(require('@/assets/imgs/home/home2.png'))" />
                <p class="text-caption text-center" v-if="$vuetify.breakpoint.mdAndUp">click to enlarge</p>
            </v-col>
            
            <v-col cols="12" md="7" :class="{ 'text-left': $vuetify.breakpoint.mdAndUp, 'text-center': $vuetify.breakpoint.smAndDown, 'px-10': $vuetify.breakpoint.mdAndUp }" class="mt-10" order-sm="4" order-md="4">
                <h3 class="mt-0">No gatekeepers!</h3>
                <p>Our members have made a commitment to share who and what- they know with other community members on the startup journey - no more gatekeepers or exclusive-only access to people and resources!</p>
            </v-col>

            <!-- Reason 3 -->
            <v-col cols="12" md="5" :class="{ 'text-right': $vuetify.breakpoint.mdAndUp, 'px-10': $vuetify.breakpoint.mdAndUp }" class="mt-10" order-sm="5" order-md="6">
                <v-img style="cursor: pointer;" :src="require('@/assets/imgs/home/home3.png')" width="100%" contain max-height="400" @click="openPic(require('@/assets/imgs/home/home3.png'))" />
                <p class="text-caption text-center" v-if="$vuetify.breakpoint.mdAndUp">click to enlarge</p>
            </v-col>
            
            <v-col cols="12" md="7" :class="{ 'text-left': $vuetify.breakpoint.mdAndUp, 'text-center': $vuetify.breakpoint.smAndDown, 'px-10': $vuetify.breakpoint.mdAndUp }" class="mt-10" order-sm="6" order-md="5">
                <h3 class="mt-0">Startup community meets the Web3 ownership economy!</h3>
                <p>We operate a contribution economy whereby we recognise, record, and reward value exchanged between members, capturing that using Web3 tokens as a way to pay-it-forward to the rest of the community using a unique contribute-to-earn model.</p>
            </v-col>
            
            <!-- Reason 4 -->
            <v-col cols="12" md="5" :class="{ 'text-right': $vuetify.breakpoint.mdAndUp, 'px-10': $vuetify.breakpoint.mdAndUp }" class="mt-10" order-sm="7" order-md="7">
                <v-img style="cursor: pointer;" :src="require('@/assets/imgs/home/home4.png')" width="100%" contain max-height="400" @click="openPic(require('@/assets/imgs/home/home4.png'))" />
                <p class="text-caption text-center" v-if="$vuetify.breakpoint.mdAndUp">click to enlarge</p>
            </v-col>
            
            <v-col cols="12" md="7" :class="{ 'text-left': $vuetify.breakpoint.mdAndUp, 'text-center': $vuetify.breakpoint.smAndDown, 'px-10': $vuetify.breakpoint.mdAndUp }" class="mt-10" order-sm="8" order-md="8">
                <h3 class="mt-0">Find your tribe</h3>
                <p>Alongside building open startup support tools, we're actively building an IRL community with events, participation benefits, NFT drops, and unique rewards for contributors - get involved whilst it's early!</p>
            </v-col>

            <!-- Reason 5 -->
            <v-col cols="12" md="5" :class="{ 'text-right': $vuetify.breakpoint.mdAndUp, 'px-10': $vuetify.breakpoint.mdAndUp }" class="mt-10" order-sm="9" order-md="10">
                <v-img style="cursor: pointer;" :src="require('@/assets/imgs/home/home5.png')" width="100%" contain max-height="400" @click="openPic(require('@/assets/imgs/home/home5.png'))" />
                <p class="text-caption text-center" v-if="$vuetify.breakpoint.mdAndUp">click to enlarge</p>
            </v-col>
            
            <v-col cols="12" md="7" :class="{ 'text-left': $vuetify.breakpoint.mdAndUp, 'text-center': $vuetify.breakpoint.smAndDown, 'px-10': $vuetify.breakpoint.mdAndUp }" class="mt-10" order-sm="10" order-md="9">
                <h3 class="mt-0">Help us build a more open &amp; equitable startup community!</h3>
                <p>Our thesis: if we can capture more of the early unpaid value which flows in startup communities, we can create a 'flywheel' to create more and more value in the community so that we all win together in a positive-sum game.</p>
            </v-col>
        </v-row>

        <!-- Section 3 - How it works -->
        <v-row align="center" justify="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 150px' : 'padding-top: 50px'">
            <v-col cols="12" class="text-center px-10">
                <h1 id="how-it-works">How Our Platform Works</h1>
            </v-col>

            <!-- Controls -->
            <v-col cols="3" class="text-left" v-if="!$vuetify.breakpoint.smAndDown">
                <div class="selector" v-for="(item,i) in howItWorks" :key="i" @click="howItWorksSelected = i" :class="{ 'selected': i === howItWorksSelected}">
                    <span class="number">{{i+1}}</span>
                    <span class="text">{{item.title}}</span>
                </div>
            </v-col>

            <v-col cols="9" class="pr-6" v-if="!$vuetify.breakpoint.smAndDown">
                <v-img :src="howItWorksSrc" width="100%" contain />
                <p class="mt-10">{{howItWorksDesc}}</p>
            </v-col>

            <!-- Small screens just render each page separately -->
            <v-col cols="12" md="11" :class="{ 'text-left': $vuetify.breakpoint.mdAndUp, 'text-center': $vuetify.breakpoint.smAndDown }" v-if="$vuetify.breakpoint.smAndDown">
                <div v-for="(item,i) in howItWorks" :key="i" class="mb-15">
                    <div class="selector selected">
                        <span class="number">{{i+1}}.</span>
                        <span class="text">{{item.title}}</span>
                    </div>

                    <v-img :src="howItWorks[i].src" contain class="my-15" />
                    <p class="mt-10">{{howItWorks[i].desc}}</p>
                </div>
            </v-col>
        </v-row>

        <!-- Section 4 - Questions? -->
        <v-row align="center" justify="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 150px' : 'padding-top: 50px'">
            <v-col cols="12" class="text-center px-10">
                <h1 id="faq">Questions?</h1>
            </v-col>
            <v-col cols="12">
                Have a question or want to get connected? Join us on Discord and ask for help from our friendly community!
            </v-col>
            <v-col cols="12">
                <v-btn outlined @click="openUrl('https://discord.gg/3dhddwGSMf')">Discord</v-btn>
                <v-btn outlined class="ml-5" :to="{name: 'register'}">Get Started</v-btn>
            </v-col>
        </v-row>
        
        <!-- Section 5 - Footer -->
        <v-row align="center" justify="center" :class="{ 'mb-15': $vuetify.breakpoint.smAndDown }" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 150px' : 'padding-top: 50px'">
            <v-col cols="12" class="text-center px-10">
                <span style="cursor: pointer" @click="openUrl('https://positivesum.community/')">Made with ❤️ &nbsp;by Positive Sum DAO</span>
            </v-col>
        </v-row>

        <!-- Modal dialog / image viewer -->
        <v-dialog v-model="dialog" max-width="90%" @keydown.esc="cancel">
            <v-card>
                <v-img :src="imageToView" contain />
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

// Import our custom components
import HomeNavigation from '@/components/HomeNavigation';

// The main home page component
export default {
    name: 'MainHome',

    components: {
        HomeNavigation,
    },

    data () {
      return {
        // images carousel
        carousel: [
          {
            src: require('@/assets/imgs/dashboard.png'),
          },
          {
            src: require('@/assets/imgs/directory.png'),
          },
          {
            src: require('@/assets/imgs/exchange.png'),
          },
          {
            src: require('@/assets/imgs/calendar.png'),
          },
          {
            src: require('@/assets/imgs/library.png'),
          },
          {
            src: require('@/assets/imgs/bounties.png'),
          },
        ],

        // How it works slides
        howItWorks: [
          {
            src: require('@/assets/imgs/directory.png'),
            title: 'Search our member directory for potential mentors',
            desc: 'Use our member directory to list your own skills, expertise, and how you can help others.  Tell others what you\'re currently working on and what help they can provide you!  Easily search other members\' skills and specialist areas of expertise to help find that right piece of knowledge you need next on the journey',
          },
          {
            src: require('@/assets/imgs/exchange.png'),
            title: 'Trade value with others for reputation, rewards, & benefits',
            desc: 'As you provide value to other community members, log it on our value exchange so others get visibility of specialist networks/knowledge you have. If you contribute enough, you can unlock community perks, rewards, and benefits as a thank you for being a good community citizen and paying-it-forward!',
          },
          {
            src: require('@/assets/imgs/calendar.png'),
            title: 'Share your expertise through online sessions & office-hours',
            desc: 'Need help on a specific topic?  Request a community session or propose and host your own session to help share your expertise.  Anyone can add a new session on to the community calendar as well as joining our office hours programme where you can offer your time in exchange for some time with others',
          },
          {
            src: require('@/assets/imgs/library.png'),
            title: 'Create, \'hunt\', and consume others\' content on the journey',
            desc: 'Browse through our library of pre-recorded sessions and content that our members have created and shared to help you be more successful on the startup journey.  Get rewarded for hunting the best startup content out there and discuss with the community to add value and your experience so we can all grow together.',
          },
          {
            src: require('@/assets/imgs/bounties.png'),
            title: 'Reward others via bounties for finding you specific help',
            desc: 'Really need a specific bit of urgent support? Maybe a key network to an investor, customer, or specific way-in to a programme or company, or some targetted expertise?  List a \'bounty\' and attach a reward to help incentivise others to prioritise their help and dig deep for you.  Complete others\' bounties to earn rewards!',
          },
        ],
        howItWorksSelected: 0,

        // Image popup preview
        dialog: false,
        imageToView: '',
      }
    },

    computed: {
        howItWorksDesc() {
            return this.howItWorks[this.howItWorksSelected].desc;
        },

        howItWorksSrc() {
            return this.howItWorks[this.howItWorksSelected].src;
        },
    },

    methods: {
        openUrl(url) {
            if (url) {
                window.open(url);
            }
        },

        openPic(image) {
            this.dialog = true
            this.imageToView = image
        },

        cancel() {
            this.dialog = false
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

p.text-caption {
    color:#90A4AE;
}

.selector {
    width: 100%;
    padding: 10px;
    background-color: #eeeeee;
    border-radius: 12px;
    margin: 20px 0;
    cursor: pointer;
    position: relative;
}

.selector.selected {
    background-color: #bb86fc42;
}

.selector:hover {
    background-color: #bb86fc81;
}

.selector .number {
    font-size: 1.5rem;
    padding-right: 5px;
    top: 2px;
    position: relative;
}

.selector .text {
    font-size: 1rem;
}

</style>

/**
 * Front-end view controller for the login page
 *
 * URL Parameters
 * 
 * @param from (string) - which page the login came from - for rendering additional instructions
 *
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * @file   Front-end view controller for the login page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container>
        <v-row align="start" justify="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col cols="8">
                <!-- Title -->
                <h1>Login</h1>

                <!-- Message based on where the user came from -->
                <p>Enter your email address and password to continue.</p>

                <!-- Login form -->
                <v-form ref="form" v-model="valid" @submit.prevent="submit" autocomplete="off" style="margin-top: 60px">

                    <!-- Email field -->
                    <v-text-field v-model="email" name="email" :rules="emailRules" required filled>
                        <template #label><span class="red--text"><strong>* </strong></span>Your email address</template>
                    </v-text-field>

                    <!-- Password field -->
                    <v-text-field v-model="password" name="password" type="password" :rules="passwordRules" required filled>
                        <template #label><span class="red--text"><strong>* </strong></span>Your password</template>
                    </v-text-field>
                    
                    <!-- Remember me field -->
                    <div style="display: flex; justify-content: center">
                        <v-checkbox v-model="rememberMe" label="Keep me logged in" style="text-center" />
                    </div>
                    
                    <p></p>
                    
                    <!-- Submit button -->
                    <v-btn type="submit" :disabled="!valid" :loading="pending">Login</v-btn>
                    
                    <!-- Forgot links -->
                    <p id="forgotlinks">
                        <router-link :to="{name: 'forgot'}">Forgotten your password?</router-link>
                    </p>

                    <!-- Register link -->
                    <p class="mt-5"><router-link :to="{name: 'register'}">Sign-up for an account</router-link></p>

                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapActions } from 'vuex';

// Import our auth API functions
import AuthAPI from '@/services/AuthAPIService.js';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import ExpiredTokenAPIError from '@/errors/expiredtokenapierror';
import NotExistsAPIError from '@/errors/notexistsapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';

// The Login page component
export default {
    name: 'UserLogin',
  
    data() {
        return {
            // Form fields
            email: '',
            password: '',
            rememberMe: false,
            
            // Form submit state
            valid: false,
            submitted: false,
            pending: false,
            
            // Error Message
            errorMessage: '',

            // Validation Rules
            emailRules: [
                v => !!v || 'Email address is required',
                v => /.+@.+/.test(v) || 'Email address must be valid',
            ],

            passwordRules: [
                v => !!v || 'Password is required',
                v => v && v.length && v.length < 191 || 'Password must be less than 191 characters',
                v => v && v.length && v.length > 6 || 'Password must be at least 7 characters',
            ],
        };
    },

    // URL parameters passed as properties /:from
    props: [
        'from',      // "register" | "reset"
        'fromemail',
    ],

    beforeMount() {
        // Check if an email address has been passed in
        if (this.fromemail && this.fromemail.length) {
            this.email = this.fromemail;
        }

        // Check if we're showing a sucess message from another page & show snackbar success message
        if (this.from && this.from === 'register') {
            this.setSnackBar({ snack: 'Email confirmed successfully - please login to continue', color:"success" });
        } else if (this.from && this.from === 'reset') {
            this.setSnackBar({ snack: 'Password reset successfully - please login using your new password to continue', color:"success" });
        }                    
    },
    
    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
        }),

        // Function to execute a user-initiated login action
        async submit() {
            // Cancel submit if form invalid
            if (!this.$refs.form.validate()) {
                return;
            }

            // Clear the snackbar
            this.clearSnackBar();
                
            // Process a valid form submit
            this.pending = true;

            // Build the credentials array from the posted form fields
            const credentials = {
                email: this.email,
                password: this.password,
                rememberMe: this.rememberMe,
            };
            
            // Post the submitted details to the login API endpoint
            AuthAPI.login(credentials)
            .then( (response) => {
                // If the server was unreachable or timedout, the request is cancelled and goes into the then handler
                // Trap this as a NoResponseAPIError
                if (!response || !response.message) {
                    throw new NoResponseAPIError();
                }
                
                // Reset the validators and clear input
                this.$refs.form.reset();

                // Set the form to show success message
                this.submitted = true;

                // Redirect to user home or the return url
                const returnUrl = this.$route.query.return ? this.$route.query.return : null;
                if (returnUrl) {
                    this.$router.push(returnUrl);
                } else if (this.from && this.from === "register") {
                    this.$router.push({ name: 'userEditProfile', params: { from: 'register' } });
                }
                else {
                    this.$router.push({ name: 'dashboard' });
                }
            })
            .catch( (error) => {
                // Clear the snackbar
                this.clearSnackBar();

                // registerConfirm route for constructing error messages - replace :email in route with actual email
                let routerLink = this.$router.getRoutes().find(x => x.name === 'registerConfirm').path;
                routerLink = routerLink.slice(0,-6) + encodeURI(this.email);
                let linkText = null;

                if (error instanceof NoResponseAPIError ) {
                    this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later';
                } else if (error instanceof UnsupportedMediaAPIError) {
                    this.errorMessage = 'We encountered a server problem, please try again later';
                } else if (error instanceof BadMethodAPIError) {
                    this.errorMessage = 'We encountered a technical problem, please try again later';
                } else if (error instanceof BadRequestAPIError) {
                    this.errorMessage = 'Login unsuccessful, please try again';
                } else if (error instanceof CredentialsRevokedAPIError) {
                    this.errorMessage = 'You are not allowed to login, please contact us for support';
                } else if (error instanceof ExpiredTokenAPIError) {
                    this.errorMessage = 'A new confirmation code was just emailed to you - please check your email to continue';

                    // Add snackbar button to confirm email
                    linkText = 'Confirm Email';
                } else if (error instanceof NotExistsAPIError) {
                    this.errorMessage = 'You need to enter the confirmation code that was emailed to you to continue';
                } else if (error instanceof InternalServerAPIError) {
                    this.errorMessage = 'We encountered a server problem, please try again later';
                } else {
                    this.errorMessage = 'There was a problem logging in, please try again later';
                }

                // Reset the password field on any error
                this.password = '';

                // Reset the validators and clear input
                this.$refs.form.resetValidation();

                // Show snackbar error message
                this.setSnackBar({ snack: this.errorMessage, routerLink, linkText });
            }).finally(() => {
                // Reset server side submit state
                this.pending = false;
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

a {
  color: #42b983;
}

#forgotlinks {
  margin-top: 50px;
}
</style>

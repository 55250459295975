/**
 * Front-end view controller for the value confirmed page
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * Note:
 * 
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the value confirmed page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container v-if="isLoggedIn">
        <!-- Title and blurb -->
        <v-row align="center" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col>
                <h1>Thank you for using our community to get ahead!</h1>
                <p class="mt-5">Community contributions are super-helpful in adding value to us as individuals and to the community as a whole.</p>
                <p>The more we all give back, the stronger we collectively are so please consider contributing in return.</p>
                <p>You can contribute by helping other community members at events, online, or in Discord, then logging any introductions made, mentorship given, or content created- here in our value exchange, so we all get ahead together.</p>

                <p class="my-10" id="emailicon">
                    🙌 
                </p>
                
                <p class="font-weight-bold">We've sent a note to {{firstName | capitalize}} to close off this transaction, and to provide any feedback you submitted.</p>
                <p class="mb-0 ">Thanks for being awesome!</p>
            </v-col>
        </v-row>

        <!-- Submit reminder & button -->
        <v-row align="center" justify="space-around">
            <v-col cols="12" md="9">
                <v-btn :to="{ name: 'valueHome' }">Done</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters } from 'vuex';

// Setup our confetti module
import Vue from 'vue';
import VueConfetti from 'vue-confetti';
Vue.use(VueConfetti);

// The main admin home page component
export default {
    name: 'ValueConfirmed',

    data() {
        return {
        };
    },

    // Incoming properties for this component
    props: {
        firstName: { type: String, default: null },
    },

    computed: {
        // Map our Vuex getters
        ...mapGetters({
            isLoggedIn: 'Auth/isLoggedIn',
        }),
    },

    filters: {
        capitalize: (value) => {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },

    watch: {
        // whenever login state changes and we're no longer logged in, we redirect to login page
        isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
            if (!newIsLoggedIn && oldIsLoggedIn) {
                this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
            }
        },
    },

    // If we're not navigating to another exchange page, we clear the searchResults
    beforeRouteLeave(to,from,next) {
        const validRouteNames = ['valueHome', 'valueAddPerson', 'valueLogValue', 'valueLogged', 'valueConfirm', 'valueConfirmed', 'valueDisputed', 'valueView'];
        if (!to.name || validRouteNames.indexOf(to.name) === -1) {
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-searchResults' });
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-recentResults' });
        }
        next();
    },

    mounted() {
        // Show the confetti effect
        this.$confetti.start({
          defaultType: 'heart',
          defaultSize: 20,
          defaultDropRate: 20,
        });
        setTimeout(() => this.$confetti.stop(), 2500);
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
#emailicon {
    font-size: 160px;
}
</style>

/**
 * Country Select component
 *
 * This renders a country select drop down field - form values are returned as countryName::countryShortCode
 * 
 * Note:
 * Use in tandem with region select, by passing in the country object returned from the country select box
 * 
 * import CountrySelect from '@/components/CountrySelect';
 * import RegionSelect from '@/components/RegionSelect';
 *
 * Template:
 *      <CountrySelect v-model="country" placeholder="Choose your country" />
 *      <RegionSelect v-model="region" :country="country.countryName || ''" placeholder="Choose your region" />
 *
 * Component:
 *
 *      components: { CountrySelect, RegionSelect },
 *
 *      data() {
 *          return {
 *              country: {},
 *              region: {},
 *          }
 *      },
 * 
 * @file   Country Select component
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
<template>
    <v-select v-bind="{ ...$props, ...commonAttrs }" v-on="$listeners">
        <template v-slot:prepend-inner> 
            <img v-if="countryIcon" :src="`data:image/png;base64,${countryIcon}`" class="flag" />
        </template>
        <template #label><span class="red--text" v-if="$attrs.required"><strong>* </strong></span>{{label}}</template>
    </v-select>
</template>

<script>
// Import vuetify select component to subclass
import { VSelect } from 'vuetify/lib'

// Import the countries data in JSON format
import allCountries from 'country-region-data/data.json';

// Import the flag data in JSON format
import { countries as flags } from '@/data/countries.js';

/*
 * Note: country data is in the following format:
 * 
 * [
 * {
 *   "countryName":"Ecuador",
 *   "countryShortCode":"EC",
 *   "regions":[
 *     {
 *       "name":"Azuay",
 *       "shortCode":"A"
 *     },
 *     ...
 *   ]
 * },
 * ... 
 * ]
 * 
 * Flag data is in following format:
 * 
 * [
 * {
 *    country: 'Albania',
 *    iso2: 'al',
 *    flag: '[data]...';
 * }
 * ...
 * ]
 */

// The countries component
export default {
    
    name: "CountrySelect",
    
    extends: VSelect,
    
    computed: {
        countryIcon() {
            if (!this.value || !this.value.countryShortCode) return null;

            // Lookup the current selected country in the flag array and return the base64 image
            const found = flags.find(f => f.iso2 === this.value.countryShortCode.toLowerCase());
            return found && found.flag ? found.flag : null;
        },
        
        commonAttrs() {
            return {
                items: allCountries,
                itemText: 'countryName',
                returnObject: true,
                autocomplete: 'off',
            }
        }
    },
};
</script>

<style scoped>
.flag {
    width: 30px;
    margin-right: 10px;
}
</style>
/**
 * Front-end view controller for the user edit profile page/dashboard
 *
 * Render's the page template, and processes any UI functions and interactivity
 *
 * Note:
 *
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the user edit profile page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 *
 */
 <template>
    <v-container v-if="isLoggedIn" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
        <v-row align="start" class="mb-5 mt-0">
            <v-col cols="12" md="9" align="left">
                <!-- Back Link -->
                <a class="text-left" @click="navigateBack">&lt; Back</a>
            </v-col>
        </v-row>

        <v-card class="pa-8">
            <!-- Profile picture row -->
            <v-row align="start">
                <!-- profile pic - file uploader component -->
                <v-col cols="12" md="2">
                    <file-pond
                        name="files[]"
                        ref="fileUploader"
                        :server="fileUploadConfiguration"
                        chunkUploads=true
                        :imageTransformAfterCreateBlob="imageTransformAfterCreateBlob"
                        @init="initFileUploader"
                        @addfilestart="addFileStart"
                        @error="onErrorFiles"
                        @removefile="removeFile"
                        @processfiles="processFilesComplete"
                    />
                </v-col>

                <!-- Name & Email -->
                <v-col cols="12" md="7" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'">
                    <v-list class="pb-0">
                        <v-list-item>
                            <v-list-item-content>
                                <!-- If profile setup, show different header and message -->
                                <v-list-item-title v-if="isProfileSetup" class="text-h5">Setup Your Profile</v-list-item-title>
                                <v-list-item-title v-else class="text-h5">{{userFullName}}</v-list-item-title>
                                <v-list-item-subtitle v-if="isProfileSetup" style="white-space: normal">Add a profile picture and complete your profile below to help other community members better find you in the directory.</v-list-item-subtitle>
                                <v-list-item-subtitle v-else><p class="mb-3">{{email}}</p><h4 class="mt-0 text-wrap">{{headline}}</h4></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
                
                <!-- Profile Box -->
                <v-col cols="12" md="3" align-self="end" class="pa-0">
                    <ProfileBox
                        :id='userId'
                        :firstname='firstName'
                        :lastname='lastName'
                        :profileImgSrc='userProfileImgPath'
                        :regionStr='regionName'
                        :countryStr='countryName'
                        :countryCode='countryCode'
                        :clickable=false
                        :align="$vuetify.breakpoint.smAndDown ? 'center' : 'right'"
                        level=""
                    />
                </v-col>
            </v-row>

            <!-- Form fields rows -->
            <v-row align="start">
                <v-col>
                    <!-- Register form -->
                    <v-form ref="form" v-model="valid" @submit.prevent="submit" autocomplete="off">

                        <!-- Your Details Header -->
                        <h2 class="text-left mb-4" v-if="!isProfileSetup">Your Details</h2>
                        
                        <!-- First name field -->
                        <v-text-field v-model="firstName" :rules="firstNameRules" label="Your first name" required filled v-if="!isProfileSetup">
                            <template #label><span class="red--text"><strong>* </strong></span>Your first name</template>
                        </v-text-field>

                        <!-- Last name field -->
                        <v-text-field v-model="lastName" :rules="lastNameRules" label="Your last name" required filled v-if="!isProfileSetup">
                            <template #label><span class="red--text"><strong>* </strong></span>Your last name</template>
                        </v-text-field>

                        <!-- Country Select field -->
                        <h2 class="text-left mb-4" v-if="isProfileSetup">Where are you based?</h2>
                        <CountrySelect v-model="country" label="Your country" filled :required="true" :rules="countryRules" />

                        <!-- Region Select field -->
                        <RegionSelect v-model="region" :country="country" label="Your region" :required="true" filled :rules="regionRules" />

                        <!-- Password field -->
                        <div class="text-left mb-7" v-if="!isProfileSetup">
                            <v-icon class="mr-4">mdi-email-edit</v-icon>
                            <router-link :to="{ name: 'forgot', query: { email } }">Change your password</router-link>
                        </div>
                        
                        <!-- Discord Username -->
                        <h2 class="text-left mt-15 mb-4">Your Discord Details</h2>
                        <p class="text-left">Our main community runs on discord! Please <a href="https://discord.gg/3dhddwGSMf" target="_blank">join discord</a>, then enter your username here so others can connect.</p>
                        <v-text-field v-model="discord" :rules="discordRules" required filled hint="Enter your full discord username (e.g. LeanCTO#2299)">
                            <template #label><span class="red--text"><strong>* </strong></span>Your discord username</template>
                        </v-text-field>

                        <!-- Your Background/Skills/Experience Header -->
                        <h2 class="text-left mt-15 mb-4">Your Background, Skills, &amp; Experience</h2>

                        <!-- Headline -->
                        <v-text-field v-model="headline" class="mb-8" :rules="headlineRules" label="Headline" persistent-hint hint="A one-liner headline summary" required filled counter=220></v-text-field>
                        
                        <!-- Your bio -->
                        <v-textarea v-model="bio" filled counter=2500 :auto-grow="isTextAreaSelected('bio')" rows="1" class="mb-8" row-height="20" :rules="bioRules" label="What's your background?" persistent-hint hint="Give people a brief summary of your background.  You may add website links as [Google](https://www.google.com/)" @focus="selectTextArea('bio')" @blur="deselectTextArea"></v-textarea>

                        <!-- What are you working on -->
                        <v-textarea v-model="workingOn" filled counter=2500 :auto-grow="isTextAreaSelected('workingon')" rows="1" class="mb-8" row-height="20" :rules="workingOnRules" label="What are you working on currently?" persistent-hint hint="Let people know what you're working on so they can see how they can help you on the journey." @focus="selectTextArea('workingon')" @blur="deselectTextArea"></v-textarea>

                        <!-- How you can help others -->
                        <v-combobox
                            v-model="selectedSkills"
                            :filter="filterSkillTags"
                            :hide-no-data="!search"
                            :items="filteredSkills"
                            :search-input.sync="search"
                            :rules="skillTagsRules"
                            hide-selected
                            label="List your skills, experience, and domain expertise"
                            multiple
                            small-chips
                            persistent-hint
                            filled
                            counter=25
                            class="mb-8 v-skillcombobox"
                            hint="Show others what you know and how you can help them"
                        >
                            <!-- Allow user to create new tag when no matching selection -->
                            <template v-slot:no-data>
                                <v-list-item>
                                    <span class="subheading mr-2">Create</span>
                                    <v-chip color="green lighten-3" label small>{{ search }}</v-chip>
                                </v-list-item>
                            </template>

                            <!-- Render selected tags as deletable chips to deselect an item -->
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip v-if="item === Object(item)" v-bind="attrs" :color="`${skillColour(item.type)} lighten-3`" :input-value="selected" label small>
                                    <span class="pr-2">{{ item.text }}</span>
                                    <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                                </v-chip>
                            </template>

                            <!-- show an item in the list - if we're editing, show text-field, or show a delete and edit option for user-specific tags -->
                            <template v-slot:item="{ index, item }">
                                <v-text-field v-if="editing === item" v-model="editing.text" autofocus flat dense background-color="transparent" hide-details solo @keyup.enter="editSkillTag(index, item)"></v-text-field>
                                <h3 class="pa-0 ma-0" v-if="item.type === 'divider'">{{item.text}}</h3>
                                <v-chip v-else :color="`${skillColour(item.type)} lighten-3`" label small>{{ item.text }}</v-chip>

                                <v-spacer></v-spacer>

                                <!-- delete button for custom tags if not editing -->
                                <v-list-item-action @click.stop v-if="item.type === 'user'">
                                    <v-btn icon @click.stop.prevent="deleteSkillTag(item)" v-if="!editing">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-list-item-action>

                                <!-- edit or save button if a custom tag -->
                                <v-list-item-action @click.stop v-if="item.type === 'user'">
                                    <v-btn icon @click.stop.prevent="editSkillTag(index, item)">
                                        <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                                    </v-btn>
                                </v-list-item-action>

                                <!-- If it's not a custom tag, we show the popularity count -->
                                <v-list-item-action v-if="item.type !== 'user'">
                                    <v-btn block disabled x-small class="ma-0 pa-0" v-if="item.popularity && item.popularity >= 0">
                                        {{item.popularity}}
                                    </v-btn>
                                </v-list-item-action>
                            </template>
                        </v-combobox>

                        <!-- The form buttons -->
                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <!-- Skip Button -->
                            <v-btn :disabled="pending" :to="{ name: 'dashboard' }" v-if="isProfileSetup">Skip</v-btn>

                            <!-- Reset Button -->
                            <v-btn type="reset" :disabled="pending" @click.prevent="resetForm">Reset</v-btn>

                            <!-- Send Button -->
                            <v-btn type="submit" :loading="pending" :disabled="!formButtonsEnabled">Save</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-form>
                </v-col>
            </v-row>
        </v-card>

        <!-- The confirm dialog -->
        <ConfirmDialog ref="confirm" />

        <!-- A drop zone overlay for files -->
        <div id="dropzone" class="dropzone" ref="dropzone" :class="{ show: showDropZone }"></div>

    </v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters, mapActions } from 'vuex';

// Shared components used in this page
import CountrySelect from '@/components/CountrySelect';
import RegionSelect from '@/components/RegionSelect';
import ProfileBox from '@/components/ProfileBox';

// Common includes used in this page
import UserAPIService from '@/services/UserAPIService';
import FileAPIService from '@/services/FileAPIService';

// Import our confirm dialog component
import ConfirmDialog from '@/components/ConfirmDialog';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';

// Setup our transform options for filepond - save a reference to variant metadata so we can add
const variantOptions = { variants: [] };

// Initialise the fileuploader config
FileAPIService.setFileUploaderOptions(
    FileAPIService.profilePicOptions(variantOptions)
);

// Create FileUploader component
const FilePond = FileAPIService.createFileUploader();

// The main user home page component
export default {
    name: 'EditProfile',

    props: [
        // if coming from register page, we go into setup your profile mode
        'from',             // "register"
    ],

    data() {
        return {
            // Form fields
            firstName: '',
            lastName: '',
            discord: '',
            country: {},
            region: {},
            headline: '',
            bio: '',
            workingOn: '',
            skills: [],
            selectedSkills: [],         // Which skills are selected
            deletedUserTags: [],        // track deleted tags that have a tagid so we can delete server-side
            updatedUserTags: [],        // track updated tags that have a tagid so we can update server-side

            // Track the original list of skills as fetched from the API
            originalUserSkills: [],
            originalSkills: [],

            // Stash original values of fetched values so we can reset the form
            userDiscord: '',
            userHeadline: '',
            userBio: '',
            userWorkingOn: '',
            userSelectedSkills: [],

            // Order by popularity or category
            popularityOrder: true,

            // Validation rules
            firstNameRules: [
                v => !!v || 'First name is required',
                v => v && v.length && v.length < 191 || 'First name must be fewer than 191 characters',
            ],

            lastNameRules: [
                v => !!v || 'First name is required',
                v => v && v.length && v.length < 191 || 'First name must be fewer than 191 characters',
            ],

            discordRules: [
                v => !!v || 'Discord username is required',
                v => v && v.length && v.length < 191 || 'Discord username must be fewer than 191 characters',
                v => v && v.length && v.match(/\w+#\d{4}/i) !== null || 'Discord username must include the # and subsequent 4 characters',
            ],

            countryRules: [
                (v) => {
                    if (!v || !v.countryName || !v.countryName.length) return 'Country is required';
                    return true;
                }
            ],
            
            regionRules: [
                (v) => {
                    if (!this.country || !this.country.countryName || !this.country.countryName.length) return true;
                    if (this.country && this.country.countryName && (!v || !v.name || !v.name.length)) return 'Region is required';
                    return true;
                }
            ],

            headlineRules: [
                (v) => {
                    if (!v) {
                        return true;
                    } else {
                        return v.length && v.length <= 220 || 'Your headline must no more than 220 characters';
                    }
                }
            ],
            
            bioRules: [
                (v) => {
                    if (!v) {
                        return true;
                    } else {
                        return v.length && v.length <= 2500 || 'Your background must be no more than 2,500 characters';
                    }
                }
            ],

            workingOnRules: [
                (v) => {
                    if (!v) {
                        return true;
                    } else {
                        return v.length && v.length <= 2500 || 'What you\'re working on must be no more than 2,500 characters';
                    }
                }
            ],

            skillTagsRules: [
                (v) => {
                    if (!v || !v.length) {
                        return true;
                    } else {
                        return v.length && v.length <= 25 || 'You can only choose a total of 25 skills, domains, and industries';
                    }
                }
            ],

            // We track the field name of the selected text area so we can make it auto-grow
            selectedTextArea: null,

            // For file uploader
            attachments: [],
            sendingFiles: false,
            numFileUploads: 1,
            showDropZone: false,

            // Form submit state
            valid: false,
            pending: false,
            filesDirty: false,

            // Submit error messages
            errorMessage: '',

            // Skills combobox
            editing: null,
            editingIndex: -1,
            search: null,
        };
    },

    // The components we'll be using in this template
    components: {
        FilePond,
        ConfirmDialog,
        CountrySelect,
        RegionSelect,
        ProfileBox,
    },

    computed: {
        // Map our Vuex getters
        ...mapGetters({
            isLoggedIn: 'Auth/isLoggedIn',
            accessToken: 'Auth/accessToken',
            isAdmin: 'Auth/isAdmin',
            rememberMe: 'Auth/rememberMe',
            userId: 'User/userid',
            email: 'User/email',
            profileImg: 'User/profileImagePath',

            // Rename these fields so they don't conflict with form values
            userFirstname: 'User/firstname',
            userLastname: 'User/lastname',
            userCountryName: 'User/countryName',
            userCountryCode: 'User/countryCode',
            userRegionName: 'User/regionName',
            userRegionCode: 'User/regionCode',
        }),

        // File Upload endpoint
        fileUploadConfiguration() {
            return FileAPIService.configureFileUploader(this.accessToken);
        },

        userProfileImgPath() {
            return this.profileImg && this.profileImg.large ? this.profileImg.large : '';
        },

        userFullName() {
            return this.userFirstname && this.userLastname ? this.userFirstname + ' ' + this.userLastname : '';
        },

        // Whether to enable the form buttons
        formButtonsEnabled() {
            // Only if form fields changed from originals
            const nameChanged = this.firstName !== this.userFirstname || this.lastName !== this.userLastname;

            const countryChanged = (!this.userCountryName && this.country && this.country.countryName && this.country.countryName.length) ||
                (this.userCountryName && this.userCountryName.length && this.country.countryName !== this.userCountryName);

            const regionChanged = (!this.userRegionName && this.region && this.region.name && this.region.name.length) ||
                (this.userRegionName && this.userRegionName.length && this.region.name !== this.userRegionName);

            const bioChanged = this.discord !== this.userDiscord || this.headline !== this.userHeadline || this.bio !== this.userBio || this.workingOn !== this.userWorkingOn;

            // Define a few helper functions to calculate the difference between two object arrays
            const diffBy = (pred) => (a, b) => a.filter(x => !b.some(y => pred(x, y)));
            const makeSymmDiffFunc = (pred) => (a, b) => diffBy(pred)(a, b).concat(diffBy(pred)(b, a));
            const array_diff = makeSymmDiffFunc((x, y) => x.tagid === y.tagid && x.text === y.text && x.type === y.type);

            // Now determine if the current selectedSkills differ from original
            const skillsChanged = array_diff(this.selectedSkills, this.userSelectedSkills).length > 0;

            // If we're deleting or updating any skill tags then we enable save button
            const customTagsChanged = this.deletedUserTags.length || this.updatedUserTags.length;

            // Determine if any fields have changed
            const fieldsChanged = nameChanged || countryChanged || regionChanged || bioChanged || skillsChanged || customTagsChanged;

            // Enable if we're not in process of uploading a file, if the fields have changed, or if the file has changed and the form is valid
            return this.valid && !this.sendingFiles && (this.filesDirty || fieldsChanged);
        },

        isProfileSetup() {
            return this.from === 'register';
        },

        isTextAreaSelected() {
            return (fieldname) => this.selectedTextArea == fieldname;
        },

        // Renders the combo-box in sorted order and attaches headers and dividers by category
        filteredSkills() {
            let userTags = null;
            let skillTags = null;
            let domainTags = null;
            let industryTags = null;

            // Add a header message based on how many items are selected
            let filteredItems = [];
            if (this.selectedSkills.length < 25) {
                filteredItems = [ { header: 'Start typing to search from the list below, or type to add your own custom tag'} ] ;
            }

            if (this.popularityOrder) {
                // Add user tags at the top
                userTags = this.skills.filter(i => i.type === 'user').sort((a, b) => {
                    return a.text < b.text;
                });
                Array.prototype.push.apply(filteredItems, userTags);

                // Add the rest of the tags in popularity order except the user tags
                const nonUserTags = this.skills.filter(i => i.type !== 'user')
                Array.prototype.push.apply(filteredItems, nonUserTags);
            } else {
                // Get the specific tags by category
                userTags = this.skills.filter(i => i.type === 'user').sort((a, b) => {
                    return a.text < b.text;
                });

                skillTags = this.skills.filter(i => i.type === 'skill').sort((a, b) => {
                    return a.text < b.text;
                });
                domainTags = this.skills.filter(i => i.type === 'domain').sort((a, b) => {
                    return a.text < b.text;
                });
                industryTags = this.skills.filter(i => i.type === 'industry').sort((a, b) => {
                    return a.text < b.text;
                });
            
                // determine is we have any items for a particular category by counting how many are in the selected list, and compare to the total lists above
                const selectedUserTagsCount = this.selectedSkills.filter(i => i.type === 'user').length;
                const selectedSkillTagsCount = this.selectedSkills.filter(i => i.type === 'skill').length;
                const selectedDomainTagsCount = this.selectedSkills.filter(i => i.type === 'domain').length;
                const selectedIndustryTagsCount = this.selectedSkills.filter(i => i.type === 'industry').length;

                // Rebuild the list with custom user tags first
                const skillHeader = { header: 'Skills' };
                if (userTags && userTags.length) {
                    // Only add header if still some to choose
                    if (userTags.length > selectedUserTagsCount && !this.popularityOrder) {
                        filteredItems.push({ header: 'Your custom tags' });
                        filteredItems.push({ divider: true });
                    }
                    Array.prototype.push.apply(filteredItems, userTags);
                }

                // Then order other tags by skills, then domains, then industries
                if (skillTags && skillTags.length) {
                    if (skillTags.length > selectedSkillTagsCount && !this.popularityOrder) {
                        filteredItems.push(skillHeader);
                        filteredItems.push({ divider: true });
                    }
                    Array.prototype.push.apply(filteredItems, skillTags);
                }

                if (domainTags && domainTags.length) {
                    if (domainTags.length > selectedDomainTagsCount && !this.popularityOrder) {
                        filteredItems.push({ header: 'Domain Expertise'});
                        filteredItems.push({ divider: true });
                    }
                    console.log('applying', domainTags);
                    Array.prototype.push.apply(filteredItems, domainTags);
                }

                if (industryTags && industryTags.length) {
                        if (industryTags.length > selectedIndustryTagsCount && !this.popularityOrder) {
                        filteredItems.push({ header: 'Industries'});
                        filteredItems.push({ divider: true });
                    }
                    Array.prototype.push.apply(filteredItems, industryTags);
                }
            }

            return filteredItems;
        },

        skillColour() {
            return (type) => {
                // User tags are always green
                if (type === 'user') return 'green';
                
                // Keep other tags all same colour if ordering by popularity
                if (this.popularityOrder) {
                    return 'indigo';
                } else {
                    // Otherwise give each a specific color
                    if (type === 'skill') return 'teal';
                    if (type === 'domain') return 'purple';
                    if (type === 'industry') return 'indigo';
                }
            }
        },

        countryName() {
            return this.country && this.country.countryName ? this.country.countryName : '';
        },

        countryCode() {
            return this.country && this.country.countryShortCode ? this.country.countryShortCode : '';
        },

        regionName() {
            return this.region && this.region.name ? this.region.name : '';
        }
    },

    beforeMount() {
        // Check login state or redirect
        if (!this.isLoggedIn) {
            this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
        }

        // Populate starting form fields from the store
        this.populateFormDefaults();

        // Fetch the user bio and skills details
        UserAPIService.getSkills(this.userId)
        .then( (response) => {
            // If the server was unreachable or timedout, the request is cancelled and goes into the then handler
            // Trap this as a NoResponseAPIError
            if (!response || !response.message) {
                throw new NoResponseAPIError();
            }

            // Make sure our expected skills are in the response
            if (!response.user || !response.skills || !response.skills.default || !response.skills.user || !response.skills.selected) {
                throw 'There was a problem fetching your data, please try again later';
            }
            
            // Set the user-related fields
            this.discord = response.user.discord ? response.user.discord : '';
            this.headline = response.user.headline ? response.user.headline : '';
            this.bio = response.user.bio ? response.user.bio : '';
            this.workingOn = response.user.workingOn ? response.user.workingOn : '';
            
            // Stash these user values so we can reset form
            this.userDiscord = this.discord;
            this.userHeadline = this.headline;
            this.userBio = this.bio;
            this.userWorkingOn = this.workingOn;
            
            // Stash the list of user skills so we can check if anything changed
            this.originalUserSkills = response.skills.user;

            // Stash the list of default skills
            this.originalSkills = response.skills.default;
            
            // Build the list of all skills for the combobox
            this.skills = [...JSON.parse(JSON.stringify(this.originalUserSkills)), ...JSON.parse(JSON.stringify(this.originalSkills))];     // deep copy

            // Set the list of selected skills
            this.selectedSkills = response.skills.selected;

            // Stash a copy of the selected items so we can reset form
            this.userSelectedSkills = JSON.parse(JSON.stringify(this.selectedSkills));
        })
        .catch( (error) => {
            // Clear the snackbar
            this.clearSnackBar();

            if (error instanceof NoResponseAPIError ) {
                this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
            } else if (error instanceof UnsupportedMediaAPIError) {
                this.errorMessage = 'We encountered a server problem, please try again later';
            } else if (error instanceof BadMethodAPIError) {
                this.errorMessage = 'We encountered a technical problem, please try again later';
            } else if (error instanceof BadRequestAPIError) {
                this.errorMessage = 'We encountered a problem, please try again later';
            } else if (error instanceof AuthenticationAPIError) {
                this.errorMessage = 'We encountered an authentication problem, please logout and try again';
            } else if (error instanceof InternalServerAPIError) {
                this.errorMessage = 'We encountered a server problem, please try again later';
            } else {
                this.errorMessage = 'There was a problem, please try again later';
            }

            // Show snackbar error message
            this.setSnackBar({ snack: this.errorMessage });
        });
    },

    mounted() {
        // Highlight any unentered data
        if (!this.isProfileSetup) {
            this.$refs.form.validate();
        }
    },

    watch: {
        // whenever login state changes and we're no longer logged in, we redirect to login page
        isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
            if (!newIsLoggedIn && oldIsLoggedIn) {
                this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
            }
        },

        // Watch for user-defined new skills and create them to the list
        selectedSkills(val, prev) {
            if (val.length === prev.length) return

            // Determine if we added a new item (it will be a string not an item)
            this.selectedSkills = val.map(v => {
                if (typeof v === 'string') {
                    // Check if this matches an existing custom tag and add that, or create a new tag
                    const match = this.skills.find(i => {
                        return i.text.toString().toLowerCase() === v.toString().toLowerCase()
                    });

                    // Use the match if found
                    if (match) {
                        return Object.assign({}, match);
                    } else {
                        // Create a new tag
                        v = {
                            text: v,
                            type: 'user',
                            tagid: -1,
                        }
                        this.skills.push(v);
                    }
                }

                return v;
            });
        },
    },

    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
        }),

        navigateBack() {
            this.$router.go(-1);
        },

        discordHelp() {
            window.open('https://www.alphr.com/discord-find-user-id/');
        },

        // Edit the name of a skill tag - we need to stash and save if editing a tag already saved on the server so it can update server side too
        editSkillTag(index, item) {
            if (!this.editing) {
                // Started editing
                this.editing = item;
                this.editingIndex = index;
            } else {
                // Finished editing
                
                // If we're editing a saved user tag, we add it to updatedUserTags so we can submit to server
                if (item.type === 'user' && item.tagid !== -1) {
                    // Stopped editing - check if the text changed from the original value
                    const originalItem = this.originalUserSkills.find(i => i.tagid === item.tagid);

                    // See if the item is already in update list
                    const updatedIndex = this.updatedUserTags.findIndex( i => {
                        if (i.text === item.text && i.type === item.type && i.tagid === item.tagid) {
                            return true;
                        }
                    });

                    // Remove this item from the list first
                    if (updatedIndex !== -1) {
                        this.updatedUserTags.splice(updatedIndex, 1);
                    }

                    // Now add the new edited item back onto the end if it's changed
                    if (!originalItem || originalItem.text !== item.text) {
                        this.updatedUserTags.push(item);
                    }
                }

                // Reset the edited item
                this.editing = null;
                this.editingIndex = -1;
            }
        },

        // Remove a custom user tag from the list of available tags
        deleteSkillTag(item) {
            // Find this matching item's index
            const index = this.skills.findIndex( i => {
                if (i.text === item.text && i.type === item.type && i.tagid === item.tagid) {
                    return true;
                }
            });

            // Delete this index
            if (index !== -1) {
                // If this item has a tagid, it means it's been saved server-side already, so we save these details so we can post to API to delete
                if (this.skills[index] && this.skills[index].tagid !== -1) {
                    this.deletedUserTags.push(this.skills[index]);
                }
                
                // Now delete this item
                this.skills.splice(index, 1);
            }

            // If this item is in the updatedTags list, there's no need to update it's name anymore, remove it
            const updatedIndex = this.updatedUserTags.findIndex( i => {
                if (i.tagid === item.tagid && item.type === 'user') {
                        return true;
                }
            });
            if (updatedIndex !== -1) {
                // Remove this item from the updatedUserTags array
                this.updatedUserTags.splice(updatedIndex, 1);
            }
        },

        // Filter the list of skill stags to remove items that are already selected
        filterSkillTags(item, queryText, itemText) {

            // Ignore headers and dividers
            if (item.header || item.divider) return false
            
            // Local function to check if item is empty and return empty string instead of object
            const hasValue = val => val != null ? val : '';
            const text = hasValue(itemText);
            const query = hasValue(queryText);

            // Check if the item matches the query
            return text.toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1;
        },

        // Selects a text area so we can make it auto-grow when focussed
        selectTextArea(fieldname) {
            this.selectedTextArea = fieldname;
        },

        deselectTextArea() {
            this.selectedTextArea = null;
        },

        // Construct select-compatible region and country from the defaults in the store
        populateFormDefaults() {
            // Username
            this.firstName = this.userFirstname,
            this.lastName = this.userLastname;

            // Country (only update if changed so it doesn't force the region to reset)
            if (this.userCountryName && this.userCountryCode) {
                if (this.country && this.country.countryName !== this.userCountryName) {
                    this.country = {
                        countryName: this.userCountryName,
                        countryShortCode: this.userCountryCode,
                    }
                }
            } else {
                this.country = {};
            }

            // Region - do on next tick since resetting country if it changed will force a region reset to ''
            this.$nextTick(()=> {
                if (this.userRegionName && this.userRegionCode) {
                    this.region = {
                        name: this.userRegionName,
                        shortCode: this.userRegionCode,
                    }
                } else {
                    this.region = {};
                }
            });

            // Bio fields
            this.discord = this.userDiscord;
            this.headline = this.userHeadline;
            this.bio = this.userBio;
            this.workingOn = this.userWorkingOn;

            // Reset the list all skills for the combobox from the original
            this.skills = [...JSON.parse(JSON.stringify(this.originalUserSkills)), ...JSON.parse(JSON.stringify(this.originalSkills))];     // deep copy
            
            // Selected Skills
            this.selectedSkills = JSON.parse(JSON.stringify(this.userSelectedSkills));

            // Clear out any updated or deleted user tags
            this.deletedUserTags = [];
            this.updatedUserTags = [];
        },

        async initFileUploader() {
            // Setup the dropzone listeners
            window.addEventListener('dragenter', () => {
                this.showDropZone = true;
            }, false);
            const dropZone = this.$refs.dropzone;

            // Test drag is allowed
            dropZone.addEventListener('dragenter', (e) => {
                e.dataTransfer.dropEffect = 'copy';
                e.preventDefault();
            }, false);
            dropZone.addEventListener('dragover', (e) => {
                e.dataTransfer.dropEffect = 'copy';
                e.preventDefault();
            }, false);

            // Hide the dropzone on drop or leave
            dropZone.addEventListener('dragleave', () => {
                this.showDropZone = false;
            }, false);
            dropZone.addEventListener('drop', async () => {
                this.showDropZone = false;

                // Remove any current file first so the upload works!
                if (this.$refs.fileUploader.getFiles().length) {
                    await this.$refs.fileUploader.removeFiles();
                }
            }, false);

            // Add the poster image
            await this.initCurrentProfilePic(true);
        },

        // Check if there are any file upload errors
        isFileErrors() {
            if (this.$refs.fileUploader) {
                const files = this.$refs.fileUploader.getFiles();
                let errors = false;
                files.forEach((f) => {
                if (f.status && f.status === 8) {
                    errors = true;
                }
                });
                return errors;
            }
            return false;
        },

        // Initialise or resets the file field with the currently selected profile pic
        initCurrentProfilePic(isFirst=false) {
            // Add the new file based on the current profile picture
            if (this.userProfileImgPath && (isFirst || !isFirst && this.filesDirty)) {
                return this.$refs.fileUploader.addFile(this.userProfileImgPath, {
                    type: "local",

                    // mock file information so doesn't go to server and validate it's real
                    file: {
                        name: "mock.png",
                        size: 3500000,
                        type: "image/png",
                    },

                    // pass poster property
                    metadata: {
                        poster: this.userProfileImgPath,
                        isPoster: true,
                    },
                });
            }
        },

        async resetFileUploader() {
            // Set the current profile pic as the poster
            await this.initCurrentProfilePic();

            // Reset the dirty state
            this.filesDirty = false;
        },

        async resetForm() {
            // Populate starting form fields from the store
            this.populateFormDefaults();

            // Reset files
            await this.resetFileUploader();
        },

        // Disable send button whilst uploading
        addFileStart() {
            this.disableSendButton(true);

            // Set flag that files has changed
            this.filesDirty = true;
        },

        removeFile() {
            // update the nmber of files
            this.numFileUploads = this.$refs.fileUploader.getFiles().length;

            // Enable save button
            if ((!this.userProfileImgPath.length && this.numFileUploads) || (this.userProfileImgPath.length && !this.numFileUploads)) {
                this.filesDirty = true;
            } else {
                this.filesDirty = false;
            }

            // Reset the files metadata if no more files
            if (!this.numFileUploads) {
                variantOptions.variants = [];
            }
        },

        onErrorFiles(error, file) {
            if (file) {
                this.disableSendButton(true);
            }
        },

        processFilesComplete() {
            // Enable file button if no errors
            const buttonState = !this.isFileErrors();
            this.disableSendButton(!buttonState);

            // Reset the files metadata
            variantOptions.variants = [];

            // update the nmber of files
            this.numFileUploads = this.$refs.fileUploader.getFiles().length;
        },

        disableSendButton(state) {
            this.sendingFiles = state;
        },

        imageTransformAfterCreateBlob: (blob, file) => new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = URL.createObjectURL(blob);
            img.onerror = (err) => {
                reject(err);
            };
            img.onload = () => {
                // Attach the image dimensions to our metadata upload
                if (variantOptions.variants && img.naturalWidth && img.naturalHeight) {
                    variantOptions.variants.push({
                        name: file.name,
                        width: img.naturalWidth,
                        height: img.naturalHeight,
                        size: blob.size,
                        type: blob.type,
                    });
                }
                URL.revokeObjectURL(blob);
            };
            resolve(blob);
        }),

        // Process a form submit
        async submit() {
            if (this.formButtonsEnabled) {
                // Construct our post data - send as multipart FormData along with attachments
                const countryCode = this.country && this.country.countryShortCode ? this.country.countryShortCode : '';
                const regionCode = this.region && this.region.shortCode ? this.region.shortCode : '';

                // Setup our submit data
                const formData = new FormData();
                formData.append('userid', this.userId);
                formData.append('countryName', this.country && this.country.countryName ? this.country.countryName : '');
                formData.append('countryCode', countryCode);
                formData.append('regionName', this.region && this.region.name ? this.region.name : '');
                formData.append('regionCode', regionCode);
                formData.append('discord', this.discord);
                formData.append('headline', this.headline);
                formData.append('bio', this.bio);
                formData.append('workingon', this.workingOn);

                // Format our skills fields for submitting (remove the popularity field)
                const submitSelectedSkills = this.selectedSkills.map((i) => {
                    return {
                        text: i.text,
                        type: i.type,
                        tagid: Number(i.tagid),
                    }
                });
                formData.append('selectedSkills', JSON.stringify(submitSelectedSkills));

                // Are we deleting any custom user skills?
                const submitDeletedSkills = this.deletedUserTags.map((i) => {
                    return {
                        text: i.text,
                        type: i.type,
                        tagid: Number(i.tagid),
                    }
                });
                formData.append('deletedSkills', JSON.stringify(submitDeletedSkills));

                // Are we deleting any custom user skills?  Just collapse into their respective tagids
                const submitUpdatedSkills = this.updatedUserTags.map((i) => {
                    return {
                        text: i.text,
                        type: i.type,
                        tagid: Number(i.tagid),
                    }
                });
                formData.append('updatedSkills', JSON.stringify(submitUpdatedSkills));

                // We don't send the name fields if we're setting up the profile
                if (this.isProfileSetup) {
                    formData.append('setup', 1);
                } else {
                    formData.append('firstName', this.firstName);
                    formData.append('lastName', this.lastName);
                }

                // Check if we are uploading any files - first check for pre-uploaded files
                let useRaw = true;
                let files = null;
                if (typeof this.$refs.fileUploader.getFiles === 'function') {
                    files = this.$refs.fileUploader.getFiles();
                    useRaw = false;
                } else {
                    files = this.$refs.fileUploader.files;
                }

                // if we had a profile pic originally, but now no file selected, we assume you're removing it - add a param so we can detect
                if (this.userProfileImgPath.length && !files.length) {
                    if (!await this.$refs.confirm.open("Confirm", "Are you sure you want to remove your current profile pic?")) {
                        this.resetFileUploader();
                        return;
                    } else {
                        formData.append('remove', true);
                    }
                }

                // Attach either the serverId for pre-uploaded files, or attach the underlying file object
                for (let i = 0; i < files.length; i++) {
                    const f = files[i];
                    if (!useRaw && f.serverId) {
                        formData.append('files[]', f.serverId);
                    } else if (!useRaw) {
                        formData.append('files[]', f.file);
                    } else {
                        formData.append('files[]', f);
                    }
                }

                // Clear the snackbar
                this.clearSnackBar();

                // Start uploading
                this.pending = true;

                // Build fields for the API signature
                const fields = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    countryCode,
                    regionCode,
                };

                // Send to API endpoint
                UserAPIService.editProfile(this.userId, this.rememberMe, fields, formData)
                .then((response) => {
                    // If the server was unreachable or timedout, the request is cancelled and goes into the then handler - trap this as a NoResponseAPIError
                    if (!response || !response.message) {
                        throw new NoResponseAPIError();
                    }

                    // User store will have been updated with the new profile picture
                    this.errorMessage = '';

                    // Show snackbar success message
                    this.setSnackBar({ snack: 'Profile updated', color:"success" });

                    // All done, reset form fields
                    this.resetFileUploader();

                    // If this is setup mode, we go to the user home page next
                    if (this.isProfileSetup) {
                        this.$router.push({ name: 'dashboard', params: { 'from': 'register' } });
                    }

                    // Will return the new list of selected skills/tags and user tags, set these in scope
                    if (response.skills && response.skills.user && response.skills.default && response.skills.selected) {
                        // Save these new skills as the original skills
                        this.originalUserSkills = response.skills.user;

                        // Stash the list of default skills
                        this.originalSkills = response.skills.default;
                        
                        // Build the list of all skills for the combobox
                        this.skills = [...JSON.parse(JSON.stringify(this.originalUserSkills)), ...JSON.parse(JSON.stringify(this.originalSkills))];     // deep copy

                        // Set the list of selected skills
                        this.selectedSkills = response.skills.selected;

                        // Stash a copy of the selected items so we can reset form
                        this.userSelectedSkills = JSON.parse(JSON.stringify(this.selectedSkills));
                    }

                    // Populate the bio fields from the response, and stash so we know if it updated from these new values
                    if (response.user) {
                        this.discord = response.user.discord;
                        this.headline = response.user.headline;
                        this.bio = response.user.bio;
                        this.workingOn = response.user.workingOn;
                        
                        this.userDiscord = response.user.discord;
                        this.userHeadline = response.user.headline;
                        this.userBio = response.user.bio;
                        this.userWorkingOn = response.user.workingOn;
                    }
                })
                .catch((error) => {
                    // Clear the snackbar
                    this.clearSnackBar();

                    if (error instanceof NoResponseAPIError ) {
                        this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                    } else if (error instanceof UnsupportedMediaAPIError) {
                        this.errorMessage = 'We encountered a server problem saving your details, please try again later.';
                    } else if (error instanceof BadMethodAPIError) {
                        this.errorMessage = 'We encountered a problem saving your details, please try again later.';
                    } else if (error instanceof BadRequestAPIError) {
                        this.errorMessage = 'We encountered a problem saving your details, please try again.';
                    } else if (error instanceof AuthenticationAPIError) {
                        this.errorMessage = 'We encountered a problem saving your details, please try again later.';
                    } else if (error instanceof CredentialsRevokedAPIError) {
                        this.errorMessage = 'You are not allowed to save your details, please contact us for support.';
                    } else if (error instanceof InternalServerAPIError) {
                        this.errorMessage = 'We encountered a server problem saving your details, please try again later.';
                    } else {
                        this.errorMessage = 'There was a problem saving your details, please try again.';
                    }

                    // We reset the form incase there was a server error and the data is no longer on the server
                    this.resetFileUploader();

                    // Show snackbar error message
                    this.setSnackBar({ snack: this.errorMessage });
                })
                .finally(() => {
                    // Reset server side submit state
                    this.pending = false;
                });
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.dropzone {
    box-sizing: border-box;
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    background: rgba(#60a7dc,.5);
    border: 11px dashed #60a7dc;
}

.dropzone.show {
    display: block;
}

</style>

<style>
.filepond--drop-label.filepond--drop-label label {
    font-size: 14px;
}

.v-working-on .v-select__selections {
    margin-top: 10px;
    margin-bottom: 10px;
}

.v-working-on div[role=combobox] .v-input__append-inner {
    margin-top: 17px;
}

.v-skillcombobox .v-select__selections {
    margin-top: 10px;
    margin-bottom: 10px;
}

.v-skillcombobox .v-select__selections {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Shift label when not selected to fit new box height */
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small .v-select__selections {
    min-height: 52px;
}

/* Push label down to match increase min-height to allow for chips */
.v-skillcombobox .v-label {
    margin-top: 10px;
}

/* Shift label up when the combobox is selected */
.v-skillcombobox.v-input--is-label-active .v-label, .v-skillcombobox.v-select--is-menu-active .v-label {
    margin-top: 0px;
}

/* center align the arrow dropdown for our added margin */
.v-skillcombobox div[role=combobox] .v-input__append-inner {
    margin-top: 27px;
}

</style>

/**
 * Front-end view controller for the admin home page/dashboard
 *
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * Note:
 * 
 * Requires the user to be logged in before accessing this page.
 * Also refreshes the user details from the UserAPI when the page is reloaded/accessed
 *
 * @file   Front-end view controller for the admin home page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container v-if="isAuthorised">
        <v-row align="start" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col>
                <!-- Title -->
                <h1>&#x1f512;&nbsp;Admin Home</h1>
                
                <!-- User Data -->
                <p>isLoggedIn: {{isLoggedIn}}</p>
                <p>isAdmin: {{isAdmin}}</p>
                
                <!-- Logout Link -->
                <p><router-link :to="{ name: 'logout' }">Logout</router-link></p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters, mapActions } from 'vuex';

// API Connectors
import UserAPI from '@/services/UserAPIService.js';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';

// The main admin home page component
export default {
    name: 'AdminHome',

    data() {
        return {
                errorMessage: '',
        };
    },
    
    computed: {
        // Map our Vuex getters
        ...mapGetters({
            isLoggedIn: 'Auth/isLoggedIn',
            isAdmin: 'Auth/isAdmin',
        }),

        isAuthorised() {
            return this.isLoggedIn && this.isAdmin;
        }
    },

    watch: {
        // whenever login state changes and we're no longer logged in, we redirect to login page
        isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
            if (!newIsLoggedIn && oldIsLoggedIn) {
                this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
            }
        },

        // whenever admin state changes, we redirect to notAuthorised page
        isAdmin(newIsAdmin, oldIsAdmin) {
            if (!newIsAdmin && oldIsAdmin) {
                this.$router.push({ name: 'notAuthorised'});  
            }
        }
    },

    // On page load, refresh latest data from the UserAPI
    async beforeMount() {
        // Try to refresh user's data
        if (this.isLoggedIn) {
            const userid = await this.$store.getters['User/userid'];
            UserAPI.getUser(userid)
            .then( (response) => {
                // If the server was unreachable or timedout, the request is cancelled and goes into the then handler
                // Trap this as a NoResponseAPIError
                if (!response || !response.message) {
                    throw new NoResponseAPIError();
                }
                // Make sure we're authorised
                if (!this.isAdmin) {
                    this.$router.push({ name: 'notAuthorised'});  
                }
            })
            .catch( (error) => {
                // Clear the snackbar
                this.clearSnackBar();

                if (error instanceof NoResponseAPIError ) {
                    this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                } else if (error instanceof UnsupportedMediaAPIError) {
                    this.errorMessage = 'We encountered a server problem, please try again later';
                } else if (error instanceof BadMethodAPIError) {
                    this.errorMessage = 'We encountered a technical problem, please try again later';
                } else if (error instanceof BadRequestAPIError) {
                    this.errorMessage = 'We encountered a problem, please try again later';
                } else if (error instanceof AuthenticationAPIError) {
                    this.errorMessage = 'We encountered an authentication problem, please logout and try again';
                } else if (error instanceof InternalServerAPIError) {
                    this.errorMessage = 'We encountered a server problem, please try again later';
                } else {
                    this.errorMessage = 'There was a problem, please try again later';
                }

                // Show snackbar error message
                this.setSnackBar({ snack: this.errorMessage });
            });  
        } else {
            // Back to login page
            this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
        }
    },

    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
        }),
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>

/**
 * Wrapper component for zxcvbn password field
 *
 * Passes through all attributes, events, and slots from the parent
 * 
 * @file   Wrapper component for zxcvbn password field
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
<template>
    <!-- Pass through normal slots -->
    <div class="strongpassword">
        <slot name="default">
            <Password v-model="password" v-bind="$attrs" :toggle="true" :placeholder="$attrs.placeholder" @score="saveScore" @input="onInput" v-on="$listeners" />
        </slot>
        <slot name="messages">
            <!-- Use a "mirror" v-input to run through local validation and pass the results back up to the parent -->
            <v-input style="margin-top: -20px; margin-left: 10px;" v-model="password" v-bind="{ ...$attrs, ...commonAttrs }" v-on="$listeners" />
        </slot>
    </div>
</template>

<script>
// Import validation libraries
import { required, minLength, maxLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Password from 'vue-password-strength-meter';

// The Strong Password component
export default {
    name: 'StrongPassword',

    inheritAttrs: false,
  
    data() {
        return {
            // Form fields
            password: '',
            
            // Password strength fields
            score: 0,
        };
    },

    // Our password validation component
    components: { Password },

    // Computed params
    computed: {
        valid() {
            return !this.error;
        },
        
        error() {
            return this.v$.password.$error && (this.v$.password.required.$invalid || this.v$.password.maxLength.$invalid || this.v$.password.minScore.$invalid);
        },

        errorCount() {
            let count = 0;
            if (this.v$.password.$error && this.v$.password.required.$invalid) {
                count++;
            } else {
                if (this.v$.password.$error && this.v$.password.maxLength.$invalid) {
                    count++;
                } 
            
                if (this.v$.password.$error && this.v$.password.minScore.$invalid) {
                    count++;
                }
            }
            return count;
        },

        errorMessages() {
            let msgs = [];
            if (this.v$.password.$error && this.v$.password.required.$invalid) {
                msgs.push('Password is required');
            } else {
                if (this.v$.password.$error && this.v$.password.maxLength.$invalid) {
                    msgs.push('Passwords should be no longer than 191 characters');
                } 
            
                if (this.v$.password.$error && this.v$.password.minScore.$invalid) {
                    msgs.push('Please choose a stronger password');
                }
            }

            return msgs;
        },

        commonAttrs() {
            return {
                errorCount: this.errorCount,
                errorMessages: this.errorMessages,
                error: this.error,
                valid: this.valid,
            }
        },
    },

    // Setup form validators
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            password: { required, minLength: minLength(7), maxLength: maxLength(191), minScore() { return this.score > 1; } },
        }
    },

    methods: {
        onInput(input) {
            this.v$.password.$touch();

            // Push up to parent
            this.$emit('input', input);
        },
   
        // Save the password score for the validator
        saveScore(score) {
            this.score = score;
            this.$emit('score', score);
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Password {
    max-width: none;
}

.strongpassword {
    margin-bottom: 10px;
}

</style>

<!-- Non-scoped styles - match the password field with vuetify as much as possible -->
<style>
input.Password__field {
    background-color: rgba(0, 0, 0, 0.06) !important;
    min-height: 56px;
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
    padding: 0 12px;
    border-color: rgba(0, 0, 0, 0.42);
    border-style: solid;
    border-width: 0 0 thin 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    color: rgba(0, 0, 0, 0.6);
}

input.Password__field:focus {
    caret-color: #1976d2 !important;
    border-color: #1976d2;
}

</style>

/**
 * Front-end view controller for the forgot check email page
 *
 * Render's the page template, and processes any UI functions and interactivity
 *
 * @file   Front-end view controller for the forgot check email page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container>
        <v-row align="start" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col>
                <!-- Title -->
                <h1>Check Your Email!</h1>
        
                <!-- Blurb -->
                <p>We've sent an email to <strong>{{email}}</strong> with instructions on how to reset your password.</p>
                <p>If you're not sure this was the right email address you signed up with, <router-link :to="{ name: 'forgot' }">try another email</router-link></p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'NotFound',

   // URL parameters passed as properties /:email
    props: [
        'email'
    ],
};
</script>

<style scoped>
h1 {
    margin: 40px 0 0;
}
a {
    color: #42b983;
    cursor: pointer;
}
</style>
/**
 * API Middleware for File-related API functions (exluding file upload)
 *
 * Contains transport-level functions to make API calls and process the responses,
 * 
 * @file   API middleware for file-related functions
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */

// Common includes used in this file
import axios from '@/common/axios';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import UnsupportedMediaAPIError from '@/errors/unsupportedmediaapierror';

// We use the filepond library to do file uploading
import vueFilePond, { setOptions } from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';

// Import file upload plugins
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileMetaData from 'filepond-plugin-file-metadata';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Functions to initialiase the file uploader component
const createFileUploader = () => {
    // Create the underlying file upload component and initialise any plugins
    return vueFilePond(
        FilePondPluginImageCrop,
        FilePondPluginImageResize,
        FilePondPluginImageExifOrientation,
        FilePondPluginFileMetaData,
        FilePondPluginImageTransform,
        FilePondPluginFileValidateType,
        FilePondPluginImagePreview,
        FilePondPluginFilePoster,
        FilePondPluginFileValidateSize,
    );
}

const setFileUploaderOptions = (options) => {
    setOptions(options);
}

const profilePicOptions = (variantOptions) => {
    return {
        // Image variants setup
        imageResizeTargetWidth: 200,
        imageResizeTargetHeight: 200,
        imageResizeMode: 'contain',
        imageTransformVariantsIncludeOriginal: true,
        imageTransformVariantsDefaultName: 'thumb_large_',
        imageTransformVariants: {
            thumb_medium_: (transforms) => {
                const newTransforms = JSON.parse(JSON.stringify(transforms));
                newTransforms.resize.size.width = 100;
                return newTransforms;
            },
            thumb_small_: (transforms) => {
                const newTransforms = JSON.parse(JSON.stringify(transforms));
                newTransforms.resize.size.width = 40;
                return newTransforms;
            },
        },
        fileMetadataObject: variantOptions,
    
        // One file only
        allowMultiple: false,
        maxFiles: 1,
        
        // Basic config
        checkValidity: true,
        dropOnElement: false,
        dropOnPage: true,
        acceptedFileTypes: ['image/*'],
        labelIdle: `Drag & drop your profile picture or <span class="filepond--label-action">Browse</span>`,
    
        allowImagePreview: true,
        allowImageTransform: true,
        allowImageResize: true,
        allowImageCrop: true,
        allowImageExifOrientation: true,
        allowFilePoster: true,
        allowImageEditor: false,
            
        // Sizing Options
        imagePreviewHeight: 190,
        imageCropAspectRatio: '1:1',
        filePosterHeight: 190,
        
        // Styling
        stylePanelLayout: 'compact circle',
        styleLoadIndicatorPosition: 'center bottom',
        styleProgressIndicatorPosition: 'center bottom',
        styleButtonRemoveItemPosition: 'center bottom',
        styleButtonProcessItemPosition: 'center bottom',

        // File sizing options
        maxFileSize: '8MB',
    }
}

// Configure the file uploader post config by adding the access Token to the header
const configureFileUploader = (accessToken) => {
    const thisAccessToken = 'Bearer ' + (accessToken ? accessToken : null);
    return {
        url: `${process.env.VUE_APP_BASE_URL_API}file/upload`,
        withCredentials: true,
        headers: {
            'Authorization' : thisAccessToken,
        }
    };
};

/*
 * function downloadFile ()
 *
 * API call to download a secured file
 *
 */
const downloadFile = async (params) => {
    // Check we have our expected parameters set in the params
    if (!params || !params.userid) {
        return Promise.reject();
    }

    // For downloads, we process the response as a blob so we can force the browser save process
    // For streams (e.g. img src), we set our response to an array buffer so can convert to base64
    const responseType = params.stream ? 'arraybuffer' : 'blob';

    // We issue a get request and embed our parameters in the URL
    return axios.get('file/download', { params, responseType })
    .then(response => {
        // Return the binary image data for streams
        if (params.stream) {
            return Promise.resolve(response.data);
        }

        // Extract filename from content-disposition header
        const disposition = response.request.getResponseHeader('Content-Disposition');
        const regEx = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
        const match = regEx.exec(disposition);
        const filename = match[1] ? match[1] : '';

        // To force the download, we create a faux link , attach to DOM, click it, then remove it
        const blobData = [response.data];
        const blob = new Blob(blobData, { type: 'application/octet-stream' });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were
            // revoked by closing the blob for which they were created.
            // These URLs will no longer resolve as the data backing
            // the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const blobURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', filename);

            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }
  
        // Return the callback promise
        return Promise.resolve(filename);
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded
            if (error.response.status === 405 && error.response.data.reason === 'BAD_METHOD') {
                throw new BadMethodAPIError(error.response, error.request. error);
            } else if (error.response.status === 415) {
                throw new UnsupportedMediaAPIError(error.response, error.request. error);
            } else if (error.response.status === 400 && error.response.data.reason === 'BAD_REQUEST') {
                throw new BadRequestAPIError(error.response, error.request. error);
            } else if (error.response.status === 401 && error.response.data.reason === 'AUTHENTICATION_ERROR') {
                throw new AuthenticationAPIError(error.response, error.request. error);
            } else if (error.response.status === 500 && error.response.data.reason === 'INTERNAL_SERVER_ERROR') {
                throw new InternalServerAPIError(error.response, error.request, error);
            } else {
                throw new Error(error);    
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new NoResponseAPIError(error.request, error);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error);
        }
    });
}

// Export each API endpoint
export default {
    // File uploader functions
    createFileUploader,
    setFileUploaderOptions,
    configureFileUploader,
    profilePicOptions,
    
    // API endpoints
    downloadFile,
};
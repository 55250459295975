/**
 * Value transaction component
 *
 * This renders a reusable transaction component box show a summary of a value transaction
 *
 *
 * @file   Value transaction component
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 *
 */
<template>
    <v-hover v-slot="{ hover }">
        <v-card :class="`value-transaction ${alignment}`" :elevation="clickable && !isCurrent ? (hover ? 3 : 1) : 1" :color="isCurrent ? 'grey lighten-3' : 'white'">
            <v-container class="value-transaction-container" :class="{ clickable: clickable && !isCurrent }" @click="onTap">
                <v-row>
                    <v-col cols="4">
                        <v-container>
                            <v-row>
                                <!-- From user -->
                                <v-col cols="4" align="center" class="pa-0">
                                    <v-avatar class="value-transaction-user" size="30" v-if="fromProfileImgSrc.length">
                                        <img :src="fromProfileImgSrc" />
                                    </v-avatar>
                                    <v-avatar v-else color="grey lighten-1" size="30">
                                        <span class="white--text text-h6 initials ma-auto">{{fromUserInitials}}</span>
                                    </v-avatar>
                                </v-col>

                                <!-- Arrow -->
                                <v-col cols="4" class="pa-0">
                                    <div class="my-auto mx-auto text-center value_arrow">⟶</div>
                                </v-col>

                                <!-- To user -->
                                <v-col cols="4" align="center" class="pa-0">
                                    <v-avatar class="value-transaction-user" size="30" v-if="toProfileImgSrc.length">
                                        <img :src="toProfileImgSrc" />
                                    </v-avatar>
                                    <v-avatar v-else color="grey lighten-1" size="30">
                                        <span class="white--text text-h6 initials ma-auto">{{toUserInitials}}</span>
                                    </v-avatar>
                                </v-col>

                                <!-- Value Rating -->
                                <v-col cols="12" align="center" class="mt-2 pa-0">
                                    <div class="text-center" v-if="confirmed && actualValue && !$vuetify.breakpoint.smAndDown">
                                        <v-rating v-model="actualValue" background-color="orange lighten-3" color="orange" size="18" dense readonly half-increments></v-rating>
                                    </div>

                                    <!-- Small layouts show number in stead of stars-->
                                    <div class="text-center text-body-2 my-5" v-if="confirmed && actualValue && $vuetify.breakpoint.smAndDown">
                                        {{actualValue}} <v-icon color="orange lighten-3" small>mdi-star</v-icon>
                                    </div>

                                    <div class="text-center disputed mt-4" v-if="disputed">
                                        <v-icon slot="icon" color="red lighten-1" dense class="mr-1">mdi-alert</v-icon> disputed
                                    </div>

                                    <div class="text-center pending mt-4" v-if="pending">
                                        <v-icon slot="icon" color="amber lighten-1" dense class="mr-1">mdi-information</v-icon> pending
                                    </div>
                                </v-col>
                                
                                <!-- Value -->
                                <v-col cols="12" align="center" class="mt-0 pa-0" v-if="actualValue">
                                    <div class="text-center value">
                                        {{valueLabel}}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>

                    <!-- Name, Type, and date -->
                    <v-col cols="8" align="center" class="pl-0" v-if="createdAt">
                        <div class="text-left text-body-1 font-weight-bold name">{{capitalize(fromFirstname)}} {{capitalize(fromLastname)}} to {{capitalize(toFirstname)}} {{capitalize(toLastname)}}</div>
                        <div class="text-left text-overline capital-type">{{capitalize(type)}} Capital</div>
                        <div class="text-left text-body-2 date">{{daysAgo}}, on {{date}}</div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-hover>
</template>

<script>
import { daysAgoLabel } from '@/common/utilities';

// The value transaction box component
export default {

    name: "ValueTransactionBox",

    props: {
            // Value fields
            id: { type: [Number, String], default: 0 },
            status: { type: [Number, String], default: null },
            type: { type: String, default: '' },
            value: { type: [Number, String], default: 0 },
            confirmedValue: { type: [Number, String], default: 0 },
            createdAt: { type: [Number, String], default: null },

            // From user
            fromid: { type: [Number, String], default: 0 },
            fromFirstname: { type: String, required: true },
            fromLastname: { type: String, required: true },
            fromProfileImgSrc: { type: String, required: true },

            // To user
            toid: { type: [Number, String], default: 0 },
            toFirstname: { type: String, required: true },
            toLastname: { type: String, required: true },
            toProfileImgSrc: { type: String, required: true },

            // Misc
            clickable: { type: Boolean, default: true },
            align: {type: String, default: "text-center"},           // right, left, center
            isCurrent: { type: Boolean, default: false },
        },

    data() {
        return {
        };
    },

    computed: {
        pending() {
            return typeof(this.status) !== "undefined" && Number(this.status) === 0;
        },

        confirmed() {
            return typeof(this.status) !== "undefined" && Number(this.status) === 1;
        },

        disputed() {
            return typeof(this.status) !== "undefined" && Number(this.status) === 2;
        },
        
        fromFullName() {
            return this.fromFirstname ? this.fromFirstname + ' ' + this.fromLastname : '';
        },

        fromUserInitials() {
            let initials = '';
            if (this.fromFirstname) {
                initials += this.fromFirstname.slice(0,1).toUpperCase();
            }
            if (this.fromLastname) {
                initials += this.fromLastname.slice(0,1).toUpperCase();
            }
            return initials;
        },

        fromProfileImg() {
            return this.fromProfileImgSrc.length ? this.fromProfileImgSrc : '';
        },

        toFullName() {
            return this.toFirstname ? this.toFirstname + ' ' + this.toLastname : '';
        },

        toUserInitials() {
            let initials = '';
            if (this.toFirstname) {
                initials += this.toFirstname.slice(0,1).toUpperCase();
            }
            if (this.toLastname) {
                initials += this.toLastname.slice(0,1).toUpperCase();
            }
            return initials;
        },

        toProfileImg() {
            return this.toProfileImgSrc.length ? this.toProfileImgSrc : '';
        },

        alignment() {
            if (this.align === "left") return 'mr-auto';
            if (this.align === "right") return 'ml-auto';
            return 'ma-auto';
        },

        daysAgo() {
            return this.createdAt ? daysAgoLabel(this.createdAt) : '';
        },

        date() {
            return !this.createdAt ? '' : new Date(this.createdAt*1000).toLocaleDateString();
        },

        actualValue() {
            // To show the actual value we take mid point of both values and round up to nearest half
            if (!this.value || !this.confirmedValue) return null;

            const avg = (this.value*1 + this.confirmedValue*1) / 2;      // *1 converts to int
            const roundHalf = Math.round(avg*2)/2;
            return roundHalf;
        },

        valueLabel() {
            if (this.actualValue >= 4.5) return 'massive value';
            if (this.actualValue >= 3.5) return 'great value';
            if (this.actualValue >= 2.5) return 'solid value';
            if (this.actualValue >= 1.5) return 'certain value';
            return 'some value';
        },
    },

    methods: {
        onTap() {
            if (this.clickable) {
                this.$emit('click', { id: this.id });
            }
        },

        capitalize(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
};
</script>

<style scoped>
.value-transaction-container.clickable {
    cursor: pointer;
}

.value_arrow {
    position: relative;
    color: #999999;
}

.initials {
    font-size: 0.9rem !important;
}

.name, .capital-type, .date {
    color: #616161;
    line-height: 1.5rem;
    padding-bottom: 5px;
}

.value, .disputed, .pending {
    color: #616161;
    font-size: 0.8rem !important;
}

.date {
    padding-bottom: 0;
}
</style>
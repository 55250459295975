/**
 * Front-end view controller for the value exchange view value function
 *
 * Render's the page template, and processes any UI functions and interactivity
 * 
 * Note:
 * 
 * Requires the user to be logged in before accessing this page.
 *
 * @file   Front-end view controller for the view value page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
 <template>
    <v-container v-if="isLoggedIn" fluid class="d-flex flex-column flex-grow-1 fill-parent-height pa-0">
        <!-- Page loader-->
        <v-progress-linear class="pa-0 ma-0" :indeterminate="valueLoading" rounded height="3" :background-color="valueLoading ? null : 'grey lighten-3'" :color="valueLoading ? 'primary' : 'grey lighten-3'" />

        <!-- Top Row -->
        <v-row color="grey lighten-3" no-gutters class="top-row flex-grow-0 flex-shrink-0" :class="{ 'sm': $vuetify.breakpoint.smAndDown}" align="center" justify="center">
            <v-col v-if="valueDetails">
                <!-- profile boxes -->
                <v-container>
                    <v-row align="start">
                        <v-col cols="12" md="9" align="left">
                            <!-- Back Link -->
                            <a class="text-left" @click="$router.go(-1)">&lt; Back</a>
                        </v-col>
                    </v-row>
        
                    <!-- Add the profile boxes row -->
                    <v-row align="center" justify="space-around">
                        
                        <!-- Originator -->
                        <v-spacer />
                        <v-col cols="12" md="3" lg="2" v-if="fromUser">
                            <ProfileBox
                                :id='fromUser.userid'
                                :firstname='fromUser.firstname'
                                :lastname='fromUser.lastname'
                                :profileImgSrc='fromUser.profileImg'
                                :countryStr='fromUser.countryname'
                                :countryCode='fromUser.countrycode'
                                :regionStr='fromUser.regionname'
                                level=""
                                :clickable="true"
                                @click="select(fromUser)"
                                @deselect="deselect" />
                        </v-col>
                        
                        <!-- Value Delivered Arrows & Rating Scale -->
                        <v-col cols="12" md="2" class="px-0">
                            <div class="text-center" v-if="confirmedValue">{{valueLabel}}</div>
                            <div class="text-center" v-if="confirmedValue">
                                <v-rating v-model="confirmedValue" background-color="orange lighten-3" color="orange" size="20" dense readonly half-increments></v-rating>
                            </div>
                            <div class="value_arrow">⟶</div>
                            <br />
                            <div class="text-center">{{valueDetails.type}} capital</div>
                        </v-col>
                        
                        <!-- The person adding value to -->
                        <v-col cols="12" md="3" lg="2" v-if="toUser">
                            <ProfileBox
                                :id='toUser.userid'
                                :firstname='toUser.firstname'
                                :lastname='toUser.lastname'
                                :profileImgSrc='toUser.profileImg'
                                :countryStr='toUser.countryname'
                                :countryCode='toUser.countrycode'
                                :regionStr='toUser.regionname'
                                level=""
                                :clickable="true"
                                @click="select(toUser)"
                                @deselect="deselect" />
                        </v-col>
                        <v-spacer />
                    </v-row>

                    <!-- Tags -->
                    <v-row align="center" justify="space-around" class="mt-8">
                        <v-col cols="12" md="9">
                            <p v-if="sortedTags && sortedTags.length">
                                <v-chip v-for="(item, index) in sortedTags" :key="item.tagid" color="blue-grey lighten-3" label small class="mb-2" :class="{ 'mr-2': index < (sortedTags.length - 1)}">
                                    <span>{{ item.text }}</span>
                                </v-chip>
                            </p>
                        </v-col>
                    </v-row>

                    <!-- Banner -->
                    <v-row align="center" justify="center">
                        <v-col cols="12" md="9" class="pb-0">
                            <v-banner rounded style="text-align: left" color="white" class="text-body-2" :single-line="pending || disputed">
                                <v-icon slot="icon" color="amber lighten-1" v-if="valueDetails && pending">mdi-information</v-icon>
                                <v-icon slot="icon" color="green lighten-1" v-if="valueDetails && confirmed">mdi-calendar-check</v-icon>
                                <v-icon slot="icon" color="red lighten-1" v-if="valueDetails && disputed">mdi-alert</v-icon>
                                <div class="text-wrap">{{banner}}</div>
                                <template v-slot:actions v-if="canConfirm">
                                    <v-btn color="primary" text @click="confirmNow">Confirm Now</v-btn>
                                </template>
                            </v-banner>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <!-- Bottom Section -->
        <v-row no-gutters class="bottom-row flex-grow-1 flex-shrink-1">
            <v-col class="mt-12 mb-3 mx-12" v-if="valueDetails">
                <div class="text-left" v-if="showPrivate">
                    <v-btn x-small color="red darken-1" outlined class="mb-5 font-weight-bold mr-auto" @click="expanded = !expanded">
                        <v-icon left dark>{{ expanded ? `mdi-eye` : `mdi-eye-off` }}</v-icon>
                        private
                    </v-btn>
                </div>
                                
                <TransitionExpand v-if="showPrivate">
                    <!-- Private Messages (only if you're the to or from user -->
                    <v-card key="privateFromExpanded" color="red lighten-5" class="mb-10" v-if="expanded && privateFromMessage">
                        <span class="privatemessage font-italic text-caption mr-2 text-left" color="red darken-4" v-if="$vuetify.breakpoint.smAndDown">Only you and {{privateMessageFirstName}} can can see this private message</span>
                        <v-card-text class="text-left publicmessage private font-weight-bold" :class="{ 'sm': $vuetify.breakpoint.smAndDown}" v-html="privateFromMessage"></v-card-text>
                        <v-card-actions>
                            <v-list-item class="grow message-actions">
                                <v-list-item-avatar color="grey darken-1" v-if="fromUserProfileImg">
                                    <v-img class="elevation-6" :src="fromUserProfileImg"></v-img>
                                </v-list-item-avatar>
                                <!-- Show initials if no avatar image -->
                                <v-list-item-avatar v-else color="grey darken-1" class="my-1">
                                    <span class="mx-auto white--text text-h6">{{fromUserInitials}}</span>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title class="text-left">{{fromUserName}}</v-list-item-title>
                                </v-list-item-content>

                                <v-row align="center" justify="end" v-if="!$vuetify.breakpoint.smAndDown">
                                    <span class="privatemessage font-italic text-caption mr-2" color="red darken-4">Only you and {{privateMessageFirstName}} can can see this private message</span>
                                </v-row>
                            </v-list-item>
                        </v-card-actions>
                    </v-card>

                    <v-card key="privateToExpanded" color="red lighten-5" class="mb-10" v-if="expanded && privateToMessage">
                        <span class="privatemessage font-italic text-caption mr-2 text-left" color="red darken-4" v-if="$vuetify.breakpoint.smAndDown">Only you and {{privateMessageFirstName}} can can see this private message</span>
                        <v-card-text class="text-left publicmessage private font-weight-bold" :class="{ 'sm': $vuetify.breakpoint.smAndDown}" v-html="privateToMessage"></v-card-text>
                        <v-card-actions>
                            <v-list-item class="grow message-actions">
                                <v-list-item-avatar color="grey darken-1" v-if="toUserProfileImg">
                                    <v-img class="elevation-6" :src="toUserProfileImg"></v-img>
                                </v-list-item-avatar>
                                <!-- Show initials if no avatar image -->
                                <v-list-item-avatar v-else color="grey darken-1" class="my-1">
                                    <span class="mx-auto white--text text-h6">{{toUserInitials}}</span>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title class="text-left">{{toUserName}}</v-list-item-title>
                                </v-list-item-content>

                                <v-row align="center" justify="end" v-if="!$vuetify.breakpoint.smAndDown">
                                    <span class="privatemessage font-italic text-caption mr-2" color="red darken-4">Only you and {{privateMessageFirstName}} can can see this private message</span>
                                </v-row>
                            </v-list-item>
                        </v-card-actions>
                    </v-card>
                </TransitionExpand>
                
                <!-- Public message -->
                <v-card color="blue-grey lighten-5" class="mb-10" v-if="publicMessage">
                    <v-card-text class="text-left publicmessage font-weight-bold" :class="{ 'sm': $vuetify.breakpoint.smAndDown}" v-html="publicMessage"></v-card-text>
                    <v-card-actions>
                        <v-list-item class="grow message-actions">
                            <v-list-item-avatar color="grey darken-1" v-if="toUserProfileImg">
                                <v-img class="elevation-6" :src="toUserProfileImg"></v-img>
                            </v-list-item-avatar>
                            <!-- Show initials if no avatar image -->
                            <v-list-item-avatar v-else color="grey darken-1" class="my-1">
                                <span class="mx-auto white--text text-h6">{{toUserInitials}}</span>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title class="text-left">{{toUserName}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!-- Right nav drawer -->
        <v-navigation-drawer fixed right v-model="profileDrawerOpen" width="400">
            <v-container class="pa-0">
                <!-- Close button on mobile -->
                <v-row>
                    <v-col cols="12" class="text-right" v-if="$vuetify.breakpoint.smAndDown">
                        <v-btn class="ma-5" outlined small fab color="indigo" @click="profileDrawerOpen = !profileDrawerOpen">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <!-- Profile box -->
                <v-row align="center" justify="space-around">
                    <v-col cols="12" class="mt-10" v-if="profileDrawerUser">
                        <ProfileBox
                            :id='profileDrawerUser.userid'
                            :firstname='profileDrawerUser.firstname'
                            :lastname='profileDrawerUser.lastname'
                            :profileImgSrc='profileDrawerUser.profileImg'
                            :countryStr='profileDrawerUser.countryname'
                            :countryCode='profileDrawerUser.countrycode'
                            :regionStr='profileDrawerUser.regionname'
                            level=""
                            @click="viewProfile"
                            />
                        
                        <!-- View profile button -->
                        <v-btn elevation="2" class="mt-15" @click="viewProfile">View Profile</v-btn>
                    </v-col>
                </v-row>

                <!-- Recent Value List -->
                <v-row align="center">
                    <v-col cols="12" class="pb-0">
                        <h3 v-if="profileDrawerUser" class="pb-0">Recent value created by {{capitalize(profileDrawerUser.firstname)}}</h3>
                        <h3 v-else>Recent value created</h3>
                    </v-col>

                    <!-- Loader -->
                    <v-col cols="12" class="pa-0 ma-0">
                        <v-progress-linear class="pa-0 ma-0" :indeterminate="loadingRecent" rounded height="3" :background-color="loadingRecent ? null : 'white'" :color="loadingRecent ? 'primary' : 'white'" v-if="profileDrawerOpen" />
                    </v-col>
                </v-row>

                <!-- Latest values list -->
                <v-row align="center" justify="space-around" v-if="latestValue && latestValue.length">
                    <!-- List items -->
                    <v-col cols="11" v-for="(v, index) in latestValue" :key="index">
                        <ValueTransactionBox
                            :id="v.id"
                            :status="v.status"
                            :value="v.value"
                            :confirmedValue="v.confirmed_value"
                            :type="v.type"
                            :fromid="v.fromid"
                            :fromFirstname="v.from_firstname"
                            :fromLastname="v.from_lastname"
                            :fromProfileImgSrc="v.from_profileImg"
                            :toid="v.toid"
                            :toFirstname="v.to_firstname"
                            :toLastname="v.to_lastname"
                            :toProfileImgSrc="v.to_profileImg"
                            :createdAt="v.createdat_ts"
                            :isCurrent="Number(v.id) === Number(curValueId)"
                            @click="clickValue"
                        ></ValueTransactionBox>
                    </v-col>
                </v-row>

                <!-- No transactions -->
                <v-row align="center" justify="space-around" v-else>
                    <v-col cols="9">
                        <v-banner outlined rounded style="text-align: left" class="text-body-2">
                            <v-avatar slot="icon" size="80">
                                <v-icon icon="mdi-information-outline" color="primary accent-4">mdi-information</v-icon>
                            </v-avatar>
                            No value logged yet!
                        </v-banner>
                    </v-col>
                </v-row>
            </v-container>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
// Common includes used in this page
import { mapGetters, mapActions } from 'vuex';
import { daysAgoLabel } from '@/common/utilities';

// Import our custom errors
import BadMethodAPIError from '@/errors/badmethodapierror';
import BadRequestAPIError from '@/errors/badrequestapierror';
import AuthenticationAPIError from '@/errors/authenticationapierror';
import InternalServerAPIError from '@/errors/internalserverapierror';
import NoResponseAPIError from '@/errors/noresponseapierror';
import CredentialsRevokedAPIError from '@/errors/credentialsrevokedapierror';
import NotExistsAPIError from '@/errors/notexistsapierror';

// API Connectors
import ValueExchangeAPIService from '@/services/ValueExchangeAPIService.js';

// Shared components used in this page
import ProfileBox from '@/components/ProfileBox';
import ValueTransactionBox from '@/components/ValueTransactionBox';
import TransitionExpand from '@/components/TransitionExpand';

// The main admin home page component
export default {
    name: 'ViewValue',

    data() {
        return {
            curValueId: null,
            valueLoading: false,
            valueDetails: null,
            tags: null,

            // The private message expander
            expanded: false,
            
            // Profile drawer
            profileDrawerOpen: false,
            profileDrawerUser: null,            // toUser | fromUser
            loadingRecent: true,
            valuesCache: [],
        };
    },

    // Incoming properties for this component
    props: {
        valueId: { type: [String,Number], default: null },
    },

    components: {
        ProfileBox,
        TransitionExpand,
        ValueTransactionBox,
    },

    computed: {
        // Map our Vuex getters
        ...mapGetters({
            isLoggedIn: 'Auth/isLoggedIn',
            userid: 'User/userid',
        }),

        pending() {
            return this.valueDetails && typeof(this.valueDetails.status) !== "undefined" && Number(this.valueDetails.status) === 0;
        },

        confirmed() {
            return this.valueDetails && typeof(this.valueDetails.status) !== "undefined" && Number(this.valueDetails.status) === 1;
        },

        disputed() {
            return this.valueDetails && typeof(this.valueDetails.status) !== "undefined" && Number(this.valueDetails.status) === 2;
        },

        daysAgo() {
            return this.valueDetails && this.valueDetails.createdat_ts ? daysAgoLabel(this.valueDetails.createdat_ts) : '';
        },

        banner() {
            if (this.pending) return 'This value transaction is yet to be confirmed by ' + this.capitalize(this.valueDetails.toUser.firstname) + ' ' +  this.capitalize(this.valueDetails.toUser.lastname);
            if (this.confirmed) return 'This value transaction was confirmed ' + this.daysAgo + ' by ' + this.capitalize(this.valueDetails.confirmed_firstname) + ' ' +  this.capitalize(this.valueDetails.confirmed_lastname) + ' on ' + new Date(this.valueDetails.createdat_ts*1000);
            if (this.disputed) return 'This value transaction is currently disputed by ' + this.capitalize(this.valueDetails.toUser.firstname) + ' ' +  this.capitalize(this.valueDetails.toUser.lastname);
            return '';
        },

        // Whether the currently logged in user is the target and the txn needs confirming
        canConfirm() {
            return this.valueDetails && this.valueDetails.toUser && this.valueDetails.toUser.userid && this.valueDetails.toUser.userid === this.userid && (this.pending || this.disputed);
        },

        fromUser() {
            return this.valueDetails && this.valueDetails.fromUser ? this.valueDetails.fromUser : null;
        },

        toUser() {
            return this.valueDetails && this.valueDetails.toUser ? this.valueDetails.toUser : null;
        },

        toUserName() {
            return this.toUser ? this.capitalize(this.valueDetails.toUser.firstname) + ' ' + this.capitalize(this.valueDetails.toUser.lastname) : null;
        },

        toUserInitials() {
            return this.toUser ? this.toUser.firstname.slice(0,1).toUpperCase() + this.toUser.lastname.slice(0,1).toUpperCase() : '';
        },
        
        toUserProfileImg() {
            return this.toUser && this.toUser.profileImg ? this.toUser.profileImg : null;
        },

        fromUserName() {
            return this.fromUser ? this.capitalize(this.valueDetails.fromUser.firstname) + ' ' + this.capitalize(this.valueDetails.fromUser.lastname) : null;
        },

        fromUserInitials() {
            return this.fromUser ? this.fromUser.firstname.slice(0,1).toUpperCase() + this.fromUser.lastname.slice(0,1).toUpperCase() : '';
        },
        
        fromUserProfileImg() {
            return this.fromUser && this.fromUser.profileImg ? this.fromUser.profileImg : null;
        },

        privateMessageFirstName() {
            if (Number(this.userid) === Number(this.toUser.userid)) {
                return this.fromUser.firstname;
            } else {
                return this.toUser.firstname;
            }
        },

        confirmedValue() {
            // To show the actual value we take mid point of both values and round up to nearest half
            if (!this.valueDetails || !this.valueDetails.value || !this.valueDetails.confirmed_value) return null;

            const fromValue = this.valueDetails.value;
            const toValue = this.valueDetails.confirmed_value;
            const avg = (fromValue*1 + toValue*1) / 2;      // *1 converts to int
            const roundHalf = Math.round(avg*2)/2;
            return roundHalf;
        },

        valueLabel() {
            if (this.confirmedValue >= 4.5) return 'massive value';
            if (this.confirmedValue >= 3.5) return 'great value';
            if (this.confirmedValue >= 2.5) return 'solid value';
            if (this.confirmedValue >= 1.5) return 'certain value';
            return 'some value';
        },

        sortedTags() {
            if (!this.tags) return null;

            return [...JSON.parse(JSON.stringify(this.tags))].sort((a, b) => {
                return a.text < b.text;
            });
        },

        publicMessage() {
            return this.valueDetails && this.valueDetails.public_message ? this.nl2br(this.valueDetails.public_message) : null;
        },

        showPrivate() {
            return this.userid && this.toUser && this.fromUser && (Number(this.userid) === Number(this.toUser.userid) || Number(this.userid) === Number(this.fromUser.userid)) && (this.privateFromMessage || this.privateToMessage);
        },

        privateFromMessage() {
            return this.valueDetails && this.valueDetails.message ? this.nl2br(this.valueDetails.message) : null;
        },

        privateToMessage() {
            return this.valueDetails && this.valueDetails.to_message ? this.nl2br(this.valueDetails.to_message) : null;
        },

        latestValue() {
            if (!this.profileDrawerUser) return null;
            const cached = this.valuesCache.find(v => Number(v.userid) === Number(this.profileDrawerUser.userid));
            return cached && cached.recent ? cached.recent : null;
        }
    },

    watch: {
        // whenever login state changes and we're no longer logged in, we redirect to login page
        isLoggedIn(newIsLoggedIn, oldIsLoggedIn) {
            if (!newIsLoggedIn && oldIsLoggedIn) {
                this.$router.push({ name: 'login', query: { return: this.$route.fullPath } });
            }
        },
    },

    created() {
        // Fetch the details from the API
        this.getValue(this.valueId);
    },

    // When route changed to view another value transaction, we need to fetch the new item
    beforeRouteUpdate (to, from, next) {
        this.getValue(to.params.valueId);
        next();
    },

    // If we're not navigating to another exchange page, we clear the searchResults
    beforeRouteLeave(to,from,next) {
        const validRouteNames = ['valueHome', 'valueAddPerson', 'valueLogValue', 'valueLogged', 'valueConfirm', 'valueConfirmed', 'valueDisputed', 'valueView'];
        if (!to.name || validRouteNames.indexOf(to.name) === -1) {
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-searchResults' });
            this.$store.dispatch('SearchResults/clearSearch', { key: 'value-recentResults' });
        }
        next();
    },

    methods: {
        // Map our Snackbar methods into this component
        ...mapActions({
            setSnackBar: 'SnackBar/setSnackBar',
            clearSnackBar: 'SnackBar/clearSnackBar',
        }),

        async getValue(valueId) {
            // We save this valueId
            this.curValueId = valueId;

            // Fetch the tags for the drop down, and details about this value exchange
            this.valueLoading = true;
            ValueExchangeAPIService.getValue(this.userid, valueId)
            .then( (response) => {
                // If the server was unreachable or timedout, the request is cancelled and goes into the then handler trap this as a NoResponseAPIError
                if (!response || !response.message) {
                    throw new NoResponseAPIError();
                }

                // Make sure our expected tags are in the response
                if (!response.value || !response.value.toUser || !response.value.fromUser || !response.tags[response.value.type] || !response.tags.user || !response.tags.from || !response.tags.to) {
                    throw 'There was a problem fetching data, please try again later';
                }
                
                // Stash the original value details
                this.valueDetails = response.value;

                // Close the profile drawer if it's open
                this.profileDrawerUser = null;
                this.profileDrawerOpen = false;
                    
                // Build list of from and to tags
                this.tags = [...response.tags.from, ...response.tags.to];
            })
            .catch( (error) => {
                // Clear the snackbar
                this.clearSnackBar();

                if (error instanceof NoResponseAPIError ) {
                    this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                } else if (error instanceof BadMethodAPIError) {
                    this.errorMessage = 'We encountered a technical problem, please try again later';
                } else if (error instanceof BadRequestAPIError) {
                    this.errorMessage = 'We encountered a problem, please try again later';
                } else if (error instanceof CredentialsRevokedAPIError) {
                    this.errorMessage = 'You are not allowed to access this data, please contact us for support';
                } else if (error instanceof NotExistsAPIError) {
                    this.$router.replace({ name: 'notFound' });
                } else if (error instanceof AuthenticationAPIError) {
                    this.errorMessage = 'We encountered an authentication problem, please logout and try again';
                } else if (error instanceof InternalServerAPIError) {
                    this.errorMessage = 'We encountered a server problem, please try again later';
                } else {
                    this.errorMessage = 'There was a problem, please try again later';
                }

                // Show snackbar error message
                this.setSnackBar({ snack: this.errorMessage });
            })
            .finally(() => {
                this.valueLoading = false;
            });
        },

        capitalize(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },

        nl2br(str, is_xhtml=true) {
            if (typeof str === 'undefined' || str === null) {
                return '';
            }
            var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
        },

        confirmNow() {
            this.$router.push({ name: 'valueConfirm', params: { 'valueId': this.curValueId } });
        },

        deselect() {
            this.profileDrawerUser = null;
            this.profileDrawerOpen = false;
        },

        select(user) {
            // Close drawer if open
            if (this.profileDrawerOpen) {
                this.profileDrawerOpen = false;
            }

            // Load new user
            this.profileDrawerUser = user;
            this.profileDrawerOpen = true;

            // Check if we have cached data otherwise fetch
            const index = this.valuesCache.findIndex(v => Number(v.userid) === Number(this.profileDrawerUser.userid));
            if (index === -1) {
                this.loadingRecent = true;
                
                // Fetch the recent value list
                ValueExchangeAPIService.getLatestValue(this.profileDrawerUser.userid)
                .then( (response) => {
                    // If the server was unreachable or timedout, the request is cancelled and goes into the then handler trap this as a NoResponseAPIError
                    if (!response || !response.message) {
                        throw new NoResponseAPIError();
                    }

                    // Make sure our expected tags are in the response
                    if (!response.recent) {
                        throw 'There was a problem fetching data, please try again later';
                    }

                    // Cache the fetched details so we don't need to lookup again
                    this.valuesCache.push({ userid: this.profileDrawerUser.userid, recent: response.recent });
                })
                .catch( (error) => {
                    // Clear the snackbar
                    this.clearSnackBar();

                    if (error instanceof NoResponseAPIError ) {
                        this.errorMessage = 'We couldn\'t contact the server. Please check your Internet connection or try again later.';
                    } else if (error instanceof BadMethodAPIError) {
                        this.errorMessage = 'We encountered a technical problem, please try again later';
                    } else if (error instanceof BadRequestAPIError) {
                        this.errorMessage = 'We encountered a problem, please try again later';
                    } else if (error instanceof CredentialsRevokedAPIError) {
                        this.errorMessage = 'You are not allowed to access this data, please contact us for support';
                    } else if (error instanceof NotExistsAPIError) {
                        this.errorMessage = 'We couldn\'t find any details for this user';
                    } else if (error instanceof AuthenticationAPIError) {
                        this.errorMessage = 'We encountered an authentication problem, please logout and try again';
                    } else if (error instanceof InternalServerAPIError) {
                        this.errorMessage = 'We encountered a server problem, please try again later';
                    } else {
                        this.errorMessage = 'There was a problem, please try again later';
                    }

                    // Show snackbar error message
                    this.setSnackBar({ snack: this.errorMessage });
                }).finally(() => {
                    // Reset server loader
                    this.loadingRecent = false;
                });
            } else {
                this.loadingRecent = false;
            }
        },

        viewProfile() {
            if (this.profileDrawerUser && this.profileDrawerUser.userid) {
                this.$router.push({ name: 'directoryMember', params: { fetchId: this.profileDrawerUser.userid }});
            }
        },

        clickValue(value) {
            const id = Number(value.id);
            if (id !== Number(this.curValueId)) {
                this.$router.push({ name: 'valueView', params: { valueId: id }});
            }
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
.fill-parent-height{
    height:100%;
}
.value_arrow {
    font-size: 40px;
    color: #757575;
}
.top-row {
    background-color: #eeeeee;
    border-bottom: 1px solid #d1d1d1;
    -webkit-box-shadow: inset 0px -10px 17px -20px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px -10px 17px -20px rgba(0,0,0,0.75);
    box-shadow: inset 0px -10px 17px -20px rgba(0,0,0,0.75);
    padding: 50px 10px;
}

.top-row.sm {
    padding: 20px 10px 50px 10px;
}

.publicmessage {
    font-size: 0.9rem;
    line-height: 1.2rem;
    padding-left: 80px;
    padding-right: 10px;
    margin-bottom: 20px;
}

.publicmessage:before {
    content: open-quote;
    font-weight: bold;
    font-size:100px;
    color:#D6D7D6;
    font-family:Georgia, "Times New Roman", Times, serif;
    position: absolute;
    top: 40px;
    left: 10px;
}

.publicmessage:after {
    content: close-quote;
    font-weight: bold;
    font-size:100px;
    color:#D6D7D6;
    font-family:Georgia, "Times New Roman", Times, serif;
    position: absolute;
    bottom: 0px;
    right: 10px;
}

.message-actions{
    padding-left: 5px;
}

.publicmessage.sm {
    padding-left: 40px;
    padding-right: 10px;
    margin-bottom: 10px;
}

.publicmessage.sm:before {
    font-size:50px;
    top: 20px;
    left: 10px;
}

.publicmessage.sm:after {
    font-size:50px;
    bottom: 50px;
    right: 10px;
}

.publicmessage.private:after {
    bottom: 40px;
}

.publicmessage.private.sm:before {
    top: 70px;
}

.privatemessage {
    color: #B71C1C;
}
</style>
<style>
.v-banner--is-mobile.v-banner--has-icon.v-banner--single-line .v-banner__wrapper {
    padding-top: 10px !important;
}

.v-banner--is-mobile.v-banner--has-icon .v-banner__wrapper {
    padding-top: 16px !important;
}
</style>

/**
 * Profile Box component
 *
 * This renders a reusable profile box showing a mini view of a user
 *
 *
 * @file   Profile Box component
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 *
 */
<template>
    <v-container class="profile-box-container" :class="{ clickable }" @click="onTapPerson">
        <v-row>
            <v-col col="12" style="position: relative">
                <v-hover v-slot="{ hover }">
                    <v-card :class="`profile-box ${alignment} ${level}`" :elevation="clickable ? (hover ? 5 : 2) : 0" :max-height="height" :height="height" :max-width="width" :width="width">
                        <v-img class="profile-box-background" :src="selectedBackground" :gradient="gradient" :height="height">
                            <v-card-subtitle :class="`white--text text-center mt-0 ${level}`">
                                <!-- if Selected, show the select or deselect box -->
                                <v-avatar size="20" v-if="selectable && selected" color="error" class="profile-box-deselect" x-small @click.stop="$emit('deselect', { userid: id })">
                                    <v-icon x-small dark>mdi-close</v-icon>
                                </v-avatar>
                                <v-avatar class="profile-box-user my-1" size="56" v-if="profileImgSrc.length">
                                    <img :src="profileImgSrc" />
                                </v-avatar>
                                <!-- Show initials if no avatar image -->
                                <v-avatar v-else color="grey darken-1" size="56" class="my-1">
                                    <span class="white--text text-h6 ma-auto">{{userInitials}}</span>
                                </v-avatar>
                                <p class="profile-box-name ma-1">{{fullName}}</p>
                                <p class="profile-box-flag mt-1 mb-2">{{countryFlag}}</p>
                                <p class="profile-box-region my-0">{{region}}</p>
                                <p class="profile-box-country my-0">{{country}}</p>
                            </v-card-subtitle>
                        </v-img>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// The profile box component
export default {

    name: "ProfileBox",

    props: {
            width: { type: String, default: "161" },
            height: { type: String, default: "205" },
            align: {type: String, default: "center"},           // right, left, center

            id: { type: [Number, String], default: 0 },
            firstname: { type: String, required: true },
            lastname: { type: String, required: true },
            backgroundImgSrc: { type: String, default: '' },
            profileImgSrc: { type: String, required: true },
            regionStr: { type: String, default: '' },
            countryStr: { type: String, default: '' },
            countryCode: { type: String, default: '' },
            level: { type: String, default: '' },
            clickable: { type: Boolean, default: true },
            selectable: { type: Boolean, default: false },
            selected: { type: Boolean, default: false },
        },

    data() {
        return {
        };
    },

    computed: {
        fullName() {
            return this.firstname ? this.firstname + ' ' + this.lastname : '';
        },

        userInitials() {
            let initials = '';
            if (this.firstname) {
                initials += this.firstname.slice(0,1).toUpperCase();
            }
            if (this.lastname) {
                initials += this.lastname.slice(0,1).toUpperCase();
            }
            return initials;
        },

        alignment() {
            if (this.align === "left") return 'mr-auto';
            if (this.align === "right") return 'ml-auto';
            return 'ma-auto';
        },

        countryFlag() {
            // Lookup emoji flag based on 2 letter country code
            // @see https://stackoverflow.com/questions/42234666/get-emoji-flag-by-country-code
            return this.formattedCountryCode.length ? String.fromCodePoint(...[...this.formattedCountryCode].map(c => c.charCodeAt() + 0x1f1a5)) : '';       // 0x1f1a5 for uppercase, 0x1f185 for lowercase country codes
        },

        country() {
            // Trim long names to fit in the profile box
            return this.countryStr && this.countryStr.length > 20 ? this.countryStr.slice(0, 17) + '...' : this.countryStr;
        },

        region() {
            // Trim long names to fit in the profile box
            return this.regionStr && this.regionStr.length > 20 ? this.regionStr.slice(0, 17) + '...' : this.regionStr;
        },

        formattedCountryCode() {
            return this.countryCode ? this.countryCode.toUpperCase() : '';
        },

        profileImg() {
            return this.profileImgSrc.length ? this.profileImgSrc : '';
        },

        selectedBackground() {
           // Work out path to background by the level prop
           const path = !this.level || !this.level.length ? 'default' : this.level;
           const ext = path === 'default' || path === 'gold' ? 'png' : 'jpeg';
           
           // Include the image using require
           return require('@/assets/imgs/backgrounds/' + path + '.' + ext);
        },

        gradient() {
            if (this.level === 'elite') return 'to top right, rgba(150,150,150,0.01), rgba(150,150,150,0.1)';
            if (this.level === 'jade') return 'to top right, rgba(150,150,150,.1), rgba(150,150,150,.2)';
            if (this.level === 'diamond') return 'to top right, rgba(24, 137, 230,0.9), rgba(213,239,246,0.95)';
            if (this.level === 'platinum') return 'to top right, rgba(150,150,150,0.1), rgba(150,150,150,0.2)';
            if (this.level === 'gold') return 'to top right, rgba(8,8,8,.1), rgba(8,8,8,.2)';
            if (this.level === 'silver') return 'to top right, rgba(150,150,150,0.9), rgba(150,150,150,0.5)';
            return '';
        }
    },

    methods: {
        onTapPerson() {
            if (this.clickable) {
                this.$emit('click', { id: this.id });
            }
        },
    },
};
</script>

<style scoped>
.profile-box-container.clickable {
    cursor: pointer;
}

.v-sheet.v-card.profile-box {
    border-radius: 10px;
}

.profile-box-background .v-image__image--cover {
    opacity: 0.4;
}

.profile-box-name {
    color: rgba(0,0,0,0.6);
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    height: 40px;
    max-height: 40px;
    display: flex;
    align-items:center;
    justify-content:center;
}

.v-card.jade .profile-box-name, .v-card.jade .profile-box-region, .v-card.jade .profile-box-country {
    color: #FFFFFF;
}

.v-card.diamond .profile-box-name, .v-card.diamond .profile-box-region, .v-card.diamond .profile-box-country {
    color: rgba(0,0,0,0.6);   
}

.v-card.platinum .profile-box-name, .v-card.platinum .profile-box-region, .v-card.platinum .profile-box-country {
    color: rgba(0,0,0,0.8);   
}

.v-card.silver .profile-box-name, .v-card.silver .profile-box-region, .v-card.silver .profile-box-country,
.v-card.gold .profile-box-name, .v-card.gold .profile-box-region, .v-card.gold .profile-box-country,
.v-card.elite .profile-box-name, .v-card.elite .profile-box-region, .v-card.elite .profile-box-country {
    color: #ffffff;   
}

.profile-box-user img {
    border: 2px solid white;
}

.profile-box-flag {
    text-align: center;
    font-size: 16px;
}

.profile-box-region {
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    font-size: 12px;
    line-height: 1rem;
}

.profile-box-country {
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    font-size: 12px;
    line-height: 1rem;
}
.profile-box-deselect {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
</style>
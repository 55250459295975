/**
 * Front-end view controller for the not found page
 *
 * Render's the page template, and processes any UI functions and interactivity
 *
 * @file   Front-end view controller for the not found page
 * @author LeanCTO
 * @since  1.0.0
 * @copyright (c) 2022 All rights reserved.
 * 
 */
<template>
    <v-container>
        <v-row align="start" :style="!$vuetify.breakpoint.smAndDown ? 'padding-top: 50px' : 'padding-top: 20px'">
            <v-col>
                <!-- Title -->
                <h1>Not Found</h1>

				<br />
				
				<!-- Back Link -->
				<a @click="$router.back()">Go Back</a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
h1 {
	margin: 40px 0 0;
}
a {
	color: #42b983;
    cursor: pointer;
}
</style>